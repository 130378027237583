import styled, { css } from 'styled-components';
import { Pie } from '../pie/Pie';
import { IconType } from 'react-icons';
import { Chips } from '../chips/Chips';
import { Button } from '../Button';
import { COLORS } from '../../utils/constants';
import { MoreOptions } from '../more-option/MoreOption';
import { useSelector } from 'react-redux';
import { selectCurrentEntity } from '../../reducers/app.reducer';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RiShareForwardLine } from 'react-icons/ri';
import { selectUser } from '../../reducers/account.reducer';
import { useTranslation } from 'react-i18next';

export interface DataProps {
  icon: IconType;
  label: string;
  color?: string;
}

export interface SurveyCardProps {
  versionable: boolean;
  title?: string;
  surveyName?: string;
  surveyId?: string;
  data?: Array<DataProps>;
  percentage?: number;
  status?: string;
  createProduct?: boolean;
  chipsLabel?: any;
  button?: {
    onClick: () => void;
    label: string;
  };
  onOptionsClick?: {
    duplicate: () => void;
    rename: () => void;
    delete: () => void;
    share: () => void;
  };
}

export const SurveyCard = ({
  title,
  data,
  percentage,
  createProduct,
  chipsLabel,
  button,
  onOptionsClick,
  surveyName,
  versionable,
}: SurveyCardProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const currentEntity = useSelector(selectCurrentEntity);

  const getSurveyOptions = () =>
    user.role !== 'guest'
      ? [
          {
            label: t('survey_card.share_survey'),
            onClick: onOptionsClick?.share,
            icon: RiShareForwardLine,
          },
          ...(versionable && !currentEntity.submission?.submitted
            ? [
                {
                  label: t('survey_card.duplicate_survey'),
                  onClick: onOptionsClick.duplicate,
                },
                {
                  label: t('survey_card.rename_survey'),
                  onClick: onOptionsClick.rename,
                },
                {
                  label: t('survey_card.delete_survey'),
                  onClick: onOptionsClick.delete,
                  color: '#ed3f3e',
                },
              ]
            : []),
        ]
      : [];

  return (
    <SurveyCardContainer createProduct={createProduct}>
      {!createProduct && (
        <>
          <TitleContainer>
            <Title>
              <span>{title}</span>
              <span className='subTitle'>{surveyName ?? ' '}</span>
            </Title>
            <MoreOptions options={getSurveyOptions()} />
          </TitleContainer>
          {chipsLabel && <Chips {...chipsLabel} />}
          <Content>
            <PieContainer>
              <Pie inverted={true} percent={percentage} color={COLORS.AquaMarine} />
            </PieContainer>
            <DataContainer>
              {data?.map((item, index) => {
                let Icon = item.icon;
                return (
                  <Data key={index}>
                    <Icon color={item.color} />
                    <Label>{item.label}</Label>
                  </Data>
                );
              })}
            </DataContainer>
          </Content>
          {button && <Button style={{ alignSelf: 'center' }} label={button.label} onClick={button.onClick} />}
        </>
      )}
      {createProduct && (
        <CreateCardContent>
          <IoMdAddCircleOutline />
          <CreateCardTitle>{t('survey_card.create_product')}</CreateCardTitle>
        </CreateCardContent>
      )}
    </SurveyCardContainer>
  );
};

const CreateCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  svg {
    height: 35px;
    width: 35px;
    color: ${COLORS.MediumGrey};
    transition: color 0.3s;
  }
`;

const CreateCardTitle = styled.span`
  font-size: 1.3em;
  color: ${COLORS.lightGrey};
  transition: color 0.3s;
`;

const PieContainer = styled.div`
  width: 100px;
`;

const SurveyCardContainer = styled.div<{ createProduct: boolean }>`
  ${({ createProduct }) => !createProduct && 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);'};
  border-radius: 20px;
  background-color: ${({ createProduct }) => (createProduct ? 'transparent' : 'white')};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
  justify-content: ${({ createProduct }) => (createProduct ? 'center' : 'flex-start')};
  user-select: none;
  width: 320px;
  ${({ createProduct }) => (createProduct ? `border: 2px dashed ${COLORS.MediumGrey}` : 'flex-start')};
  transition: all 0.3s;

  &:hover {
    cursor: ${({ createProduct }) => (createProduct ? 'pointer' : 'default')};
    ${({ createProduct }) =>
      createProduct &&
      css`
        border: 2px dashed ${COLORS.darkBlue};

        svg {
          color: ${COLORS.darkBlue};
        }

        span {
          color: ${COLORS.darkBlue};
        }
      `};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-size: 26px !important;
  font-weight: 700;
  font-family: 'nunito', sans-serif;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span.subTitle {
    color ${COLORS.MediumGrey};
    font-size: 1.3rem;
    white-space: pre;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
`;

const Data = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 18px;

  svg {
    width: 24px;
    height: 24px;
    color: ${props => props.color || 'white'};
  }
`;

const Label = styled.div`
  white-space: nowrap;
  font-weight: 400;
  font-family: 'Roboto', serif;
  font-size: 0.9em;
`;
