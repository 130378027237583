import styled from 'styled-components';
const Content = ({ children }) => {
  return <DrawerContent>{children}</DrawerContent>;
};

const DrawerContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.125rem 2rem;
`;
export default Content;
