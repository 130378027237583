import styled from 'styled-components';

export const ErrorMessage = styled.div`
  color: red;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 10px;
  grid-row: span 1;

  div {
    display: flex;
    column-gap: 10px;

    select {
      flex: 1;
    }

    button {
      min-width: 0px;
    }
  }

  input,
  select {
    padding: 5px 10px;
    width: fit-content;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 0;
    width: auto;

    &:focus-visible {
      border: 1px solid #187ead;
      outline: none;
    }
  }

  > span {
    font-size: 1.125em;
    color: #000;
    font-weight: bold;
  }

  label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 1.125em;
  }
`;

export const InfosFlexContainer = styled.div`
  display: flex;
  column-gap: 30px;
  height: 100%;
`;

export const Left = styled.div`
  flex: 3;
  overflow-y: auto;
`;

export const Right = styled.div`
  flex: 1;
  height: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const UserActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const UserInfosSlider = styled.div`
  height: calc(100% - 40px);
  display: grid;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px 20px;
  grid-template-columns: 1fr 1fr;

  .spacer {
    height: 20px;
  }
`;

export const UserDetailsTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  width: calc(100% - 40px);
  color: black;
  column-gap: 20px;
  justify-content: space-between;
`;

export const BypassOnboarding = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  input {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const LegalOfficerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
