/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FiArrowRight } from 'react-icons/fi';

import { Logo } from '../logo/logo';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import Spinner from '../spinner/Spinner';
import LanguageSwitcher from '../languageSwitcher/languageSwitcher';
// import RadioTiles from '../radioTiles/RadioTiles';
import { Infos } from '../infos/Infos';

import { ApplicantResponsesProps, TODO, WizardSlideProps } from './wizard.types';
import { WIZARD_RIGHT_SPACE } from './Wizard.slides';

import { BUTTON_COLORS } from 'src/utils/constants';
import { isEmailValid } from 'src/utils/utils';
import { RadioTile } from '@components-new/atoms';

const WizardRights = () => {
  // define the slides list for the wizard
  const slideList = WIZARD_RIGHT_SPACE;
  const params: { accountId; entityId; relatedProductId; versionProductId } = useParams();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<ApplicantResponsesProps>();
  const [toolTip, setToolTip] = useState(null);

  const goToStep = async nextStep => {
    let lastStep = nextStep > slideList.length;
    if (!lastStep) {
      setCurrentStep(nextStep);
    }
  };

  const isStepValid = useMemo(() => {
    return step => {
      const currentSlide = slideList[step - 1];

      if (!currentSlide || !currentSlide.items || currentSlide.items.length === 0) {
        // If the slide or its items are not defined, consider it valid
        return true;
      }
      const hasComment = currentSlide.items.some(item => item.name === 'comment');

      if (hasComment && responses?.requestType) {
        return true;
      }

      return (currentSlide.items as TODO[]).every(item => {
        const responseValue = responses?.[item.name];
        if (item.name === 'email') {
          return isContactEmailValid;
        }
        return responseValue !== '' && responseValue !== null && responseValue !== undefined;
      });
    };
  }, [slideList, responses]);

  const isSelectable = targetStep => {
    if (targetStep === 1 || currentStep > targetStep || !isStepValid(targetStep - 1)) {
      return false;
    }
    return true;
  };

  const handleStepChange = async targetStep => {
    if (isSelectable(targetStep)) {
      setError(false);
      await goToStep(targetStep);
    } else {
      setError(true);
    }
  };

  const getNextStep = () => {
    return currentStep + 1;
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setResponses(prevInput => ({
      ...prevInput,
      [name]: value,
    }));
  };

  const handleButtonClick = async (slideStep: WizardSlideProps) => {
    if (slideStep.buttonShouldSave) {
      if (!showCommentArea) delete responses.comment;
      setLoading(true);
      const { accountId, entityId, relatedProductId, versionProductId } = params;
      slideStep
        .saveFunction({
          accountId,
          entityId,
          relatedProductId,
          versionProductId,
          applicant: responses,
        })
        .then(res => {
          setLoading(false);
          if (res.success) {
            handleStepChange(getNextStep());
          } else {
            setError(true);
          }
        })
        .catch(err => {
          setLoading(false);
          setError(true);
        });
    } else {
      handleStepChange(getNextStep());
    }
  };

  const showCommentArea = useMemo(
    () => ['rectification', 'opposition', 'limitation', 'portability'].includes(responses?.requestType),
    [responses?.requestType]
  );

  const isContactEmailValid = useMemo(() => {
    return isEmailValid(responses?.email);
  }, [responses?.email]);

  return (
    <WizardContainer>
      <SideBar>
        <LogoContainer>
          <StyledLogo whiteBg={true} />
          <Separator />
        </LogoContainer>

        <StepContainer>
          {slideList.map((item, idx) => {
            return (
              <Step key={idx} selected={item.step === currentStep} isSelectable={currentStep === 1 ? false : isSelectable(item.step)}>
                <FiArrowRight />
                {t(`wizard.rights.step${item?.step}.label`)}
              </Step>
            );
          })}
        </StepContainer>
      </SideBar>
      <ContentContainer>
        <LanguageSwitcher />
        {slideList.map((item, index) => {
          return (
            item.step === currentStep && (
              <Slide key={index}>
                {item?.title && <Title>{t(`wizard.rights.step${item?.step}.title`).replace('{companyName}', 'Anass Company')}</Title>}
                {item?.content && <Content>{t(`wizard.rights.step${item?.step}.content`)}</Content>}
                <RadioTilesContainer step={item.step}>
                  {item?.items?.map((elt, index) => {
                    return elt.inputType === 'radioTiles' ? (
                      <RadioTile
                        key={index}
                        label={t(elt.inputLabel)}
                        icon={elt.icon}
                        name={elt.name}
                        value={elt.value}
                        checked={responses?.[elt.name] === elt.value}
                        onChange={handleInputChange}
                        disabled={loading || error}
                        onHover={() => elt?.tooltip && setToolTip(elt?.tooltip)}
                        onLeave={() => toolTip && setToolTip(null)}
                      />
                    ) : elt.name === 'comment' ? (
                      showCommentArea ? (
                        <StyledInputCompo
                          key={index}
                          name={elt.name}
                          type={elt.inputType}
                          sharable={false}
                          value={responses?.[elt.name]}
                          onChange={handleInputChange}
                          label={t(elt.inputLabel)}
                          disabled={loading || error}
                          rows={4}
                        />
                      ) : null
                    ) : (
                      <StyledInputCompo
                        key={index}
                        name={elt.name}
                        type={elt.inputType}
                        sharable={false}
                        value={responses?.[elt.name]}
                        onChange={handleInputChange}
                        label={t(elt.inputLabel)}
                        disabled={loading || error}
                        error={responses?.email && !isContactEmailValid && elt.errorMsg ? elt.errorMsg : null}
                        errorColor='red'
                      />
                    );
                  })}
                </RadioTilesContainer>
                {item.showNextButton &&
                  (loading ? (
                    <SpinnerContainer>
                      <Spinner color={BUTTON_COLORS.default} />
                    </SpinnerContainer>
                  ) : (
                    <Button
                      label={t(item.buttonLabel).toUpperCase()}
                      onClick={() => handleButtonClick(item)}
                      disabled={!isStepValid(item.step) || error}
                    />
                  ))}
                {item.step === currentStep && error && <ErrorMessage>Une erreur est survenue. Veuillez réessayer ultérieurement.</ErrorMessage>}
              </Slide>
            )
          );
        })}
        {toolTip && (
          <TooltipContainer>
            <Infos text={t(toolTip)} />
          </TooltipContainer>
        )}
      </ContentContainer>
    </WizardContainer>
  );
};

export default WizardRights;

const StyledInputCompo = styled(InputComponent)``;

const TooltipContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 30px);
  min-width: 340px;
  justify-content: center;
`;

const RadioTilesContainer = styled.div<{ step?: number }>`
  ${StyledInputCompo} {
    font-size: 1.1rem;
  }
  ${({ step }) =>
    step === 2
      ? `
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      justify-items: center;
      align-self: center;
      max-width: 100%;
      width: 80%;
      row-gap: 2rem;
      ${StyledInputCompo} {
        grid-column: 1 / -1;
        width: 75%;
      }
      `
      : `
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      row-gap: 1rem;
    `}
`;

const WizardContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
`;

const SideBar = styled.div`
  width: 210px;
  background-color: #eff7fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled(Logo)``;

const Separator = styled.div`
  color: #1179a1;
  width: 50px;
  border: 1px solid #1179a1;
  margin-top: 35px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'Roboto';
  font-size: 1.33rem;
  margin-top: 190px;
  flex: 1;
  user-select: none;
`;

const Step = styled.div<{ selected: boolean; isSelectable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  color: ${({ selected }) => (selected ? 'rgba(0, 0, 0,    0.8)' : 'rgba(0, 0, 0, 0.63)')};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'not-allowed')};

  svg {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    height: 24px;
    width: 24px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(50%, 70%) minmax(0, min-content);
  overflow: hidden;
  max-width: 100%;
  width: calc(100% - 210px);
  height: 100%;
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Roboto';
  font-size: 1.33rem;
  padding: 0 0 0 125px;
  row-gap: 40px;
  animation: fadeInRightBig 0.5s;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  max-width: 596px;
  font-family: 'Nunito';
  font-size: 4.1rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.011em;
`;

const Content = styled.div`
  display: flex;
  max-width: 863px;
  line-height: 26px;
  font-family: 'Roboto';
  font-weight: 400;

  button {
    margin-top: 20px;
  }
`;
const ErrorMessage = styled.div`
  color: red;
`;

const SpinnerContainer = styled.div`
  margin-left: 15px;
`;
