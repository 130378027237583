import styled from 'styled-components';

export const AdminUserListContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const UserListBody = styled.div<{ admin: boolean }>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '1fr 4fr 4fr 5fr 2fr 2fr 2fr' : '1fr 4fr 4fr 5fr 4fr 2fr')};
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  overflow: auto;

  span {
    font-size: 1.125em;
    font-weight: bold;
  }
`;

export const UserListHeader = styled.div<{ admin: boolean }>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '1fr 4fr 4fr 5fr 2fr 2fr 2fr' : '1fr 4fr 4fr 5fr 4fr 2fr')};
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;

  span {
    font-size: 1.125em;
    font-weight: bold;
  }
`;

export const UserListContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const MessageContainer = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 1.5em;
  padding: 20px;

  div {
    text-align: center;

    span {
      font-weight: 600;
      color: #187ead;
    }
  }
`;

export const UserListDataContent = styled.div`
  display: contents;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
`;

export const ConfirmContainer = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 1.5em;
  padding: 20px;

  div {
    text-align: center;

    span {
      font-weight: 600;

      color: #187ead;

      &.status {
        color: #f5a623;
      }
    }
  }

  button {
    font-size: 1em;
  }
`;
