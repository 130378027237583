import { useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TaskItemProps } from './TaskItem.type';
import {
  ArrowContainer,
  CheckedIconContainer,
  TaskItemContainer,
  TitleContainer,
  SubMenuContainer,
  SubTaskItemContainer,
  SubTaskItemTitleContainer,
  DescriptionContainer,
  TaskItemTitleContainer,
} from './TaskItem_.style';
import { addEventTracking } from '@utils/utils';

const TaskItem = ({ title, onClick, checked, disabled, subMenu, icon, description, selected }: TaskItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    if (description) {
      setIsExpanded(!isExpanded);
    } else if (subMenu) {
      setIsExpanded(!isExpanded);
    }
    onClick();
  };

  const isAllSubTasksChecked = () => {
    return subMenu ? subMenu.every(subTask => subTask.checked) : checked;
  };

  const handleClickTask = () => {
    addEventTracking(title, 'Click');
    onClick();
  };

  const handleDescriptionClick = () => {
    if (description) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <TaskItemContainer
      selected={selected}
      checked={isAllSubTasksChecked()}
      disabled={disabled}
      onClick={!disabled ? (subMenu || description ? handleExpand : !checked ? handleClickTask : null) : null}>
      <TaskItemTitleContainer selected={selected} checked={isAllSubTasksChecked()} disabled={disabled}>
        <CheckedIconContainer checked={isAllSubTasksChecked()} disabled={disabled}>
          {icon ? icon : <IoMdCheckmarkCircleOutline />}
          <TitleContainer disabled={disabled} checked={isAllSubTasksChecked()}>
            {title}
          </TitleContainer>
        </CheckedIconContainer>

        <ArrowContainer checked={isAllSubTasksChecked()} disabled={disabled}>
          {description ? (
            isExpanded && selected ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )
          ) : subMenu && !isAllSubTasksChecked() ? (
            isExpanded && selected ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )
          ) : (
            <IoArrowForwardCircleOutline />
          )}
        </ArrowContainer>
      </TaskItemTitleContainer>

      {selected && !subMenu && description && isExpanded && (
        <DescriptionContainer onClick={handleDescriptionClick}>{description}</DescriptionContainer>
      )}

      {selected && subMenu && isExpanded && !isAllSubTasksChecked() && !description && (
        <SubMenuContainer>
          {subMenu.map((subTask, index) => (
            <SubTaskItemContainer
              key={index}
              checked={subTask.checked}
              disabled={subTask.disabled}
              onClick={!subTask.disabled ? (!subTask.checked ? subTask.onClick : null) : null}>
              <CheckedIconContainer checked={subTask.checked} disabled={subTask.disabled}>
                <SubTaskItemTitleContainer disabled={subTask.disabled} checked={subTask.checked}>
                  {subTask.title}
                </SubTaskItemTitleContainer>
              </CheckedIconContainer>
            </SubTaskItemContainer>
          ))}
        </SubMenuContainer>
      )}
    </TaskItemContainer>
  );
};

export default TaskItem;
