import styled from 'styled-components';
import { COLORS, ENTITY_STATUS } from '../../utils/constants';
import { IconType } from 'react-icons';

export interface ChipsProps {
  label: string;
  status?: typeof ENTITY_STATUS | string;
  icon?: IconType;
  color?: string;
  forwardedRef?: React.Ref<HTMLDivElement> | null;
}

export const Chips = ({ label, icon: Icon, color, forwardedRef }: ChipsProps) => {
  return (
    <ChipsContainer color={color} ref={forwardedRef}>
      {Icon && <Icon />}
      {label}
    </ChipsContainer>
  );
};

const ChipsContainer = styled.div<{ color: string; status?: string }>`
  padding: 6px 12px;
  background-color: ${({ color }) => color};
  /* background-color: ${({ status }) => {
    switch (status) {
      case ENTITY_STATUS.NOT_EDITED:
      case ENTITY_STATUS.EDITED:
        return `rgba(0, 203, 158, 0.49)`;
      case ENTITY_STATUS.SUBMITTED:
      case 'warning':
        return COLORS.Squash;
      case ENTITY_STATUS.REJECTED:
        return `#fab1a0`;
      case ENTITY_STATUS.VALIDATED:
        return COLORS.AquaMarine;
      default:
        return COLORS.NiceBlue;
    }
  }};*/

  color: white;
  white-space: nowrap;
  border-radius: 8px;
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
