import { Modal } from '../modal/Modal';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, StyledSpan } from './ModalSubmitEntityConfirmation_.style';
import { Button } from '@components/Button';

export interface ModalSubmitEntityConfirmationProps {
  show?: boolean;
  entity: any;
  onClick: () => void;
  onClose: () => void;
}

export const ModalSubmitEntityConfirmation = ({ show, entity, onClick, onClose }: ModalSubmitEntityConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <Modal
          onClose={onClose}
          size={{ height: undefined, width: '40%' }}
          show={show}
          title={`${t('modal.submit_entity_confirmation.confirm_submit_prefix')} <b>${entity.name}</b>
            ${t('modal.submit_entity_confirmation.confirm_submit_suffix')}`}>
          <StyledSpan dangerouslySetInnerHTML={{ __html: t('modal.submit_entity_confirmation.warning') }}></StyledSpan>
          <StyledSpan>{t('modal.submit_entity_confirmation.information')}</StyledSpan>
          <ButtonContainer>
            <Button onClick={onClick} label={t('modal.submit_entity_confirmation.submit_button')} />
            <Button type={'light'} onClick={onClose} label={t('modal.submit_entity_confirmation.cancel_button')} />
          </ButtonContainer>
        </Modal>
      )}
    </>
  );
};
