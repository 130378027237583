import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { MdOutlineLockOpen, MdBookmarkBorder, MdCheckCircle, MdOutlineHolidayVillage, MdOutlinePeopleAlt } from 'react-icons/md';
import { AiOutlineMessage } from 'react-icons/ai';

import { selectLoading } from '../../reducers/app.reducer';
import { selectActiveAccount, selectImpersonated, selectUser, updateUser } from '../../reducers/account.reducer';

import { ConformityCard } from '../conformityCard/ConformityCard';
import { Button } from '../Button';
import { Counter } from '../counter/Counter';
import { COLORS, USER_ROLES, Z_INDEX } from '../../utils/constants';
import { EntityTable } from '../entity-table/EntityTable';
import { Modal } from '../modal/Modal';
import Calendar from '../calendar/Calendar';

import { getAccountConformityPercentage, getAccountCounters, getAccountProductAmount, getCompletedEntityAmount } from '../../utils/utils';

export interface DashboardGroupProps {}

const DashboardGroup = () => {
  //REDUX
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);
  const impersonated = useSelector(selectImpersonated);
  const currentUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);

  const userDetails = activeAccount?.users.find(user => user._id === currentUser._id);

  const [displayNewVersionModal, setDisplayNewVersionModal] = useState(userDetails?.displayNewVersionMsg);

  const displaySponsorship = () => {
    if (userDetails.role === USER_ROLES.Guest) {
      return false;
    }

    const currentDate = new Date().getTime();

    if (userDetails?.createdAt) {
      const createdAtDate = new Date(userDetails.createdAt).getTime();
      const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;

      if (currentDate - createdAtDate <= twoWeeksInMilliseconds) {
        return false;
      }
    }

    if (!userDetails?.lastSponsorshipDate) {
      return true;
    }

    const lastSponsorshipDate = new Date(userDetails.lastSponsorshipDate).getTime();
    const twoMonthsInMilliseconds = 2 * 30 * 24 * 60 * 60 * 1000;

    return currentDate - lastSponsorshipDate > twoMonthsInMilliseconds;
  };

  const [displaySponsorshipModal, setDisplaySponsorshipModal] = useState(userDetails !== undefined && displaySponsorship());

  useEffect(() => {
    userDetails !== undefined && setDisplaySponsorshipModal(displaySponsorship());
  }, [impersonated, userDetails]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCloseVersionMsgModal = async () => {
    const accountCopy = _.cloneDeep(activeAccount);
    let userCopy = accountCopy.users.find(userItem => userItem._id === currentUser._id);
    userCopy.displayNewVersionMsg = false;
    dispatch(updateUser({ userId: userCopy._id, data: userCopy }));
    setDisplayNewVersionModal(false);
  };

  const handleCloseSponsorshipModal = async () => {
    const accountCopy = _.cloneDeep(activeAccount);
    let userCopy = accountCopy.users.find(userItem => userItem._id === currentUser._id);
    userCopy.lastSponsorshipDate = new Date();
    dispatch(updateUser({ userId: userCopy._id, data: userCopy }));
    setDisplaySponsorshipModal(false);
  };

  const groupCardData = {
    title: `${t('dashboard.complianceLevel')} ${activeAccount?.companyName}`,
    data: [
      {
        icon: MdOutlineHolidayVillage,
        label: `${activeAccount?.entities?.length} ${activeAccount?.entities?.length > 1 ? t('common.entities') : t('common.entity')}`,
      },
      {
        icon: MdOutlinePeopleAlt,
        label: `${activeAccount?.users?.length} ${activeAccount?.users?.length > 1 ? t('common.collaborators') : t('common.collaborater')}`,
      },
      {
        icon: MdBookmarkBorder,
        label: `${getAccountProductAmount(activeAccount)} ${t('common.product')}${getAccountProductAmount(activeAccount) > 1 ? 's' : ''}`,
      },
      {
        icon: MdCheckCircle,
        label: `${getCompletedEntityAmount(activeAccount)} ${
          getCompletedEntityAmount(activeAccount) > 1 ? t('common.entities') : t('common.entity')
        } ${t('common.compliant')}${getCompletedEntityAmount(activeAccount) > 1 ? 's' : ''}`,
        color: COLORS.AquaMarine,
      },
    ],
    percentage: getAccountConformityPercentage(activeAccount),
  };

  const handleMeetingDPO = () => {
    setIsCalendarOpen(prevState => !prevState);
  };

  return (
    <DashboardGroupContainer>
      <DashboardTop>
        <Left>
          <Title>
            <span>{activeAccount?.companyName}</span>
          </Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: t('dashboard.welcome'),
            }}></Description>
          {currentUser?.role !== 'guest' && <Button onClick={handleMeetingDPO} label={t('dashboard.appointment')} />}
        </Left>
        <Right>
          <ConformityCard {...groupCardData} />
          {activeAccount && (
            <Counters>
              {getAccountCounters(activeAccount).dataViolation > 0 && (
                <Counter
                  amount={getAccountCounters(activeAccount).dataViolation}
                  icon={MdOutlineLockOpen}
                  description={t('dashboard.dataViolation')}
                />
              )}
              {getAccountCounters(activeAccount).assistanceRequests > 0 && (
                <Counter
                  amount={getAccountCounters(activeAccount).assistanceRequests}
                  icon={AiOutlineMessage}
                  description={t('dashboard.contactsDipeeo')}
                />
              )}
            </Counters>
          )}
        </Right>
      </DashboardTop>
      <DashboardContent>
        <EntityTable />
      </DashboardContent>
      {!loading && !impersonated && (
        <Modal
          alignment={'center'}
          size={{ width: '50%' }}
          show={displayNewVersionModal}
          title={`${t('dashboard.refonte')} 🎉`}
          onClose={handleCloseVersionMsgModal}
          overAll>
          <BasicModalContainer
            dangerouslySetInnerHTML={{
              __html: t('dashboard.refonteText'),
            }}></BasicModalContainer>
        </Modal>
      )}

      {!loading && !impersonated && displaySponsorshipModal && (
        <Modal
          alignment={'center'}
          size={{ width: '50%' }}
          show={displaySponsorshipModal}
          title={`${t('dashboard.sponsorship')} 🎉`}
          onClose={handleCloseSponsorshipModal}
          overAll>
          <BasicModalContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: t('dashboard.sponsorshipText'),
              }}></div>
            <ButtonContainer>
              <Button label={t('common.seeMore')} onClick={() => window.open(process.env.REACT_APP_SPONSORSHIP_LINK, '_blank')}></Button>
            </ButtonContainer>
          </BasicModalContainer>
        </Modal>
      )}

      {isCalendarOpen && <Calendar isCalendarOpen={isCalendarOpen} setIsCalendarOpen={setIsCalendarOpen} activeAccount={activeAccount} />}
    </DashboardGroupContainer>
  );
};

export default DashboardGroup;

const DashboardGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 110px 150px;
  row-gap: 50px;
  height: calc(100% - 220px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 40%;
    bottom: 0;
    background-color: #c8e3f6;
    opacity: 30%;
    z-index: ${Z_INDEX.below};
  }
`;

const DashboardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  flex: 1;

  @media (max-width: 1260px) {
    display: none;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;

  @media (max-width: 1260px) {
    justify-content: center;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 50px;
  display: flex;
  align-items: center;
  column-gap: 20px;

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  width: 85%;
  line-height: 2rem;
`;

const DashboardContent = styled.div`
  flex: 1;
  min-height: 0;
`;

const Counters = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;

  @media (max-width: 1510px) {
    display: none;
  }
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  overflow-y: auto;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
