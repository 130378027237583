import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const ComponentContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 50px;
  height: 100vh;
  overflow-y: scroll;
`;
