export const paths = {
  login: '/connexion',
  dashboardGroup: '/tableau-de-bord-groupe',
  dashboardEntity: '/tableau-de-bord-entite',
  collaborators: '/collaborateurs',
  survey: '/questionnaire',
  register: '/registres',
  documents: '/mes-documents',
  admin: '/panneau-administrateur',
  wizard: '/wizard',
  userAction: '/plan-action',
  wizard2: '/sub-contractor',
  profile: '/profile',
  adminActionPlan: '/admin/plan-action',
};
