import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import CryptoJS from 'crypto-js';

import accountReducer from 'src/reducers/account.reducer';
import { decryptState, encryptState } from 'src/utils/storage';

const encryptTransform = {
  in: rawState => encryptState(rawState),
  out: (encryptedState: string) => decryptState(encryptedState),
};

// Encrypting the key and prefix using CryptoJS
const encryptedKey = CryptoJS.SHA256(`account`).toString().substring(0, 15);

// Custom persist configuration with encrypted key and prefix
const accountPersistConfig = {
  keyPrefix: '',
  key: encryptedKey,
  storage: storageSession,
  whitelist: ['account', 'user', 'originalUser'], // Specify the part of state to be persisted
  transforms: [encryptTransform], // Apply encryption to the persisted state
};

const persistedAccountReducer = persistReducer(accountPersistConfig, accountReducer);

export default persistedAccountReducer;
