import styled, { keyframes, css } from 'styled-components';
import { StyledCompoProps } from './Placeholder.type';

// Mixin function
const animMixin = () => css`
  overflow: hidden;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${backgroundAnimation};
  animation-timing-function: linear;
  background: linear-gradient(to right, #e9eaf0 8%, #d5d8e3 18%, #e9eaf0 33%);
  background-size: 800px 104px;
`;

// Keyframes for background animation
const backgroundAnimation = keyframes`
  0% {
    background-position: -500px;
  }
  100% {
    background-position: 500px;
  }
`;

export const PlaceholderDefault = styled.div<StyledCompoProps>`
  ${animMixin};
  width: ${({ width }) => (width > 100 ? 100 : width)}%;
`;

export const PlaceholderRounded = styled(PlaceholderDefault)<StyledCompoProps>`
  height: 31px;
  min-height: 31px;
  border-radius: 0.625rem;
`;

export const PlaceholderLine = styled(PlaceholderDefault)<StyledCompoProps>`
  height: 8px;
  display: block;
  border-radius: 50px;
`;

export const PlaceholderSquare = styled(PlaceholderDefault)<StyledCompoProps>`
  height: 30px;
  min-height: 30px;
`;
