import dayjs from 'dayjs';

import { ReactComponent as AccessRight } from 'src/assets/Rights/AccessRight.svg';
import { ReactComponent as CopyRight } from 'src/assets/Rights/CopyRight.svg';
import { ReactComponent as ForgottenRight } from 'src/assets/Rights/ForgottenRight.svg';
import { ReactComponent as PortabilityRight } from 'src/assets/Rights/PortabilityRight.svg';
import { ReactComponent as FateDeathRight } from 'src/assets/Rights/FateDeathRight.svg';
import { ReactComponent as RectificationRight } from 'src/assets/Rights/RectificationRight.svg';
import { ReactComponent as OppositionRight } from 'src/assets/Rights/OppositionRight.svg';
import { ReactComponent as LimitationRight } from 'src/assets/Rights/LimitationRight.svg';

import { ReactComponent as Client } from 'src/assets/UserTypes/Client.svg';
import { ReactComponent as Candidate } from 'src/assets/UserTypes/Candidate.svg';
import { ReactComponent as Employee } from 'src/assets/UserTypes/Employee.svg';

import Calendar from '../calendar/Calendar';

import { saveSubContractorResponses, createRequest } from 'src/utils/api';

export const WizardSlides = account => {
  const firstConnectionWithoutEntity = () => {
    return [
      {
        step: 1,
        label: 'Bienvenue chez Dipeeo.',
        title: `Bienvenue ${account.companyName} sur votre espace RGPD Dipeeo.`,
        content: "Nous sommes fiers d'être votre partenaire de confiance pour votre mise en conformité RGPD.",
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'DÉMARRER',
        conditions: [],
      },
      {
        step: 2,
        label: 'Pour bien commencer',
        title: 'Pour bien commencer',
        content: 'Quelle est le nom de votre première entité ?',
        input: true,
        inputType: 'text',
        options: [],
        buttonLabel: 'SUIVANT',
        conditions: [],
      },
      {
        step: 3,
        label: 'Merci !',
        title: 'Merci !',
        content: (
          <div>
            Vous allez maintenant être redirigé vers votre tableau de bord.
            <br />
            Nous vous invitons à ajouter les produits associés à votre entité (application en ligne/mobile, site internet, Blog ou FAQ) et à répondre
            aux questionnaires associés !
          </div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'ACCEDER AU TABLEAU DE BORD',
        conditions: [],
      },
    ];
  };

  const firstConnectionWithEntity = () => {
    return [
      {
        step: 1,
        label: 'Bienvenue chez Dipeeo.',
        title: `Bienvenue ${account.companyName} sur votre espace RGPD Dipeeo.`,
        content: "Nous sommes à nouveau fiers d'être votre partenaire de confiance pour votre mise en conformité RGPD.",
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'DÉMARRER',
        conditions: [],
      },
      {
        step: 2,
        label: 'Merci !',
        title: 'Merci !',
        content: (
          <div>
            Vous allez maintenant être redirigé vers votre tableau de bord.
            <br />
            Nous vous invitons à ajouter les produits associés à votre entité (application en ligne/mobile, site internet, Blog ou FAQ) et à répondre
            aux questionnaires associés !
          </div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'ACCEDER AU TABLEAU DE BORD',
        conditions: [],
      },
    ];
  };

  const calendar = (isStepPointInvitation: boolean) => {
    return [
      {
        step: 1,
        label: isStepPointInvitation ? "Votre plan d'actions est prêt !" : 'Bienvenue chez Dipeeo.',
        title: isStepPointInvitation ? "Votre plan d'actions RGPD est prêt !" : `Bienvenue ${account.companyName} sur votre espace RGPD Dipeeo.`,
        content: isStepPointInvitation ? (
          <div>
            Afin de vous présenter l'ensemble des mesures correctives que vous devez mettre en place pour que vous soyez conforme au RGPD, nous vous
            invitons à prendre rendez-vous avec votre DPO.
          </div>
        ) : (
          <div>
            Nous sommes ravis de vous accompagner dans votre mise en conformité RGPD avec Dipeeo.
            <br />
            Pour commencer ce processus, nous vous invitons à choisir un créneau horaire qui vous convient pour notre premier rendez-vous.
            <br />
            Cela nous permettra de mieux comprendre vos besoins et de vous expliquer le déroulement de notre collaboration.
          </div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'DÉMARRER',
        conditions: [!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation],
      },
      {
        step: 2,
        label: 'Prendre un RDV.',
        title: `Prendre un RDV.`,
        content: "Nous sommes à nouveau fiers d'être votre partenaire de confiance pour votre mise en conformité RGPD.",
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: 'SUIVANT',
        conditions: [!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation],
        children: Calendar,
      },
      {
        step: 3,
        label: 'Merci !',
        title: 'Merci !',
        content: (
          <div>
            Votre RDV a bien été pris en compte.
            <br />
            Vous pouvez désormais quitter cette page.
          </div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        conditions: [],
      },
    ];
  };
  const appointmentConfirmation = () => {
    const firstAppointment = account.activity.appointments.list[0];
    const formattedSlotStart = dayjs(firstAppointment?.startTime).format('HH:mm');
    const formattedDate = dayjs(firstAppointment?.startTime).format('dddd, D MMMM YYYY');
    return [
      {
        step: 1,
        label: 'Merci !',
        title: 'Merci !',
        content: (
          <div>
            Nous sommes ravis de vous informer que votre rendez-vous a bien été pris le <b>{formattedDate}</b> à <b>{formattedSlotStart}</b>.
          </div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        conditions: [],
      },
    ];
  };

  if (!account) {
    return [];
  }

  let isStepPointInvitationEmailSent = account.users.some(user =>
    user.activity.sentMails.some(email => email.templateId === 'd-cf2a5491a81242a98fd6a103dfedb99e')
  );
  let isStepPointInvitation = isStepPointInvitationEmailSent && !account.activity.appointments.didScheduleStepPointAppointment;
  if (!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation) {
    return calendar(isStepPointInvitation);
  }
  if (!account.activatedAt) {
    return appointmentConfirmation();
  }
  return account?.entities?.length >= 1 ? firstConnectionWithEntity() : firstConnectionWithoutEntity();
};

export const WIZARD_SUB_CONTRACTORS = [
  {
    step: 1,
    label: 'Bienvenue chez Dipeeo',
    title: 'Bienvenue chez Dipeeo !',
    content:
      'Dans le cadre de la mise en conformité RGPD de {companyName} nous devons auditer ses sous-traitants (ex : hébergeur, gestion de la paie, emailing, etc.). \n' +
      'Nous vous remercions par avance pour votre collaboration et votre réactivité.',
    input: false,
    inputType: 'text',
    options: [],
    buttonLabel: 'DÉMARRER',
    conditions: [],
    showNextButton: true,
  },
  {
    step: 2,
    label: 'Pour bien commencer',
    content: 'Pour que nous puissions traiter votre audit, pourriez-vous nous fournir les informations suivantes : ',
    items: [
      {
        input: true,
        inputType: 'text',
        inputLabel: "Nom de l'entreprise",
        name: 'companyName',
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: 'Votre rôle',
        name: 'responsablePosition',
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: 'Votre email',
        name: 'responsableEmail',
      },
    ],
    buttonLabel: 'SUIVANT',
    showNextButton: true,
  },
  {
    step: 3,
    label: 'Question à répondre',
    input: false,
    inputType: 'text',
    buttonLabel: 'SUIVANT',
    buttonShouldSave: true,
    saveFunction: saveSubContractorResponses,
    hasQuestion: true,
    showNextButton: true,
  },
  {
    step: 4,
    label: 'Merci !',
    title: 'Merci !',
    content:
      "Nous vous remercions d'avoir participé à notre audit RGPD. Vos réponses sont essentielles. Nous allons les traiter dans les plus brefs délais et vous recontacter si nécessaire.",
    input: false,
    inputType: 'text',
    options: [],
    conditions: [],
    showNextButton: false,
  },
];

export const WIZARD_RIGHT_SPACE = [
  {
    step: 1,
    label: 'wizard.rights.step1.label',
    title: 'wizard.rights.step1.title',
    content: 'wizard.rights.step1.content',
    buttonLabel: 'common.start',
    showNextButton: true,
  },
  {
    step: 2,
    label: 'wizard.rights.step2.label',
    content: 'wizard.rights.step2.content',
    items: [
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.0.label',
        name: 'requestType',
        value: 'access',
        tooltip: 'wizard.rights.step2.items.0.tooltip',
        icon: AccessRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.1.label',
        name: 'requestType',
        value: 'copy',
        tooltip: 'wizard.rights.step2.items.1.tooltip',
        icon: CopyRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.2.label',
        name: 'requestType',
        value: 'forgotten',
        tooltip: 'wizard.rights.step2.items.2.tooltip',
        icon: ForgottenRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.3.label',
        name: 'requestType',
        value: 'portability',
        tooltip: 'wizard.rights.step2.items.3.tooltip',
        icon: PortabilityRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.4.label',
        name: 'requestType',
        value: 'rectification',
        tooltip: 'wizard.rights.step2.items.4.tooltip',
        icon: RectificationRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.5.label',
        name: 'requestType',
        value: 'limitation',
        tooltip: 'wizard.rights.step2.items.5.tooltip',
        icon: LimitationRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.6.label',
        name: 'requestType',
        value: 'opposition',
        tooltip: 'wizard.rights.step2.items.6.tooltip',
        icon: OppositionRight,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step2.items.7.label',
        name: 'requestType',
        value: 'dataFateDeath',
        tooltip: 'wizard.rights.step2.items.7.tooltip',
        icon: FateDeathRight,
      },
      {
        input: true,
        inputType: 'textarea',
        inputLabel: 'wizard.rights.step2.items.8.label',
        name: 'comment',
      },
    ],
    buttonLabel: 'common.next',
    showNextButton: true,
  },
  {
    step: 3,
    label: 'wizard.rights.step3.label',
    items: [
      {
        input: true,
        inputType: 'text',
        inputLabel: 'wizard.rights.step3.items.0',
        name: 'name',
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: 'wizard.rights.step3.items.1',
        name: 'firstName',
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: 'wizard.rights.step3.items.2',
        name: 'email',
        errorMsg: 'Veuillez saisir une adresse e-mail valide.',
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step3.items.3',
        name: 'userType',
        value: 'candidate',
        icon: Candidate,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step3.items.4',
        name: 'userType',
        value: 'user',
        icon: Client,
      },
      {
        input: true,
        inputType: 'radioTiles',
        inputLabel: 'wizard.rights.step3.items.5',
        name: 'userType',
        value: 'employee',
        icon: Employee,
      },
    ],
    buttonLabel: 'common.next',
    buttonShouldSave: true,
    saveFunction: createRequest,
    showNextButton: true,
  },
  {
    step: 4,
    label: 'wizard.rights.step4.label',
    title: 'wizard.rights.step4.title',
    content: 'wizard.rights.step4.content',
    showNextButton: false,
  },
];
