import styled, { css } from 'styled-components';
import { GrClose } from 'react-icons/gr';

import Footer from './Footer';
import Content from './Content';

import { slideInBottom, slideInRight, slideOutBottom, slideOutRight } from './animations';

const Drawer = ({ children, header, setOpen, open, additionalComponent = null, width = '33%' }) => {
  return (
    <DrawerContainer className={`${open ? 'open' : ''}`}>
      <Overlay
        onClick={e => {
          e.preventDefault();
          setOpen();
        }}
      />
      <DrawerInner width={width}>
        <DrawerHeader>
          {header}
          <div>
            {additionalComponent}
            <Badge
              variant='button'
              onClick={e => {
                e.preventDefault();
                setOpen();
              }}>
              <GrClose />
            </Badge>
          </div>
        </DrawerHeader>
        {children}
      </DrawerInner>
    </DrawerContainer>
  );
};

Drawer.Content = Content;
Drawer.Footer = Footer;

export default Drawer;

// EXAMPLE USAGE
/* <Drawer
    setOpen={setDrawer}
    open={drawer}
    title='Drawer title'
    additionalComponent={<AdditionalComponent />}
  >
    <Drawer.Content>
      <div style={{ height: "40rem" }}>content</div>
    </Drawer.Content>
      NOTE: 
    <Drawer.Footer align='{start | between | end | full | around }'>
      <div style={{ backgroundColor: "red" }}>1</div>
      <div style={{ backgroundColor: "blue" }}>2</div>
    </Drawer.Footer>
  </Drawer> */

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  // font-size: 1.5rem;

  .font-weight-bold {
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`;

const DrawerInner = styled.div<{ width?: string }>`
  z-index: 3;

  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;

  @media (min-width: 43rem) {
    display: flex;
    flex-direction: column;

    overflow-y: hidden;
    ${({ width }) =>
      width &&
      css`
        max-width: ${width};
        width: ${width};
      `}
    height: 100vh;

    background: white;
    border-left: 1px solid #dee2e6;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);

    animation-name: ${slideOutRight};
  }

  @media (max-width: 43rem) {
    display: flex;
    flex-direction: column;

    overflow-y: hidden;

    width: 100vw;
    height: auto;
    max-height: 85vh;

    background: $white;
    border-left: 1px solid #dee2e6;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 0.3rem !default;
    border-top-right-radius: 0.3rem !default;

    animation-name: ${slideOutBottom};
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  transition-delay: 0.3s;
`;

const DrawerContainer = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  @media (min-width: 43rem) {
    flex-direction: column;
  }
  &.open {
    opacity: 1;
    visibility: visible;

    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;

    ${DrawerInner} {
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.3s;

      @media (max-width: 43rem) {
        animation-name: ${slideInBottom};
      }

      @media (min-width: 43rem) {
        animation-name: ${slideInRight};
      }
    }

    ${Overlay} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Badge = styled.div<{ variant?: string }>`
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.875rem;
  border-radius: 0.625rem;
  font-weight: 700;
  transition: 0.3s all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0.75rem;
  ${({ variant }) => {
    return variant === 'button' && 'cursor : pointer;';
  }};
`;
