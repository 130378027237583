import { COLORS, Z_INDEX } from '../../utils/constants';
import styled, { css } from 'styled-components';

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 25px;
    width: 25px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  input {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    font-family: 'Baloo 2';
    font-size: 1.2em;
    width: 100%;
    padding: 10px 0 10px 10px;

    &:focus-visible {
      outline: none;
    }
  }

  svg {
    font-size: 20px;
    position: absolute;
    left: 6px;
    top: 5px;
  }
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  row-gap: 20px;
  margin-top: 20px;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
`;

const SubItemWrapper = styled.div<{
  open: boolean;
  childHeight: number;
  menuOpen: boolean;
}>`
  height: ${({ open, childHeight }) => (open ? childHeight : 0)}px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ menuOpen }) =>
    !menuOpen &&
    `
    display: none;
  `};
`;

const SubMenuContainer = styled.div<{ open: boolean; level: number; type?: string }>`
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: ${({ level }) => (level === 0 ? '300px' : '650px')};
  bottom: 0;
  left: ${({ level }) => 80 + level * 350}px;
  z-index: ${Z_INDEX.sidenav + Z_INDEX.below};
  transition: all 0.3s;
  padding: 40px 30px;
  ${({ level }) => css`
    padding-left: ${level * 10 + 30}px;
  `};
  box-shadow: 2px 1px 2px rgb(0 0 0 / 14%);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  ${({ open, level }) =>
    !open &&
    css`
      transform: translateX(${-100 * (level + 1)}%);
      box-shadow: none;
    `};

  z-index: ${({ level }) => Z_INDEX.sidenav + Z_INDEX.below - level};

  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 25px;
    width: 25px;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 2.3em;
  color: ${COLORS.Black};
  font-weight: 700;
`;

const MenuItem = styled.div<{ selected?: boolean; admin?: boolean }>`
  color: white;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 100%;
  align-items: flex-start;

  span {
    margin-top: 3px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }
`;

const SubItemList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-left: 30px;
  height: fit-content;
  width: calc(100% - 35px);
  padding: 20px 0 0 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 2px;
    background-color: ${COLORS.MediumGrey};
    bottom: 30px;
    top: 10px;
  }
`;

const SubItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  color: ${COLORS.Black};
  transition: margin-left 0.2s;
  position: relative;

  svg,
  span {
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  &:hover {
    svg,
    span {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    `
     column-gap: 13px;
  
     svg, span {
      opacity: 1;
    }
    
    // span {
        background-color: #EEEEEE;
        border-radius: 5px;
    // }
  `}
  span {
    margin-top: 2px;
    line-height: 18px;
    max-width: 100%;
    padding: 5px 8px;
    transition: margin-left 0.2s;

    &:hover {
      margin-left: 3px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 15px;
    left: -20px;
    top: 55%;
    transform: translateY(-100%);
    border-bottom-left-radius: 10px;
    border-left: 2px solid ${COLORS.MediumGrey};
    border-bottom: 2px solid ${COLORS.MediumGrey};
  }
`;

const Entity = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: ${COLORS.Black};
  font-weight: 500;
  column-gap: 5px;
  width: 100%;

  span {
    flex: 1;
  }

  svg:last-child {
    width: 16px;
    height: 16px;
    margin-top: 1px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${COLORS.MediumGrey};
    padding: 5px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: ${Z_INDEX.below};
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    &:after {
      opacity: 0.3;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    &:after {
        opacity: 0.3;
        }
    `}
`;

export { TopContainer, SearchContainer, ItemList, SubItemWrapper, SubMenuContainer, Title, MenuItem, SubItemList, SubItem, Entity };
