import styled from 'styled-components';

const Dropdown = styled.div`
  position: absolute;
  right: 1%;
  top: 2%;
  width: 110px;
  filter: url(#goo);
`;

const Face = styled.label`
  background-color: #eff7fd;
  padding: 15px 20px;
  border-radius: 15px;
  display: block;
  position: relative;
`;

const Items = styled.ul`
  margin: 0;
  position: absolute;
  right: 0;
  top: 50%;
  width: 100%;
  list-style: none;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);

  &::before {
    content: '';
    background-color: #eff7fd;
    position: absolute;
    bottom: 100%;
    right: 20%;
    height: 40px;
    width: 20px;
  }
`;

const Arrow = styled.div`
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateY(-50%);
  transform-origin: right;
`;

const HiddenInput = styled.input`
  display: none;

  &:checked ~ ${Items} {
    top: calc(100% + 25px);
    visibility: visible;
    opacity: 1;
  }
`;

export const SVG = styled.svg`
  display: none;
`;

export { Dropdown, Face, Items, Arrow, HiddenInput };
