import styled, { css } from 'styled-components';
import Spinner from '@components/spinner/Spinner';

export const ResponseContainer = styled.div`
  overflow-y: auto;
  padding-right: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

export const ListItem = styled.div<{ active: boolean }>`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin: 5px;
  box-shadow:
    rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  ${({ active }) => active && 'box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;'};
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
  }
`;

export const ActionsContainer = styled.div`
  border-radius: 5px;
  padding: 10px;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  height: 100%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
  font-size: 1.1rem;
`;

export const ActionDetailsContainer = styled.div`
  row-gap: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  font-size: 1.1rem;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ActionFooter = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomSpinner = styled(Spinner)`
  margin-right: 50px;
`;

export const ResponsesHeader = styled.span`
  display: block;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const DivContainer = styled.div<{ center?: boolean; editable?: boolean; size?: string; height?: boolean; bolder?: boolean }>`
  display: grid;
  height: ${({ height }) => (height ? '4rem' : 'unset')};
  align-items: center;
  grid-template-columns: 1fr 2fr;
  column-gap: 1%;
  margin-bottom: 0.75rem;
  align-items: ${({ center }) => (center ? 'center' : 'unset')};
  font-weight: ${({ bolder }) => (bolder ? 'bold' : 'unset')};
  color: grey;
  span {
    color: black;
    margin-right: 1rem;
    font-weight: bold;
  }
  .justify-text {
    text-align: justify;
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1.2rem;
        `;
      default:
        return null;
    }
  }}
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

export const List = styled.div`
  padding-right: 10px;
  overflow-y: auto;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
