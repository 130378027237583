import styled from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaTimes } from 'react-icons/fa';

import { selectUser, selectSelectedAccount, addNote, deleteNote } from '../../reducers/account.reducer';

export interface DashboardNotesProps {
  style?: any;
}

export const DashboardNotes = ({ style }: DashboardNotesProps) => {
  const [currentMessage, setCurrentMessage] = useState('');
  const selectedAccount = useSelector(selectSelectedAccount);
  const user = useSelector(selectUser);
  const dispatch = useDispatch<any>();
  const notesContainerElt = useRef(null);

  const handleSaveNote = async () => {
    dispatch(addNote({ accountId: selectedAccount._id, userId: user._id, notes: currentMessage }));
  };

  const handleDeleteNote = async (noteId: string) => {
    dispatch(deleteNote({ accountId: selectedAccount._id, noteId }));
  };

  useEffect(() => {
    notesContainerElt.current.scrollTo({
      top: notesContainerElt.current.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div style={style}>
      <Title>Notes : </Title>
      <DashboardNoteContainer>
        <NotesContainer ref={notesContainerElt}>
          {selectedAccount.notes.map((note, index) => (
            <Note key={index}>
              <NoteContent>{note.text}</NoteContent>
              <BottomNote>
                <Date>
                  Le <span>{new window.Date(note.date).toLocaleDateString()}</span>
                </Date>
                <Author>
                  par <span>{note.author.name}</span>
                </Author>
              </BottomNote>
              <Delete onClick={() => handleDeleteNote(note._id)}>
                <FaTimes />
              </Delete>
            </Note>
          ))}
        </NotesContainer>
        <InputContainer>
          <input value={currentMessage} onChange={e => setCurrentMessage(e.target.value)} type='text' />
          <Save onClick={handleSaveNote}>Ajouter</Save>
        </InputContainer>
      </DashboardNoteContainer>
    </div>
  );
};

const Title = styled.div`
  font-size: 1.125em;
  color: #000;
  font-weight: bold;
`;

const DashboardNoteContainer = styled.div`
  height: 240px;
  max-height: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  grid-row: span 5;
`;

const NotesContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #187ead;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
  position: relative;
  cursor: pointer;
`;

const Delete = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  svg {
    color: white;
    width: 20px;
    height: 20px;
  }
`;

const NoteContent = styled.div`
  color: white;
  font-family: 'Baloo 2';
  font-size: 1.3em;
  padding: 5px 40px 5px 0;
`;

const BottomNote = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
`;

const Date = styled.div`
  span {
    font-style: italic;
    font-size: 1.125em;
  }
`;

const Author = styled.div`
  span {
    font-style: italic;
    font-size: 1.125em;
  }
`;

const InputContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  border-top: 1px solid gray;

  input {
    flex: 1;
    border: none;
    padding: 0 20px;
    font-size: 1.3em;

    &:focus-visible {
      outline: none;
    }
  }
`;

const Save = styled.div`
  background-color: #187ead;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;
