import { createElement, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AiOutlineCloudSync, AiOutlineMobile } from 'react-icons/ai';
import { MdWeb } from 'react-icons/md';
import { RxQuestionMark } from 'react-icons/rx';
import { PiNewspaperClipping } from 'react-icons/pi';

import {
  selectCurrentSurvey,
  selectCurrentVersion,
  setCurrentEntity,
  setCurrentStep,
  setCurrentSurvey,
  setCurrentVersion,
  setModal,
} from '../../reducers/app.reducer';

import { paths } from '@routes/routes.constants';

import { COLORS, Z_INDEX } from '../../utils/constants';
import { filterSharedSteps, getAuthorizedSurveysByEntity, getDetailedSurveyById } from 'src/utils/utils';

export interface ServicesSubListMenuProps {
  selectedEntity;
  setSelectedEntity;
  closeSidenav;
}

const servicesMapping = {
  '5mzuhKMk4tUswBW5Ji3CTk': PiNewspaperClipping,
  isxn93frXpXETVwZ6R0IE: MdWeb,
  '1TqOqgfRJccp1NTv7Sn4uM': AiOutlineCloudSync,
  '3MyR4PRSqdfRaKko7552s5': AiOutlineMobile,
  '7zFmEXeJ0C5LH6x1TdQEGx': RxQuestionMark,
};

export const ServicesSubListMenu = ({ selectedEntity, setSelectedEntity, closeSidenav }: ServicesSubListMenuProps) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const preventDefault = e => e.preventDefault();
  const itemsRef = useRef(null);

  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentVersion = useSelector(selectCurrentVersion);

  const authorizedSurveys = getAuthorizedSurveysByEntity(selectedEntity);

  const getMap = () => (!itemsRef.current ? (itemsRef.current = new Map()) : itemsRef.current);

  const handleSubItemListRef = (entity, node) => {
    const map = getMap();
    if (node) {
      map.set(entity.name, node);
    } else {
      map.delete(entity.name);
    }
  };

  const getSubItemProps = (survey, service) => {
    return {
      onClick: async () => await handleVersionClick(survey, service),
      selected: currentVersion?._id === service?._id && survey?.id === currentSurvey?.id,
    };
  };

  const handleVersionClick = async (survey, version) => {
    dispatch(setCurrentEntity(selectedEntity));
    let fullSurvey = await getDetailedSurveyById(survey.id);
    dispatch(setCurrentSurvey(fullSurvey));
    dispatch(setCurrentVersion(version));
    closeSidenav();
    dispatch(setModal({ show: false, type: '' }));
    let targetedSteps = filterSharedSteps(fullSurvey.steps);
    dispatch(setCurrentStep({ id: targetedSteps[0]?.id, name: targetedSteps[0]?.name }));
    history.push(paths.survey);
  };

  const authorizedServicesIds = authorizedSurveys?.filter(survey => survey.versioning.enabled).map(survey => survey.id);
  const services = selectedEntity?.responses
    ?.filter(response => authorizedServicesIds?.includes(response.surveyId))
    .filter(elt => elt.versions.length);

  return (
    <CreateUserContainer>
      <TopContainer>
        <Title>Mes Services</Title>
      </TopContainer>
      <ItemList>
        {authorizedSurveys
          ?.filter(survey => survey.versioning.enabled)
          ?.map((survey, index) => {
            if (services?.find(service => service.surveyId === survey.id)) {
              return (
                <MenuItem key={index} onMouseOver={preventDefault} onMouseOut={preventDefault}>
                  <Entity>
                    {servicesMapping[survey.id] && createElement(servicesMapping[survey.id])}
                    <span>{survey?.name}</span>
                  </Entity>
                  {services
                    ?.filter(service => service.surveyId === survey.id)
                    .map((service, index) => (
                      <SubItemList ref={node => handleSubItemListRef(survey, node)} key={index}>
                        {service?.versions?.map((service, index) => {
                          return (
                            <SubItem key={`version_${index}`} {...getSubItemProps(survey, service)}>
                              <span>{service?.versionName}</span>
                            </SubItem>
                          );
                        })}
                      </SubItemList>
                    ))}
                </MenuItem>
              );
            }
          })}
      </ItemList>
    </CreateUserContainer>
  );
};

const Title = styled.div`
  color: white;
  font-size: 2.3em;
  color: ${COLORS.Black};
  font-weight: 700;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 25px;
    width: 25px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

const CreateUserContainer = styled.div`
  grid-column: span 5;
  grid-row: span 8;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  row-gap: 15px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  align-items: flex-start;
  row-gap: 20px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2rem;
`;

const MenuItem = styled.div<{ selected?: boolean; admin?: boolean }>`
  color: white;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: flex-start;

  span {
    margin-top: 3px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }
`;

const Entity = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: ${COLORS.Black};
  font-weight: 500;
  column-gap: 5px;
  width: 100%;

  span {
    flex: 1;
  }

  svg:last-child {
    width: 16px;
    height: 16px;
    margin-top: 1px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${COLORS.MediumGrey};
    padding: 5px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: ${Z_INDEX.below};
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    &:after {
      opacity: 0.3;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    &:after {
        opacity: 0.3;
        }
    `}
`;

const SubItemList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-left: 30px;
  height: fit-content;
  width: calc(100% - 35px);
  padding: 20px 0 0 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 2px;
    background-color: ${COLORS.MediumGrey};
    bottom: 30px;
    top: 10px;
  }
`;

const SubItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  color: ${COLORS.Black};
  transition: margin-left 0.2s;
  position: relative;

  svg,
  span {
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  &:hover {
    svg,
    span {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    `
     column-gap: 13px;
  
     svg, span {
      opacity: 1;
    }
    
    span {
        background-color: #EEEEEE;
        border-radius: 5px;
    }
  `}
  span {
    margin-top: 2px;
    line-height: 18px;
    max-width: 100%;
    padding: 5px 8px;
    transition: margin-left 0.2s;

    &:hover {
      margin-left: 3px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 15px;
    left: -20px;
    top: 55%;
    transform: translateY(-100%);
    border-bottom-left-radius: 10px;
    border-left: 2px solid ${COLORS.MediumGrey};
    border-bottom: 2px solid ${COLORS.MediumGrey};
  }
`;
