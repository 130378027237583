import { getRandomColorFromName } from '@utils/utils';

import { Initials } from './Avatar_.style';
import { AvatarProps } from './Avatar.type';

const getInitials = (name: string, firstName?: string): string => {
  if (firstName) {
    return `${name[0]}${firstName[0]}`.toUpperCase();
  }
  return name
    ?.toUpperCase()
    .split(' ')
    .map(word => word[0])
    .join('');
};

const Avatar: React.FC<AvatarProps> = ({ name, firstName, userId, size = 'medium' }) => {
  const initials = getInitials(name, firstName);
  const color = getRandomColorFromName(initials, userId);

  return (
    <Initials color={color} size={size}>
      {initials}
    </Initials>
  );
};

export default Avatar