import styled from 'styled-components';
import { COLORS } from '@utils/constants';

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const EntityTableContainer = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0 25px;
`;

export const Top = styled.div`
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 25px;
  user-select: none;

  > span {
    font-size: 26px;
    font-weight: 700;
    font-family: 'Nunito', serif;
  }

  svg {
    color: ${COLORS.darkBlue};
    opacity: 1;
  }
`;

export const RowsSlider = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHeader = styled.div`
  display: flex;
  column-gap: 20px;
  height: 60px;
  padding: 0 25px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  transition: background-color 0.2s ease-in-out;
  padding-right: 25px;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 20px;
  height: 60px;
  padding: 0 25px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Name = styled.span`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  column-gap: 5px;
  user-select: none;

  svg {
    color: ${COLORS.Squash};
    height: 24px;
    width: 24px;
  }
`;

export const Header = styled.span`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  column-gap: 5px;
  user-select: none;
  color: ${COLORS.lightGrey};
`;

export const PieContainer = styled.div`
  height: calc(100% - 8px);
`;

export const Splitter = styled.div`
  flex: 1;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  font-family: 'Roboto', serif;
  font-weight: 500;
  width: 250px;
  position: relative;
  justify-content: center;

  span {
    white-space: nowrap;
  }

  div {
    font-size: 12px;
    color: ${COLORS.lightGrey};
    font-weight: 500;
    text-decoration: underline;
  }

  svg {
    color: ${COLORS.lightGrey};
  }

  @media (max-width: 1260px) {
    &.collaborators,
    &.completed-surveys {
      display: none;
    }
  }
`;

export const DataHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 250px;
  position: relative;
  justify-content: center;
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: ${COLORS.lightGrey};

  @media (max-width: 1260px) {
    display: none;
  }
`;

export const MoreOptionsContainer = styled.div`
  height: 40px;
  width: 40px;
`;
