import styled from 'styled-components';

export const ChipContainer = styled.div<{
  variant: string;
  color: string;
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 16px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: all 0.3s ease;
  font-size: 1rem;

  ${({ variant, color, backgroundColor }) => {
    const bgColor = backgroundColor || `${color}20`;
    switch (variant) {
      case 'outlined':
        return `
          color: ${color};
          border: 1px solid ${color};
          background-color: ${bgColor};
        `;
      case 'text':
        return `
          color: ${color};
          background-color: ${backgroundColor ? backgroundColor : `transparent`};
        `;
      case 'outlined-text':
        return `
          color: ${color};
          border: 1px solid ${color};
          background-color: transparent;
        `;
      case 'filled':
      default:
        return `
          color: ${color};
          background-color: ${bgColor};
        `;
    }
  }}
  ${({ onClick }) =>
    onClick &&
    `
    &: hover {
      opacity: 0.8;
    }`};
`;

export const ChipText = styled.span<{ hasIcon: boolean }>`
  font-size: 1rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: ${({ hasIcon }) => (hasIcon ? '0 0.65rem' : '0 1rem')};
`;
export const IconContainer = styled.div<{ iconOnly: boolean }>`
  display: flex;
  justify-content: center;
  ${({ iconOnly }) =>
    iconOnly
      ? `
      padding: 0 0.5rem;
      `
      : `
        margin-left: 0.25rem;
        margin-right: -0.3rem;
      `};
`;
export const DeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.25rem;
  margin-left: -0.3rem;
  &:hover {
    opacity: 0.7;
  }
`;
