import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  Left,
  NotificationContainer,
  NotificationContent,
  NotificationContentContainer,
  NotificationDate,
  NotificationLabel,
  NotificationTitle,
  SmallRound,
} from './Notifications.styles';
import { Avatar } from '@mui/material';
import { stringAvatar } from 'src/utils/utils';
import { NotificationType } from './Notification.type';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

interface NotificationItemProps {
  notification: NotificationType;
  handleNotificationClick: (notification: NotificationType) => void;
  user: any;
}
const NotificationItem: React.FC<NotificationItemProps> = ({ notification, handleNotificationClick, user }) => {
  const { i18n } = useTranslation();

  // add support for locals depending on the app language
  dayjs.locale(i18n.language);

  const formatDate = inputDate => {
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    const now = new Date();

    const diff = now.getTime() - date.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 15) {
      return `Le ${dayjs(inputDate).format(`DD MMMM YYYY à HH:mm`)}`;
    }

    if (days > 0) {
      return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
    }
  };

  return (
    <div data-pop onClick={() => handleNotificationClick(notification)}>
      <NotificationContainer>
        <Left>{notification.isUnread && <SmallRound></SmallRound>}</Left>
        <NotificationContentContainer>
          <Avatar
            {...stringAvatar(
              user?.firstName
                ? `${user?.name?.toUpperCase()} ${user?.firstName?.toUpperCase()}`
                : user?.name
                  ? user?.name?.toUpperCase()
                  : user?.email?.toUpperCase(),
              user._id
            )}
          />

          <NotificationContent data-pop>
            <NotificationTitle>
              <p dangerouslySetInnerHTML={{ __html: notification.title }}></p>
            </NotificationTitle>
            {notification.label.length !== 0 && (
              <NotificationLabel>
                <p dangerouslySetInnerHTML={{ __html: notification.label }}></p>
              </NotificationLabel>
            )}
            <NotificationDate>{formatDate(notification.date)}</NotificationDate>
          </NotificationContent>
        </NotificationContentContainer>
      </NotificationContainer>
    </div>
  );
};

export default NotificationItem;
