import React, { useEffect, useRef, useState } from 'react';
import MessageList from './MessageList';
import { ChatContainer, MessageFooter, MessageListContainer, SendButton, SendContainer } from './Chat.styles';
import { InputComponent } from '../form/InputComponent';
import { LuSendHorizonal } from 'react-icons/lu';
import dayjs from 'dayjs';

import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { CommentProps } from '@components-new/common/types';
import { addEventTracking } from '@utils/utils';

interface UserProps {
  id: string;
  name: string;
  firstName: string;
}

interface ChatProps {
  isLoading: boolean;
  messages: CommentProps[];
  user: UserProps;
  handleMessage: (message: CommentProps) => Promise<void>;
  updateSeenStatus: () => void;
  deleteMessage: (filtredComment: CommentProps[]) => void;
  admin: boolean;
}

const Chat: React.FC<ChatProps> = ({ messages, isLoading, user, handleMessage, updateSeenStatus, deleteMessage, admin }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [sortedMessages, setSortedMessages] = useState<CommentProps[]>([]);
  const messageListRef = useRef(null);

  useEffect(() => {
    updateSeenStatus();
  }, []);

  useEffect(() => {
    const sortedMessages = [...messages].sort((a, b) => dayjs(a.time).unix() - dayjs(b.time).unix());
    setSortedMessages(sortedMessages);
  }, [messages]);

  const handleSubmit = async event => {
    addEventTracking('Chat', 'Add message');
    event.preventDefault();
    const newMessage: CommentProps = {
      _id: uuidv4(),
      text: message,
      time: new Date(),
      seenComment: [
        {
          seen: true,
          userID: user.id,
        },
      ],
      sender: {
        name: user.name,
        id: user.id,
      },
    };

    setSortedMessages(prevSortedMessages => [...prevSortedMessages, newMessage]);
    await handleMessage(newMessage);
    setMessage('');
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [sortedMessages]);

  const handleDeleteMessage = (commentID: string | number) => {
    addEventTracking('Chat', 'Delete message');
    const filteredComments = sortedMessages.filter(comment => comment._id !== commentID);
    deleteMessage(filteredComments);
  };

  return (
    <ChatContainer>
      <MessageListContainer ref={messageListRef}>
        <MessageList isLoading={isLoading} messages={sortedMessages} user={user} deleteMessage={handleDeleteMessage} />
      </MessageListContainer>
      {!admin && (
        <MessageFooter onSubmit={handleSubmit}>
          <SendContainer>
            <InputComponent
              type='text'
              placeholder={t('chat.leave_comment_placeholder')}
              value={message}
              onChange={event => setMessage(event.target.value)}
              required
            />
            <SendButton type='submit' size='large'>
              <LuSendHorizonal />
            </SendButton>
          </SendContainer>
          <span>{t('chat.note_to_user')}</span>
        </MessageFooter>
      )}
    </ChatContainer>
  );
};

export default Chat;
