import { useTranslation } from 'react-i18next';
import './index.scss';
import Flag from './FlagIcon';
import { SVG } from './languageSwitcher.styles';
// import { useCallback, useState } from 'react';
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // const [showDropDown, setShowDropDown] = useState(false);

  const handleLanguageChange = (language: 'en' | 'fr') => {
    i18n.changeLanguage(language);
  };

  // const handleShowDropDownChange = useCallback(e => {
  //   setShowDropDown(e.target.checked);
  // }, []);

  return (
    <>
      <div className='dropdown'>
        <input type='checkbox' id='dropdown' />

        <label className='dropdown__face' htmlFor='dropdown'>
          <div className='dropdown__text'>
            <Flag country={i18n.language === 'en' ? 'gb' : i18n.language} />
          </div>

          <div className='dropdown__arrow'></div>
        </label>

        <ul className='dropdown__items'>
          <li onClick={() => handleLanguageChange('en')}>
            <Flag country='gb' />
          </li>
          <li onClick={() => handleLanguageChange('fr')}>
            <Flag country='fr' />
          </li>
        </ul>
      </div>

      <SVG>
        <filter id='goo'>
          <feGaussianBlur in='SourceGraphic' stdDeviation='5' result='blur' />
          <feColorMatrix in='blur' type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7' result='goo' />
          <feBlend in='SourceGraphic' in2='goo' />
        </filter>
      </SVG>
    </>
  );
};

export default LanguageSwitcher;
