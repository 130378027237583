import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDbAccounts, updateEntityStep, updateAccountRights, updateDbAccount } from '../utils/api';
import { selectAccount, setAccount } from './account.reducer';

export const getAllAccounts = createAsyncThunk('dashboard/GET_ALL_USERS', async () => {
  let allAccounts = await getDbAccounts();
  let parsedAccounts = allAccounts.result.map(account => {
    let rights = account.actionRights ? account.actionRights : {};
    return { ...account, actionRights: rights };
  });
  return parsedAccounts;
});

export const updateRights = createAsyncThunk('dashboard/UPDATE_RIGHTS', async (options: { accountId: string; actionRights: any }, thunkApi) => {
  let state = thunkApi.getState();
  let account = selectAccount(state);
  let isSelectedAccount = account._id === options.accountId;
  let updatedAccount = await updateAccountRights(options.accountId, options.actionRights);
  if (isSelectedAccount) {
    thunkApi.dispatch(setAccount(updatedAccount));
  }

  return updatedAccount;
});

export const updateStep = createAsyncThunk(
  'dashboard/UPDATE_ENTITY_STEP',
  async (data: { accountId: any; entityName: string; steps; changeAction: any; entityCompletionPercentage: number }) => {
    return await updateEntityStep(data.accountId, data.entityName, data.steps, data.changeAction, data.entityCompletionPercentage);
  }
);

export const updateAccountInfos = createAsyncThunk(
  'dashboard/UPDATE_ACCOUNT_INFOS',
  async (options: { userId: string; selectedUser: any; emailToSales: boolean }) => {
    let response = await updateDbAccount(options.userId, JSON.stringify(options.selectedUser), options.emailToSales);
    return response;
  }
);

export const dashboardReducer = createSlice({
  name: 'USER',
  initialState: {
    allAccounts: [],
    selectedAccount: undefined,
  },
  reducers: {
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setAllAccounts: (state, action) => {
      state.allAccounts = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllAccounts.pending, () => {})
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.allAccounts = action.payload;
      })
      .addCase(getAllAccounts.rejected, () => {})
      .addCase(updateRights.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
      })
      .addCase(updateStep.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
      });
  },
});

export const { setSelectedAccount, setAllAccounts } = dashboardReducer.actions;

export default dashboardReducer.reducer;
