import CryptoJS from 'crypto-js';

// Encrypt and store a value in sessionStorage
export const setSessionValue = (key, value) => {
  try {
    const encryptedKey = CryptoJS.SHA256(`${key}`).toString().substring(0, 15);
    const encryptedValue = CryptoJS.AES.encrypt(`${JSON.stringify(value)}`, `${process.env.REACT_APP_SECRET}`).toString();
    sessionStorage.setItem(encryptedKey, encryptedValue);
  } catch (error) {
    console.error('Error storing encrypted value:', error);
  }
};

//
// Example usage: Encrypt and store a value with a key 'encryptedData'
// setEncryptedValue('encryptedData', 'sensitive information');
//

// Retrieve and decrypt the value from sessionStorage
export const getSessionValue = key => {
  try {
    const encryptedKey = CryptoJS.SHA256(key).toString().substring(0, 15);
    const encryptedValue = sessionStorage.getItem(encryptedKey);
    if (encryptedValue) {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedValue, `${process.env.REACT_APP_SECRET}`);
      const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    }
    return null;
  } catch (error) {
    console.error('Error retrieving or decrypting value:', error);
    return null;
  }
};

//
// Example usage: Retrieve and decrypt the 'encryptedData' value
// const decryptedData = getDecryptedValue('encryptedData');
//

//
// State encryption and decryption functions
//

export const encryptState = state => {
  const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(state), `${process.env.REACT_APP_SECRET}`).toString();
  return encryptedState;
};

export const decryptState = (encryptedState: string) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedState, `${process.env.REACT_APP_SECRET}`);
  const decryptedState = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedState);
};
