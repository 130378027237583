import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImFilesEmpty } from 'react-icons/im';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdCheckCircle, MdErrorOutline } from 'react-icons/md';

import { selectAccount, selectActiveAccount, selectImpersonated, selectUser } from '../../reducers/account.reducer';

import {
  selectCurrentEntity,
  selectCurrentSurvey,
  selectLoading,
  setCurrentStep,
  setCurrentSurvey,
  setCurrentVersion,
  setModal,
  setShareModal,
} from '../../reducers/app.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import { paths } from '@routes/routes.constants';
import { SurveyCard } from '../survey-card/SurveyCard';
import { Button } from '../Button';

import {
  getAuthorizedSurveys,
  getDetailedSurveyById,
  filterSharedVersions,
  filterSharedSteps,
  addEventTracking,
  handleConditions,
  isSurveyDisabled,
} from '../../utils/utils';
import { ACCOUNT_TYPES, COLORS, MODAL_TYPES, Z_INDEX } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

export interface DashboardEntityProps {}

const DashboardEntity = () => {
  const { t } = useTranslation();
  const account = useSelector(selectAccount);
  const impersonated = useSelector(selectImpersonated);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const contentfulData = useSelector(selectAllContentfulData);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const activeAccount = useSelector(selectActiveAccount);
  const currentUser = useSelector(selectUser);

  const authorizedSurveys = getAuthorizedSurveys();

  const versionableSurveys = authorizedSurveys?.filter(survey => survey.versioning.enabled);

  const handleShowSurveyModal = async (version, option, surveyId) => {
    let fullSurvey = await getDetailedSurveyById(surveyId);
    dispatch(setCurrentSurvey(fullSurvey));
    dispatch(setCurrentVersion(version));
    switch (option) {
      case 'duplicate':
        dispatch(setModal({ show: true, type: MODAL_TYPES.DUPLICATE_PRODUCT }));
        break;
      case 'rename':
        dispatch(setModal({ show: true, type: MODAL_TYPES.RENAME_PRODUCT }));
        break;
      case 'delete':
        dispatch(setModal({ show: true, type: MODAL_TYPES.DELETE_PRODUCT }));
        break;
      default:
        break;
    }
  };

  const getChipsLabel = (version, surveyId) => {
    let { completedQuestions, completionPercentage } = version?.completion;
    if (isSurveyDisabled(surveyId, false)) {
      return { color: COLORS.softRed, label: t('common.disabled') };
    }
    if (completionPercentage === 100) {
      return { color: COLORS.AquaMarine, label: t('common.validated') };
    }
    if (completedQuestions === 0) {
      return { color: COLORS.DarkGreen, label: t('common.toFill') };
    }
    if (completedQuestions > 0 && completionPercentage !== 100) {
      return { color: COLORS.lightNavy, label: t('common.inProgress') };
    }
  };

  const getSurveyCardProps = (version, surveyId) => {
    let survey = contentfulData?.surveys?.find(survey => survey.id === surveyId);

    return {
      versionable: survey.versioning.enabled,
      title: version?.versionName,
      data: [
        {
          icon: MdCheckCircle,
          label: `${version?.themes?.filled}/${version?.themes?.amount} ${t('dashboardEntity.completedSteps')}`,
          color: COLORS.AquaMarine,
        },
        {
          icon: MdErrorOutline,
          label: `${version?.themes?.amount - version?.themes?.filled} ${t('dashboardEntity.stepsToComplete')}`,
          color: COLORS.Squash,
        },
      ],
      surveyName: survey.name,
      chipsLabel: getChipsLabel(version, surveyId),
      percentage: Math.round(version?.completion?.completionPercentage),
      button: {
        label: t('dashboardEntity.goToForm'),
        onClick: async () => {
          //enhance surveys for shared item
          //because of getLinkedQuestions check on StepQuestions
          if (currentUser.role === 'guest') {
            currentUser.sharedItems.questions.forEach(sharedItem => getDetailedSurveyById(sharedItem.surveyId));
          }
          let fullSurvey = await getDetailedSurveyById(surveyId);
          let sharedSteps = filterSharedSteps(fullSurvey.steps)?.filter(targetStep => handleConditions(targetStep, version));
          dispatch(setCurrentSurvey(fullSurvey));
          dispatch(setCurrentVersion(version));
          dispatch(setCurrentStep({ id: sharedSteps?.[0].id, name: sharedSteps?.[0].name }));
          history.push(paths.survey);
        },
      },
      onOptionsClick: {
        share: () => shareItem(version, survey.id),
        duplicate: async () => await handleShowSurveyModal(version, 'duplicate', survey.id),
        rename: async () => await handleShowSurveyModal(version, 'rename', survey.id),
        delete: async () => await handleShowSurveyModal(version, 'delete', survey.id),
      },
      disabled: version.disabled,
    };
  };

  const shareItem = (version, targetSurveyId) => {
    dispatch(setCurrentVersion(version));
    dispatch(
      setShareModal({
        targetSurveyId: targetSurveyId,
        type: 'surveyVersions',
        id: version._id,
        label: version.versionName,
      })
    );
    dispatch(setModal({ show: true, type: MODAL_TYPES.SHARE }));
  };

  const handleSubmitEntity = async () => {
    // if (currentEntity.completionPercentage === 100) {
    addEventTracking('Entity', 'Submit entity', 'Via dashboard entity');
    dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION }));
    // } else {
    // dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_ERRORS }));
    // }
  };

  const isVersionForSurvey = (targetSurvey, group?) => {
    if (group) {
      let responses = [];
      versionableSurveys?.map(surveyItem => {
        return responses.push(
          (impersonated ?? account)?.entities
            .find(entityItem => entityItem.name === currentEntity.name)
            ?.responses.find(survey => survey.surveyId === surveyItem.id)
        );
      });
      return responses.every(response => !response?.versions?.length);
    } else {
      return (impersonated ?? account)?.entities
        .find(entityItem => entityItem.name === currentEntity.name)
        ?.responses.find(survey => survey.surveyId === currentSurvey.id)?.length;
    }
  };

  const getSurveyCards = (products: boolean = false) => {
    if (products) {
      let responses = [];
      versionableSurveys
        ?.filter(survey => isSurveyVisible(survey.id))
        .map(surveyItem => {
          return responses.push(
            (impersonated ?? account)?.entities
              .find(entityItem => entityItem.name === currentEntity.name)
              ?.responses.find(survey => survey.surveyId === surveyItem.id)
          );
        });
      let isAllEmpty = responses.every(response => !response?.versions?.length);
      if (!responses.length || isAllEmpty) {
        return (
          <Empty>
            <span>{t('dashboardEntity.noServices')}</span>
            <Button
              style={{
                backgroundColor: `${COLORS.darkBlue}`,
              }}
              onClick={displayNewProductModal}
              label={t('dashboardEntity.addService')}
              icon={IoMdAddCircleOutline}
            />
          </Empty>
        );
      }

      let cardProps = responses.map(element => {
        return filterSharedVersions(_.cloneDeep(element)?.versions)
          ?.sort((a, b) => {
            return new Date(a.creationDate) > new Date(b.creationDate) ? 1 : -1;
          })
          ?.map((version, index) => <SurveyCard key={index} {...getSurveyCardProps(version, element.surveyId)} />);
      });

      return cardProps;
    } else {
      let responses = activeAccount?.entities
        .find(entityItem => entityItem.name === currentEntity.name)
        ?.responses.find(survey => survey.surveyId === currentSurvey.id);

      if (!responses?.versions?.length) {
        return (
          <Empty>
            <ImFilesEmpty />
            <span>{t('dashboardEntity.noServices')}</span>
          </Empty>
        );
      }

      let sharedVersionsCards = filterSharedVersions(responses.versions)?.map((version, index) => {
        return <SurveyCard key={index} {...getSurveyCardProps(version, currentSurvey.id)} />;
      });

      return sharedVersionsCards;
    }
  };

  const displayNewProductModal = () => {
    dispatch(setModal({ show: true, type: MODAL_TYPES.NEW_PRODUCT }));
  };

  const isSubmissionDisabled: boolean = useMemo(() => {
    let entity = activeAccount?.entities?.find(entityItem => entityItem.name === currentEntity?.name);
    let submission = entity?.submission;

    let isAuthorized =
      currentUser?.role !== 'guest' ??
      currentUser?.sharedItems?.entities?.includes(sharedEntity => sharedEntity.entityId === currentEntity?._id && sharedEntity.shareTarget);
    return !isAuthorized || submission?.submitted || submission?.validated;
  }, [account, currentUser]);

  if (!currentEntity || !currentSurvey) {
    return <Redirect to={paths.dashboardGroup} />;
  }

  const isSurveyVisible = surveyId => {
    return account.accountType === ACCOUNT_TYPES.ADMIN || !isSurveyDisabled(surveyId, false);
  };

  return (
    <>
      {!loading && currentEntity && contentfulData && currentSurvey && (
        <DashboardEntityContainer>
          <DashboardContent>
            <EntityTop>
              <ContentTitle>
                <div>
                  <span>{(impersonated ?? account).companyName}</span>
                  <span>-</span>
                  <span>{currentEntity.name}</span>
                </div>
                <span>{t('dashboardEntity.mySurveys')}</span>
              </ContentTitle>
              <ButtonContainer>
                {!isVersionForSurvey(currentSurvey, currentSurvey.versioning.enabled) && currentSurvey.versioning.enabled && (
                  <Button
                    style={{
                      backgroundColor: `${COLORS.darkBlue}`,
                    }}
                    onClick={displayNewProductModal}
                    label={t('dashboardEntity.addService')}
                    icon={IoMdAddCircleOutline}
                  />
                )}
                <Button onClick={handleSubmitEntity} label={t('dashboardEntity.submitEntity')} disabled={isSubmissionDisabled} />
              </ButtonContainer>
            </EntityTop>
            <TabContainer>
              {authorizedSurveys
                ?.filter(survey => !survey.versioning.enabled)
                .map((survey, index) => {
                  return (
                    isSurveyVisible(survey.id) && (
                      <Tab
                        selected={survey.id === currentSurvey?.id}
                        onClick={() => dispatch(setCurrentSurvey(survey))}
                        key={index}
                        disabled={isSurveyDisabled(survey.id, false)}>
                        <span>{survey?.name}</span>
                      </Tab>
                    )
                  );
                })}
              {versionableSurveys.length > 0 && (
                <Tab
                  selected={versionableSurveys.some(survey => survey.id === currentSurvey?.id)}
                  onClick={() => dispatch(setCurrentSurvey(versionableSurveys[0]))}
                  disabled={false}>
                  <span>{t('dashboardEntity.services')}</span>
                </Tab>
              )}
            </TabContainer>
            <ContentContainer empty={isVersionForSurvey(currentSurvey, currentSurvey.versioning.enabled)}>
              {getSurveyCards(currentSurvey.versioning.enabled)}
            </ContentContainer>
          </DashboardContent>
        </DashboardEntityContainer>
      )}
    </>
  );
};

export default DashboardEntity;

const EntityTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Empty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  align-self: center;

  svg {
    font-size: 80px;
    animation: swing 0.8s ease;
  }

  span {
    font-size: 1.2em;
  }
`;

const ContentContainer = styled.div<{ empty: boolean }>`
  display: grid;
  grid-template-columns: ${({ empty }) => (empty ? '1fr' : 'repeat(auto-fill, minmax(380px, 1fr))')};
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  ${({ empty }) =>
    empty &&
    css`
      flex: 1;
    `}
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
`;

const Tab = styled.div<{ selected: boolean; disabled: boolean }>`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? COLORS.softRed : 'white')};
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  border: ${COLORS.MediumGrey} solid 1px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.darkBlue};
    color: white;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.darkBlue};
      color: white;
    `}
`;

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;

  div {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${COLORS.lightGrey};
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

const DashboardEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 80px 50px 80px;
  height: calc(100% - 130px);
  width: calc(100% - 160px);
`;

const DashboardContent = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c8e3f6;
    opacity: 30%;
    z-index: ${Z_INDEX.below};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
