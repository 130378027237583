import { InputComponent } from '@components/form/InputComponent';
import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const ProductImage = styled.img`
  width: 25px;
  height: 25px;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.MediumGrey};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: 10px;

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.darkBlue};
    border-radius: 10px;
  }
`;

export const ProductItemWrapper = styled.div<{ isLast: boolean }>`
  border-right: 1px solid ${COLORS.MediumGrey};
  box-sizing: border-box;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : `1px solid ${COLORS.MediumGrey}`)};
`;

export const CreateProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

export const StyledInputComponent = styled(InputComponent)`
  input {
    background-color: rgba(221, 241, 249, 0.63);
  }
`;

export const ProductAddedContainer = styled.div`
  align-items: center;
  justify-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const ProductAddedText = styled.div`
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  text-align: center;
`;

export const ProductAddedName = styled.div`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: center;
`;

export const FormCompletionNotice = styled.div`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  text-align: center;
`;
