import { Tabs } from '@components-new/atoms';

import { PageWrapperProps } from './PageWrapper.type';
import { Content, PageTitle } from './PageWrapper_.style';

const PageWrapper = ({ children, selectedView, views, showPageTitle, applyPadding = false, customPadding = null }: PageWrapperProps) => {
  return (
    <Content applyPadding={applyPadding} customPadding={customPadding}>
      {showPageTitle && <PageTitle>{selectedView.label}</PageTitle>}
      {views && <Tabs views={views} selectedView={selectedView.value} />}
      {children}
    </Content>
  );
};

export default PageWrapper;