import styled, { css } from 'styled-components';

export const Action = styled.div<{ enabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ enabled }) => (enabled ? 0.8 : 0.4)};
  transition: opacity 0.2s;
  user-select: none;

  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};

  ${({ enabled }) =>
    enabled &&
    css`
      &:hover {
        opacity: 1;
      }
    `}
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const ConformityErrorsSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  padding: 20px;
  min-height: 0;
  row-gap: 20px;
`;

export const ErrorItem = styled.div`
  display: flex;
  border: 1px solid #dfe6e9;
  padding: 5px;
  margin: 0 10px;

  border-radius: 5px;
  flex-direction: column;
  line-height: 20px;

  span {
    color: #797979;

    b {
      color: #ff7675;
    }
  }
`;

export const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  row-gap: 10px;
`;

export const ErrorsActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
`;

export const ErrorTitle = styled.div`
  font-size: 1.2em;
  text-align: center;

  b {
    color: #1179a0;
  }
`;

export const Label = styled.div``;

export const Slider = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const SurveyName = styled.div``;
