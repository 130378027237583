import './IconStatus.scss';

const IconStatus = ({ status }) => {
  if (status === 'success') {
    return (
      <div className='icon success icon-show' style={{ display: 'flex' }}>
        <div className='success-circular-line-left' style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
        <span className='success-line-tip' /> <span className='success-line-long' />
        <div className='success-ring' />
        <div className='success-fix' style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
        <div className='success-circular-line-right' style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
      </div>
    );
  }
  if (status === 'error') {
    return (
      <div className='icon error icon-show' style={{ display: 'flex' }}>
        <span className='x-mark'>
          <span className='x-mark-line-left' />
          <span className='x-mark-line-right' />
        </span>
      </div>
    );
  }
};

export default IconStatus;
