import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const SubTitleContainer = styled.span`
  color: ${COLORS.lightGrey};
`;

export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 10px;
`;
