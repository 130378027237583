import { getRandomColorFromName } from 'src/utils/utils';
import styled from 'styled-components';

export interface InitialProps {
  name: string;
  firstName?: string;
  color?: string;
  userId: string;
}

export const Initial = ({ name, firstName, userId }: InitialProps) => {
  let initials;
  if (firstName) {
    initials = `${name[0].toUpperCase()}${firstName[0].toUpperCase()}`;
  } else {
    initials = name
      ?.toUpperCase()
      ?.split(' ')
      .map(word => word[0])
      .join('');
  }
  return <Initials color={getRandomColorFromName(initials, userId)}>{initials}</Initials>;
};

const Initials = styled.div<{ color: string }>`
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: ${({ color }) => (color ? color : 'rgba(244, 157, 29, 0.6)')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
  }
`;
