import { useRef, useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const useDidUpdate = (fn, inputs) => {
  const didMountRef = useRef(false);
  const prevInputsRef = useRef(inputs);

  const memoizedFn = useCallback(fn, [fn]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!isEqual(prevInputsRef.current, inputs)) {
        memoizedFn();
      }
    } else {
      didMountRef.current = true;
    }
    prevInputsRef.current = inputs;
  }, [inputs, memoizedFn]);
};

export default useDidUpdate;
