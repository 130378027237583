import styled from 'styled-components';
import Select from 'react-select';

import MenuItem from '@mui/material/MenuItem';

export const StyledMenuItem = styled(MenuItem)`
  && {
    &:hover {
      background: none;
    }

    &:active,
    &:focus {
      background: none;
      outline: none;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 20rem;
`;
