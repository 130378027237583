import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { InputComponent } from '../form/InputComponent';
import { Button } from '../Button';

import { removeUserFromAccount, selectUser, updateAllUsers } from '../../reducers/account.reducer';
import { Modal } from '../modal/Modal';
import { useEffect, useState } from 'react';
import {
  ActionsContainer,
  AdminUserListContainer,
  ButtonsContainer,
  ConfirmContainer,
  MessageContainer,
  UserListBody,
  UserListDataContent,
  UserListHeader,
} from './Administration.styles';
import { RiAddLine } from 'react-icons/ri';
import { FaRegSave } from 'react-icons/fa';
import { setModal } from 'src/reducers/app.reducer';
import { USER_ROLES, MODAL_TYPES } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';

interface AdministrationProps {
  account: any;
  setAccount: any;
  admin: boolean;
}

export const Administration: React.FC<AdministrationProps> = ({ account, setAccount, admin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const user = useSelector(selectUser);
  const [showModal, setShowModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [userList, setUserList] = useState([]);
  const [newAdminUserList, setNewAdminUserList] = useState([]);

  useEffect(() => {
    const accountCopy = _.cloneDeep(account);
    setUserList([...accountCopy.users]);
  }, [account]);

  const handleUserInfosChange = (name, user, value = undefined, isCheckBox: boolean = false) => {
    if (name.target) {
      value = name.target.value;
      name = name.target.name;
    }

    let isRoleChange = name === 'role' && account.users.find(rawUser => rawUser._id === user._id).role !== value;
    let updatedList = _.cloneDeep(newAdminUserList);

    if (isRoleChange && !updatedList.includes(user._id)) {
      updatedList.push(user._id);
    }
    if (!isRoleChange && updatedList.includes(user._id)) {
      updatedList = updatedList.filter(item => item !== user._id);
    }
    setNewAdminUserList(updatedList);

    setUserList(prevUserList => {
      const updatedUserList = prevUserList.map(userAccount => {
        if (userAccount._id === user._id) {
          return {
            ...userAccount,
            [name]: isCheckBox ? name.target.checked : value,
          };
        }
        return userAccount;
      });
      return updatedUserList;
    });
  };

  const handleAccountOfficerChange = async (e, user) => {
    const accountCopy = _.cloneDeep(account);
    accountCopy.users.forEach(userItem => (userItem.role = userItem._id === user._id ? 'accountOfficer' : 'admin'));
    await dispatch(setAccount(accountCopy));
  };

  const handlePopUp = async user => {
    if (user.role === 'accountOfficer') {
      setShowModal(true);
      return;
    }
    setSelectedUser(user);
    setDisplayConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setSelectedUser(null);
    setDisplayConfirmModal(false);
  };

  const deleteUser = user => {
    dispatch(removeUserFromAccount({ userId: user._id }));
    handleCloseConfirmModal();
    toast.info(t('administration.user_deleted', { name: user?.name }));
  };

  const handleAddUser = async () => {
    await dispatch(setModal({ show: true, type: MODAL_TYPES.ADD_USER }));
  };

  const handleUpdateAllUsers = async () => {
    dispatch(updateAllUsers({ accountId: account._id, users: userList, newAdminUserList }));
    toast.info(t('administration.user_info_updated'));
  };

  const accountRoleOptions = [
    { label: t('common.account_officer'), value: USER_ROLES.AccountOfficer },
    { label: t('common.admin'), value: USER_ROLES.ADMIN },
    { label: t('common.guest'), value: USER_ROLES.Guest },
  ];

  const getAccountRoleOptions = role => {
    switch (role) {
      case USER_ROLES.AccountOfficer:
        return [{ label: t('common.account_officer'), value: USER_ROLES.AccountOfficer }];
      case USER_ROLES.ADMIN:
        return [{ label: t('common.admin'), value: USER_ROLES.ADMIN }];
      case USER_ROLES.Guest:
        return [
          { label: t('common.admin'), value: USER_ROLES.ADMIN },
          { label: t('common.guest'), value: USER_ROLES.Guest },
        ];
    }
  };

  return (
    <AdminUserListContainer>
      <ActionsContainer>
        <Button label={t('administration.add_admin')} icon={RiAddLine} onClick={handleAddUser} />
        <Button label={t('administration.save_changes')} icon={FaRegSave} onClick={handleUpdateAllUsers} type={'success'} />
      </ActionsContainer>

      <UserListHeader admin={admin}>
        <span>{t('common.number')}</span>
        <span>{t('common.first_name')}</span>
        <span>{t('common.name')}</span>
        <span>{t('common.email')}</span>
        {!admin && <span>{t('common.role')}</span>}
        {admin && <span>{t('administration.receive_emails')}</span>}
        {admin && <span>{t('common.gdpr_ref')}</span>}
        <span>{t('common.actions')}</span>
      </UserListHeader>
      <UserListBody admin={admin}>
        {userList.map((accountUser, index) => {
          return (
            <UserListDataContent key={`accountUser-${index}`}>
              <div>{index + 1}</div>
              <InputComponent
                type={'text'}
                name={'firstName'}
                disabled={accountUser._id === user._id}
                key={`firstName-${index}`}
                onChange={e => handleUserInfosChange(e, accountUser)}
                value={accountUser?.firstName}
              />
              <InputComponent
                type={'text'}
                name={'name'}
                disabled={accountUser._id === user._id}
                key={`accountUserName-${index}`}
                onChange={e => handleUserInfosChange(e, accountUser)}
                value={accountUser?.name}
              />
              <InputComponent
                type={'text'}
                name={'email'}
                disabled={accountUser._id === user._id}
                key={`accountUserEmail-${index}`}
                onChange={e => handleUserInfosChange(e, accountUser)}
                value={accountUser?.email}
              />
              {!admin && (
                <InputComponent
                  onChange={(e, value) => handleUserInfosChange(e, accountUser, value)}
                  type='select'
                  name={'role'}
                  disabled={accountUser._id === user._id}
                  options={getAccountRoleOptions(account.users.find(rawUser => rawUser._id === accountUser._id)?.role)}
                  sharable={false}
                  value={accountRoleOptions.find(option => option.value === accountUser.role)}
                />
              )}
              {admin && (
                <div>
                  <label style={{ marginTop: '10px' }} htmlFor={`emailCheckbox-${index}`}>
                    <input
                      disabled={accountUser._id === user._id}
                      defaultChecked={accountUser?.receivesEmails}
                      id={`emailCheckbox-${index}`}
                      name={'receivesEmails'}
                      type='checkbox'
                      onChange={e => handleUserInfosChange(e, accountUser, undefined, true)}
                    />
                  </label>
                </div>
              )}
              {admin && (
                <div>
                  <label style={{ marginTop: '10px' }} htmlFor={`accountOfficerCheckbox-${index}`}>
                    <input
                      disabled={accountUser._id === user._id}
                      defaultChecked={accountUser?.role === 'accountOfficer'}
                      id={`accountOfficerCheckbox-${index}`}
                      name={'accountOfficer'}
                      type='radio'
                      onChange={e => handleAccountOfficerChange(e, accountUser)}
                    />
                  </label>
                </div>
              )}

              <Button label={t('common.delete')} type={'danger'} disabled={accountUser._id === user._id} onClick={() => handlePopUp(accountUser)} />
            </UserListDataContent>
          );
        })}
      </UserListBody>
      <Modal show={showModal} title={t('administration.modal_title')} onClose={() => setShowModal(false)}>
        <MessageContainer>
          <div>{t('administration.modal_content')}</div>
        </MessageContainer>
      </Modal>
      <Modal show={displayConfirmModal} title={t('administration.confirm_title')} onClose={handleCloseConfirmModal}>
        <ConfirmContainer>
          <div>{t('administration.confirm_content', { name: selectedUser?.name })}</div>
          <ButtonsContainer>
            <Button onClick={() => deleteUser(selectedUser)} label={t('common.yes')} />
            <Button onClick={handleCloseConfirmModal} label={t('common.no')} />
          </ButtonsContainer>
        </ConfirmContainer>
      </Modal>
    </AdminUserListContainer>
  );
};
