import { COLORS } from '../../utils/constants';
import styled, { css } from 'styled-components';
import { MdOutlineLightbulb } from 'react-icons/md';
import { IconType } from 'react-icons';
import { Button } from '../Button';

export interface InfosProps {
  text: string;
  icon?: IconType;
  action?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
}

export const Infos = ({ icon, text, action, className }: InfosProps) => {
  let Icon = icon ?? MdOutlineLightbulb;

  return (
    <InfosContainer customIcon={icon} className={className}>
      <Icon />
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
      {action && <Button label={action.text} onClick={action.onClick} />}
    </InfosContainer>
  );
};

const InfosContainer = styled.div<{ customIcon: IconType }>`
  border-radius: 20px;
  background-color: ${COLORS.LightOrange};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-family: 'Roboto', serif;
  font-weight: 400;
  animation: fadeInUp 0.3s ease-in-out;
  position: relative;
  row-gap: 20px;

  svg {
    position: absolute;
    font-size: 40px;
    ${({ customIcon }) =>
      !customIcon &&
      css`
        transform: rotate(10deg);
      `}
    color: ${COLORS.Squash};
    top: -25px;
    right: 15px;
  }
`;
