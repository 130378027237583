import styled from 'styled-components';

const Footer = ({ children, align = 'end' }) => {
  return <FooterContainer align={align}>{children}</FooterContainer>;
};

const FooterContainer = styled.div<{ align?: string }>`
  background-color: #f6f5f5;
  padding: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => {
    switch (align) {
      case 'start':
        return 'flex-start';
      case 'between':
        return 'space-between';
      default:
        return 'flex-end';
    }
  }};
  ${({ align }) => {
    return align === 'full' && '> * {flex-grow: 1;}';
  }};

  > :not(:last-child) {
    margin-right: 1.125rem;
  }

  @media (max-width: 43rem) {
    border-top: 1px solid #dee2e6;
    background-color: transparent;
  }
`;
export default Footer;
