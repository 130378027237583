import { MdNotificationsNone } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { paths } from '@routes/routes.constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAccount, selectImpersonated, selectUser, setAccount, setUser } from '../../reducers/account.reducer';
import { setCurrentEntity, setCurrentStep, setCurrentSurvey, setCurrentVersion, setSelectedCollaborator } from '../../reducers/app.reducer';
import { getAuthorizedSurveys, getDetailedSurveyById, getSharedItemType, getSurveyVersionById } from '../../utils/utils';
import NotificationItem from './NotificationItem';
import {
  AllNotificationContainer,
  AllReadContainer,
  EmptyNotificationContainer,
  HeaderContainer,
  HeaderNotificationsContainer,
  NotificationBadge,
  NotificationIconButton,
  NotificationsContainer,
} from './Notifications.styles';
import DropDownMenu from '../dropDownMenu/DropDownMenu';
import { updateNotification, updateNotifications } from 'src/utils/api';
import { useEffect, useMemo, useState } from 'react';
import { NotificationType } from './Notification.type';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '@utils/constants';

export interface NotificationsComponentProps {
  notificationsList: NotificationType[];
}

export const Notifications = ({ notificationsList }: NotificationsComponentProps) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);
  const impersonated = useSelector(selectImpersonated);
  const user = useSelector(selectUser);
  const authorizedSurveys = getAuthorizedSurveys();

  const history = useHistory();

  const selectedUser = useMemo(() => impersonated?.users?.find(user => user.role === USER_ROLES.AccountOfficer) ?? user, [impersonated, user]);

  const [notifications, setNotifications] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (notificationsList) {
      const sortedNotifications = [...notificationsList].sort((a, b) => {
        if (a.date && b.date) {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        }
        return 0;
      });
      setNotifications(sortedNotifications);
    }
  }, [notificationsList]);

  const updateNotificationStatus = async (notification: NotificationType) => {
    if (notification.isUnread) {
      const newNotification = {
        ...notification,
        isUnread: false,
      };
      let updatedAccount = await updateNotification(selectedUser._id, newNotification);
      dispatch(setAccount(updatedAccount));
      let updatedUser = updatedAccount.users.find(userItem => userItem._id === selectedUser._id);
      dispatch(setUser(updatedUser));
      const notificationIndex = notifications.findIndex(notif => notif._id === newNotification._id);

      if (notificationIndex !== -1) {
        const updatedNotifications = [...notifications.slice(0, notificationIndex), newNotification, ...notifications.slice(notificationIndex + 1)];
        setNotifications(updatedNotifications);
      }
    }
  };

  const getSharedItemLink = async sharedItem => {
    let sharedItemType = getSharedItemType(sharedItem);
    let sharedEntity = activeAccount.entities.find(entity => entity._id === sharedItem.entityId);

    let detailedSurvey = null;
    let detailedVersion = null;
    let detailedStep = null;

    if (sharedItem.surveyId) {
      detailedSurvey = await getDetailedSurveyById(sharedItem.surveyId);
    }
    if (sharedEntity && sharedItem.surveyId && sharedItem.surveyVersionId) {
      detailedVersion = getSurveyVersionById(sharedEntity, sharedItem.surveyId, sharedItem.surveyVersionId);
    }
    if (detailedSurvey) {
      detailedStep = detailedSurvey?.steps.find(step => step.id === sharedItem.surveyStepId);
    }

    dispatch(setCurrentEntity(sharedEntity ?? activeAccount.entities[0]));

    switch (sharedItemType) {
      case 'entities':
        dispatch(setCurrentSurvey(authorizedSurveys?.[0]));
        return paths.dashboardEntity;
      case 'surveyVersions':
        dispatch(setCurrentSurvey(detailedSurvey));
        dispatch(setCurrentVersion(detailedVersion));
        dispatch(setCurrentStep({ id: detailedSurvey.steps[0].id, name: detailedSurvey.steps[0].name }));
        return paths.survey;
      case 'surveySteps':
        dispatch(setCurrentSurvey(detailedSurvey));
        dispatch(setCurrentVersion(detailedVersion));
        dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
        return paths.survey;
      case 'questions':
        dispatch(setCurrentSurvey(detailedSurvey));
        dispatch(setCurrentVersion(detailedVersion));
        dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
        return paths.survey;
      case 'actions':
        const entityId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[1] : sharedItem.shareId.split('-')[1];
        const relatedProductId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[3] : sharedItem.shareId.split('-')[3];
        const actionId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[5] : sharedItem.shareId.split('-')[5];
        const versionProductId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[7] : sharedItem.shareId.split('-')[7];
        const selectedView = sharedItem.message ? 'chat' : 'detail';
        return `/plan-action/${actionId}|${relatedProductId}|${entityId}|${versionProductId}|${selectedView}`;
    }
  };

  const getRedirectLink = async notification => {
    switch (notification.redirect) {
      case 'collaborators':
        let collaborator = activeAccount.users.find(user => user._id === notification.collaboratorId);
        dispatch(setSelectedCollaborator(collaborator));
        return { url: paths.collaborators, newWindow: false };
      case 'dashboard_entity':
        dispatch(setCurrentEntity(activeAccount.entities[0]));
        dispatch(setCurrentSurvey(authorizedSurveys?.[0]));
        return { url: paths.dashboardEntity, newWindow: false };
      case 'plan-action':
        const selectedEntity = notification.additionalInformations.entityId;
        if (selectedEntity) {
          const entity = activeAccount.entities.find(entity => entity._id === selectedEntity);
          dispatch(setCurrentEntity(entity));
        }
        dispatch(setCurrentSurvey(authorizedSurveys?.[0]));
        return { url: paths.userAction, newWindow: false };
      case 'google_agenda':
        return {
          url: `${
            /^(http:\/\/|https:\/\/)/.test(activeAccount?.links?.google_agenda)
              ? activeAccount?.links?.google_agenda
              : 'https://' + activeAccount?.links?.google_agenda
          }`,
          newWindow: true,
        };
      case 'google_drive':
        return {
          url: `${
            /^(http:\/\/|https:\/\/)/.test(activeAccount?.links?.google_drive)
              ? activeAccount?.links?.google_drive
              : 'https://' + activeAccount?.links?.google_drive
          }`,
          newWindow: true,
        };
      case 'shared_item':
        let url = await getSharedItemLink(notification.sharedItem);
        return { url, newWindow: false };
    }
  };

  const handleNotificationClick = async notification => {
    let notificationLink = await getRedirectLink(notification);
    if (notification.redirect) {
      if (notificationLink.newWindow) {
        window.open(notificationLink.url, '_blank');
      } else {
        history.push(notificationLink.url);
      }
    }
  };

  const generateNotificationList = (notifications, handleNotificationClick, activeAccount) => {
    return notifications.map((notification, index) => ({
      label: (
        <NotificationItem
          key={`notification-${index}`}
          notification={notification}
          handleNotificationClick={handleNotificationClick}
          user={selectedUser}
        />
      ),
      onClick: () => handleNotificationClick(notification),
      onHover: () => updateNotificationStatus(notification),
    }));
  };

  const countUnreadMessages = (notifications: NotificationType[]): string => {
    const countUnreadNotifications: number = notifications.filter(notification => notification.isUnread).length;
    if (countUnreadNotifications > 9) {
      return `<span style="font-size:small;">9<sup>+</sup></span>`;
    }
    return `${countUnreadNotifications}`;
  };

  const markAllNotificationsAsRead = async () => {
    if (notifications.filter(notification => notification.isUnread).length > 0) {
      const updatedNotifications = notifications.map(notification => ({
        ...notification,
        isUnread: false,
      }));
      let newUser = await updateNotifications(selectedUser._id, updatedNotifications);
      dispatch(setAccount(newUser));
      let updatedUser = newUser.users.find(userItem => userItem._id === selectedUser._id);
      dispatch(setUser(updatedUser));
      setNotifications(updatedNotifications);
    }
  };

  return (
    <NotificationsContainer>
      <DropDownMenu
        callBackCloseDropDown={() => setShowNotification(false)}
        callBackOpenDropDown={() => setShowNotification(true)}
        title={
          <HeaderNotificationsContainer>
            <AllNotificationContainer>
              <h3>{t('notifications.all_notifications')}</h3>
            </AllNotificationContainer>

            <AllReadContainer onClick={markAllNotificationsAsRead}>{t('notifications.mark_as_read')}</AllReadContainer>
          </HeaderNotificationsContainer>
        }
        header={
          <HeaderContainer>
            <NotificationIconButton type='button' showNotification={showNotification}>
              <MdNotificationsNone />
              {notifications.filter(notification => notification.isUnread).length > 0 && (
                <NotificationBadge>
                  <p dangerouslySetInnerHTML={{ __html: countUnreadMessages(notifications) }}></p>
                </NotificationBadge>
              )}
            </NotificationIconButton>
          </HeaderContainer>
        }
        options={generateNotificationList(notifications, handleNotificationClick, activeAccount)}
        emptyOptionsComponent={
          <EmptyNotificationContainer>
            <h3>{t('notifications.no_notifications')}</h3>
          </EmptyNotificationContainer>
        }
      />
    </NotificationsContainer>
  );
};
