import styled from 'styled-components';
import { FileExplorer } from '../file-explorer/FileExplorer';
import { useTranslation } from 'react-i18next';

const MyDocuments = () => {
  const { t } = useTranslation();

  return (
    <MyDocumentsContainer>
      <Title>{t('myDocuments.title')}</Title>
      <FileExplorer />
    </MyDocumentsContainer>
  );
};

export default MyDocuments;

const MyDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 80px 80px 50px 80px;
  height: calc(100% - 130px);
  width: calc(100% - 160px);
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
  display: flex;
`;
