import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { Infos } from '../infos/Infos';

export const SendButton = styled(IconButton)`
  && {
    color: #326c82;
  }
`;

export const StyledInfos = styled(Infos)`
  width: 500px;
`;

export const PersonalInformationsFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const PersonalInformationsContainer = styled.div`
  padding-top: 35px;
  width: calc(100% - 50px);
`;

export const InitialsContainer = styled.div`
  margin: 40px;
`;

export const ButtonSubmitContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const Subtitle = styled.div`
  font-size: 1.3em;
  padding-top: 35px;
  padding-bottom: 20px;
  color: #f49d1d;
  font-family: 'Baloo 2';
  font-weight: 700;
`;

export const FooterContainer = styled.div`
  padding: 1em;
  display: flex;
  justify-content: start;
  gap: 20px;
  position: fixed;
  bottom: 0;
`;
