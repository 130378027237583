import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import DetailsSide from './components/DetailsSide';
import CalendarSide from './components/CalendarSide';
import { Modal } from '../modal/Modal';
import Div from '../div/Div';

import { selectUser } from 'src/reducers/account.reducer';

import { availableCalendarSlots, bookCalendarSlots } from 'src/utils/api';
import { addEventTracking } from '../../utils/utils';

dayjs.extend(timezone);
dayjs.extend(utc);

interface CalendarProps {
  isCalendarOpen: boolean;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fullPage?: boolean;
  activeAccount: any;
  callbackFn?: () => void;
}

const Calendar = ({ isCalendarOpen, setIsCalendarOpen, activeAccount, fullPage, callbackFn }: CalendarProps) => {
  const user = useSelector(selectUser);
  const companyName = activeAccount?.companyName;
  const legalOfficer = activeAccount?.legalOfficer;

  const [index, setIndex] = useState<number>(0);

  const [availableSlots, setAvailableSlots] = useState();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState();

  const [calendarActiveStartDate, setCalendarActiveStartDate] = useState(
    dayjs().startOf('month').locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
  );

  const [attendees, setAttendees] = useState([]);
  const [description, setDescription] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setLoading(true);
    const isSameMonth = dayjs(calendarActiveStartDate).isSame(dayjs(selectedDate), 'month');

    const formattedDate = isSameMonth
      ? dayjs(selectedDate).tz(userTimezone).format('YYYY-MM-DD')
      : dayjs(calendarActiveStartDate).tz(userTimezone).format('YYYY-MM-DD');

    const slotsData = {
      date: formattedDate,
      timezone: userTimezone,
      legalOfficerEmail: legalOfficer?.email,
    };

    availableCalendarSlots(slotsData).then(({ availableSlots }) => {
      setLoading(false);
      setAvailableSlots(availableSlots);
    });
    // .catch(err => {
    //   console.log('🚀 ~ err:', err);
    // });
  }, [calendarActiveStartDate]);

  const handleBookCalendarSlot = async () => {
    setLoading(true);
    const slotData = {
      accountId: activeAccount?._id,
      legalOfficerEmail: legalOfficer?.email,
      title: `Dipeeo : ${companyName} - ${legalOfficer?.name}`,
      description,
      startTime: dayjs(selectedSlot.start).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss'),
      endTime: dayjs(selectedSlot.end).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss'),
      timeZone: userTimezone,
      attendees: [...attendees, { email: user.email, responseStatus: 'accepted' }],
      phoneNumber,
      userId: user?._id,
      firstBook:
        activeAccount.users.some(user => user.activity.sentMails.some(email => email.templateId === 'd-7e863d6f4f2641b9875b6b5e5308f0f6')) &&
        !activeAccount.activity.appointments.didScheduleFirstAppointment,
      stepPointBook:
        activeAccount.users.some(user => user.activity.sentMails.some(email => email.templateId === 'd-cf2a5491a81242a98fd6a103dfedb99e')) &&
        !activeAccount.activity.appointments.didScheduleStepPointAppointment,
    };
    await bookCalendarSlots(slotData)
      .then(res => {
        if (res) {
          setLoading(false);
          setSuccess(true);
          setIndex(2);
          addEventTracking('Appointments', 'Booking', slotData.firstBook ? 'Onboarding' : slotData.stepPointBook ? 'Step Point' : 'On demand');
          callbackFn && callbackFn();
        }
      })
      .catch(err => {
        if (err) {
          setLoading(false);
          setError(true);
          setIndex(2);
        }
      });
  };

  // Construct Different sides props
  const detailSideProps = { legalOfficer, companyName, index, setIndex, selectedSlot, setSelectedSlot, selectedDate, setAttendees };
  const calendarSideProps = {
    user,
    setIsCalendarOpen,
    fullPage,
    index,
    setIndex,
    selectedSlot,
    setSelectedSlot,
    selectedDate,
    setSelectedDate,
    attendees,
    setAttendees,
    bookSlot: handleBookCalendarSlot,
    success,
    error,
    setDescription,
    phoneNumber,
    setPhoneNumber,
    loading,
    availableSlots,
    calendarActiveStartDate,
    setCalendarActiveStartDate,
  };

  if (!isCalendarOpen) return null;

  if (fullPage) {
    return (
      <Div flex height={'100%'}>
        <DetailsSide {...detailSideProps} />
        <Separator />
        <CalendarSide {...calendarSideProps} />
      </Div>
    );
  }

  return (
    <Modal onClose={() => setIsCalendarOpen(prevState => !prevState)} show={isCalendarOpen} size={{ height: '55%', width: '55%' }}>
      <Div flex height={'100%'}>
        <DetailsSide {...detailSideProps} />
        <Separator />
        <CalendarSide {...calendarSideProps} />
      </Div>
    </Modal>
  );
};

export default Calendar;

//
// Components
//

const Separator = styled.div`
  // flex-grow: 1;
  border-right: 1px solid #d1d5db;
  margin: 0 2rem;
`;
