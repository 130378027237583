import styled from 'styled-components';

const AdminUserListContainer = styled.div``;

const UserListBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 5fr 2fr 2fr 2fr 1fr;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;

  span {
    font-size: 1.125em;
    font-weight: bold;
  }
`;

const UserListContainer = styled.div`
  height: calc(100% - 40px);
`;

const MessageContainer = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 1.5em;
  padding: 20px;

  div {
    text-align: center;

    span {
      font-weight: 600;
      color: #187ead;
    }
  }
`;

const Separator = styled.div`
  grid-column: span 8;
  font-size: 1.2em;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

const ImpersonateUser = styled.div`
  svg {
    cursor: pointer;
    font-size: 2em;
    color: #187ead;
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export { AdminUserListContainer, UserListBody, UserListContainer, MessageContainer, Separator, ImpersonateUser };
