import styled from 'styled-components';
import dayjs from 'dayjs';

import { IoMdArrowBack } from 'react-icons/io';
import { IoEarthOutline, IoTimeOutline, IoVideocamOutline } from 'react-icons/io5';
import { BsCalendar4Event } from 'react-icons/bs';

import Div from 'src/components/div/Div';
import { useTranslation } from 'react-i18next';

const DetailsSide = ({ legalOfficer, companyName, index, setIndex, selectedSlot, setSelectedSlot, selectedDate, setAttendees }) => {
  const { t } = useTranslation();
  const formattedSlotStart = dayjs(selectedSlot?.start).format('HH:mm');
  const formattedSlotEnd = dayjs(selectedSlot?.end).format('HH:mm');
  const formattedDate = dayjs(selectedDate).format('dddd, D MMMM YYYY');

  return (
    <DetailsBox>
      {/* back arrow icon */}
      <BackArrowIcon
        visible={index === 1}
        onClick={() => {
          setIndex(0);
          setSelectedSlot(null);
          setAttendees([]);
        }}>
        <IoMdArrowBack size={30} cursor={'pointer'} />
      </BackArrowIcon>

      {/* header */}
      <Header>
        {/* <LegalOfficerName>Rendez-vous avec votre DPO</LegalOfficerName> */}
        <MeetingTitle>{`${t('calendar.details.meetingWith')} ${legalOfficer?.name} - ${companyName}`}</MeetingTitle>

        <EventDetail mt={'2.5rem'} mb={'1rem'}>
          <IoTimeOutline size={26} />
          <span>{t('calendar.details.thirtyMinuteMeeting')}</span>
        </EventDetail>

        {/* Conference details */}
        <EventDetail mb={'1rem'}>
          <IoVideocamOutline size={28} />
          <span>{t('calendar.details.conferenceDetails')}</span>
        </EventDetail>
        {selectedSlot && [1, 2].includes(index) && (
          <>
            <EventDetail mb={'1rem'}>
              <BsCalendar4Event size={22} />
              <span>
                {formattedSlotStart} - {formattedSlotEnd}, {formattedDate}
              </span>
            </EventDetail>
            <EventDetail>
              <IoEarthOutline size={27} />
              <span>{t('calendar.details.centralEuropeanTime')}</span>
            </EventDetail>
          </>
        )}
      </Header>
    </DetailsBox>
  );
};

export default DetailsSide;

const DetailsBox = styled.div`
  width: 40%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BackArrowIcon = styled.div<{ visible?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  &:hover {
    background-color: #bfdbfe;
    color: #3b82f6;
  }
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const Header = styled.div`
  padding-top: 2rem;
`;

// const LegalOfficerName = styled.h4`
//   font-weight: bold;
//   color: #737373;
//   letter-spacing: 0;
//   font-family: 'Nunito';
//   font-size: 1.5rem;
// `;

const MeetingTitle = styled.h1`
  color: black;
  font-weight: bold;
  font-size: 28px;
  @media (max-width: 640px) {
    font-size: 26px;
  }
`;

const EventDetail = styled(Div)`
  display: grid;
  grid-template-columns: 11% 88%;
  column-gap: 15px;
  font-weight: bold;
  font-family: 'Nunito';
  font-size: 1.2rem;
  align-items: center;
`;
