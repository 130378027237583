import { MdMoreVert } from 'react-icons/md';
import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { IconType } from 'react-icons';
import { Placement } from '@popperjs/core';
import { Z_INDEX } from '../../utils/constants';

export interface MoreOptionItem {
  label: string;
  onClick: any;
  color?: string;
  icon?: IconType;
}

export interface MoreOptionsProps {
  className?: string;
  options: Array<MoreOptionItem>;
  icon?: IconType;
  children?: React.ReactNode;
  placement?: Placement;
  rotate?: boolean;
  removeBorder?: boolean;
  svgColor?: string;
  offset?: [number, number];
  hover?: boolean;
}

export const MoreOptions = ({ className, options, icon, children, placement, rotate, removeBorder, svgColor, offset, hover }: MoreOptionsProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [show, setShow] = useState(false);
  const Icon = icon ?? MdMoreVert;

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.parentElement.dataset.pop) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [referenceElement]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? 'right',
    modifiers: offset
      ? [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ]
      : [],
  });

  const handleOptionClick = (e, onClick) => {
    e.stopPropagation();
    onClick && onClick();
    setShow(false);
  };

  const handleMoreOptionClick = e => {
    e.stopPropagation();
    setShow(prevState => !prevState);
  };

  const getProps = () => ({
    ...(hover && { onMouseEnter: handleMoreOptionClick }),
    ...(!children && {
      rotate: !!rotate,
      removeBorder: removeBorder,
      svgColor: svgColor,
    }),
    show: show,
    onClick: handleMoreOptionClick,
  });

  // Clone children and pass additional props
  const modifiedChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...{ show, setShow } });
    }
    return child;
  });

  return (
    <>
      {options.length > 0 && (
        <>
          {children ? (
            <ChildContainer {...getProps()} ref={setReferenceElement}>
              {modifiedChildren}
            </ChildContainer>
          ) : (
            <MoreContainer {...getProps()} ref={setReferenceElement}>
              <Icon />
            </MoreContainer>
          )}
          {show && (
            <PopoverMenu className={className} data-pop ref={setPopperElement} {...attributes.popper} style={styles.popper}>
              {options?.map((option, index) => {
                let Icon = option.icon;
                return (
                  <MenuItem color={option.color} key={index} onClick={e => handleOptionClick(e, option.onClick)}>
                    {option.label}
                    {Icon && <Icon />}
                  </MenuItem>
                );
              })}
            </PopoverMenu>
          )}
        </>
      )}
    </>
  );
};

const ChildContainer = styled.div<{ show: boolean }>`
  width: fit-content;
  position: relative;
`;

const MoreContainer = styled.div<{ show?: boolean; rotate?: boolean; removeBorder?: boolean; svgColor?: string }>`
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 5px;
  border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0)')};
  transition: border 0.2s ease-in-out;

  > svg {
    height: 24px;
    width: 24px;
    color: ${({ svgColor }) => svgColor ?? 'rgba(0, 0, 0, 0.4)'};
    ${({ removeBorder }) =>
      !removeBorder &&
      css`
        transition: all 0.2s ease-in-out;
      `};
    transform: rotate(0deg);
  }

  &:hover {
    cursor: pointer;
    border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0.5)')};

    > svg {
      color: rgba(0, 0, 0, 0.7);
      ${({ rotate }) => rotate === true && 'transform: rotate(100deg);'}
    }

    ${({ show }) =>
      show &&
      `
        cursor: pointer;
        border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0.5)')};

        > svg {
          color: rgba(0, 0, 0, 0.7);
        }
      `}
`;

const PopoverMenu = styled.div<{ show?: boolean }>`
  width: 200px;
  padding: 20px;
  background-color: white;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
  transition: opacity 0.2s ease-in-out;
  pointer-events: bounding-box;
  animation: fadeIn 0.2s ease-in-out;
  z-index: ${Z_INDEX.popover};
`;

const MenuItem = styled.div<{ color: string }>`
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  font-family: 'Roboto', serif;
  color: ${({ color }) => color ?? 'black'};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  column-gap: 10px;

  > svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;
