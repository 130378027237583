import styled from 'styled-components';
import { COLORS } from '@utils/constants';

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: ${COLORS.Grey};
  line-height: 25px;

  b {
    color: ${COLORS.Red};
  }
`;
