/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { paths } from './routes.constants';
import { ICustomRoute } from './routes.types';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrors, setErrors } from 'src/reducers/app.reducer';
import { selectAccount, setAccount, setUser } from '../reducers/account.reducer';
import { getSessionUserId } from '../utils/utils';
import { getAccountByUserId } from '../utils/api';

const CustomRoute = ({ isAuthenticated, redirectPath, isPrivate, component: Component, ...rest }: ICustomRoute) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const account = useSelector(selectAccount);

  const errors = useSelector(selectErrors);

  useEffect(() => {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (isAuthenticated && redirectUrl) {
      localStorage.removeItem('redirectUrl');
      history.replace(redirectUrl);
    }
  }, []);

  useEffect(() => {
    (async () => {
      let params = new URL(window.location.href).searchParams;
      let id = getSessionUserId() ?? params.get('uid');
      const userId = params.get('uid') ?? JSON.parse(id);
      if (userId && !account) {
        const accountData = await getAccountByUserId(userId);
        const userData = accountData?.users.find(user => user._id === userId);
        await dispatch(setUser(userData));
        await dispatch(setAccount(accountData));
      }
    })();
  }, []);

  // TODO! : to be optimized
  useEffect(() => {
    if (!!errors) {
      dispatch(setErrors(null));
    }
  }, []);

  return isPrivate ? (
    <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to={paths.login} />)} />
  ) : (
    <Route
      {...rest}
      render={props => (!isAuthenticated ? <Component {...props} /> : redirectPath ? <Redirect to={redirectPath} /> : <Component {...props} />)}
    />
  );
};

export default CustomRoute;
