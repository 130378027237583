import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { UserDetails } from '../dashboard-user-details/UserDetails';
import { setCurrentEntity, setLoading } from '../../reducers/app.reducer';
import { fetchUserActionsContentfulData, selectUserActions } from 'src/reducers/contentful.reducer';

const AdminPanel = () => {
  //redux
  const dispatch = useDispatch<any>();

  const userActions = useSelector(selectUserActions);

  useEffect(() => {
    dispatch(setCurrentEntity(null));
  }, []);

  useEffect(() => {
    (async () => {
      if (!userActions.length) {
        dispatch(setLoading(true));
        await dispatch(fetchUserActionsContentfulData({}));
        dispatch(setLoading(false));
      }
    })();
  }, []);

  return (
    <AdminPanelContainer>
      <UserDetails />
    </AdminPanelContainer>
  );
};

export default AdminPanel;

const AdminPanelContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
`;
