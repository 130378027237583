import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../utils/constants';
import { useSelector } from 'react-redux';
import {
  addEventTracking,
  getDetailedEntityById,
  getFileNameFromKey,
  getFilePathFromKey,
  getSelectedAccountEntityById,
  handleUploadFilesToS3,
  removeQuestionByQuestionName,
} from '../../utils/utils';
import { useDropzone } from 'react-dropzone';
import { InputComponent } from '../form/InputComponent';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Loader from '../loader/loader';
import {
  deleteDirectoryFromS3ByKey,
  deleteDocumentFromS3ByKey,
  downloadDirectoryFromS3ByKey,
  getDocumentsListFromS3ByAccountId,
  notifyClientForDocumentsReady,
} from '../../utils/api';
import { toast } from 'react-toastify';
import { Button } from '../Button';
import { selectActiveAccount, selectSelectedAccount } from '../../reducers/account.reducer';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { HiOutlineCloudDownload } from 'react-icons/hi';
import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFolder } from 'react-icons/ai';
import { MdOutlineBusinessCenter } from 'react-icons/md';
import { ImFilesEmpty } from 'react-icons/im';
import {
  FileSlider,
  ActionContainer,
  LoaderContainer,
  FileExplorerContainer,
  Content,
  Empty,
  DragOverlay,
  Link,
  ExplorerItem,
  FileExplorerWindow,
  FileContainer,
  BreadcrumbContainer,
  BreadcrumbItem,
} from './FileExplorer_.style';
import { useTranslation } from 'react-i18next';

export interface FileExplorerProps {
  admin?: boolean;
  embedded?: boolean;
  customClick?: any;
  selectedEntity?: any;
  selectedFileKey?: any;
  showEntitySelection?: boolean
  disableBreadcrumb?: boolean
}

export const FileExplorer = ({ admin = false, embedded, customClick, selectedEntity, selectedFileKey, showEntitySelection = true, disableBreadcrumb = false }: FileExplorerProps) => {
  const { t } = useTranslation();
  const [currentPath, setCurrentPath] = useState('');
  const [tree, setTree] = useState(undefined);
  const [level, setLevel] = useState(0);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [storedFiles, setStoredFiles] = useState([]);
  const [hoveredAction, setHoveredAction] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const selectedAccount = useSelector(selectSelectedAccount);
  const activeAccount = useSelector(selectActiveAccount);
  const targetAccount = admin ? selectedAccount : activeAccount;

  const filesKeys = selectedFileKey?.split(';');

  useEffect(() => {
    if (!storedFiles.length) {
      setCurrentPath('');
      setLevel(0);
    }
    getFilesTree();
  }, [storedFiles]);

  useEffect(() => {
    if (selectedEntity) {
      setSelectedEntityId(selectedEntity._id);
    }
    if (selectedFileKey) {
      let key = getFilePathFromKey(filesKeys[0]);
      let level = key === '' ? 0 : key.split('/').length;
      setLevel(level);
      setCurrentPath(key);
    }
  }, []);

  const getFilesTree = () => {
    let tree = { files: [] };
    storedFiles.forEach(file => {
      let explodedPath = file.Key.split('/');
      //clean path
      explodedPath = explodedPath.slice(3, explodedPath.length - 1);
      if (!explodedPath.length) {
        explodedPath = ['root'];
      }
      explodedPath.forEach((pathPart, index) => {
        let pointer = tree;
        if (pathPart === 'root') {
          pointer.files.push(file);
          return;
        }
        for (let i = 0; i < index; i++) {
          pointer = pointer[explodedPath[i]];
        }

        if (!pointer[pathPart]) {
          if (index === explodedPath.length - 1) {
            pointer[pathPart] = { files: [file] };
          } else {
            pointer[pathPart] = { files: [] };
          }
        } else {
          if (index === explodedPath.length - 1) {
            pointer[pathPart].files.push(file);
          }
        }
      });
    });

    setTree(tree);
  };

  const getTreeAtPosition = () => {
    let treeAtPosition = tree;
    let parts = currentPath.split('/');
    for (let i = 0; i < level; i++) {
      treeAtPosition = treeAtPosition?.[parts[i]];
    }
    return treeAtPosition;
  };

  const handleNavigate = key => {
    if (hoveredAction) return;
    setCurrentPath((currentPath ? currentPath + '/' : '') + key);
    setLevel(level + 1);
  };

  const deleteObject = async (key: string, deleteFromResponse: boolean) => {
    if (!window.confirm(t('FileExplorer.confirmDeleteFile'))) return;
    setFileLoading(true);
    if (deleteFromResponse) {
      let versionId = key.split('/')[key.split('/').length - 3] !== 'user-uploads' ? key.split('/')[key.split('/').length - 3] : null;
      removeQuestionByQuestionName(selectedEntityId, key.split('/')[key.split('/').length - 2], versionId);
    }
    await deleteDocumentFromS3ByKey(key);

    await getDocumentsFromS3();
    setFileLoading(false);
  };

  const deleteDirectory = async (key: string) => {
    if (!window.confirm(t('FileExplorer.confirmDeleteFolder'))) return;
    setFileLoading(true);
    await deleteDirectoryFromS3ByKey(key);
    await getDocumentsFromS3();
    setFileLoading(false);
  };

  const downloadDirectory = async (key: string, e?: any) => {
    setFileLoading(true);
    e.preventDefault();
    addEventTracking('Documents', 'Download');
    let response = await downloadDirectoryFromS3ByKey(key);

    let buffers = response.buffers;
    if (!buffers.length) return;
    let zip = new JSZip();
    for (let buffer of buffers) {
      let splitPath = buffer.path.split('.');
      let dirTree = buffer.path.split('/');
      let targetDirIndex = dirTree.indexOf(buffer.targetDirectory);
      let cleanPath = dirTree.slice(targetDirIndex, dirTree.length).join('/');
      let extension = splitPath[splitPath.length - 1];

      const data = new Uint8Array(buffer.data.Body.data);
      let blob = new Blob([data], {
        type:
          extension === 'docx'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      zip.file(cleanPath, blob);
    }
    let content = await zip.generateAsync({ type: 'blob' });
    setFileLoading(false);
    saveAs(content, `${targetAccount.companyName + '-' + response.targetDirectory}.zip`);
  };

  const getItemIcon = type => {
    switch (type) {
      case 'docx':
        return <AiOutlineFileWord />;
      case 'xlsx':
        return <AiOutlineFileExcel />;
      case 'pdf':
        return <AiOutlineFilePdf />;
      case 'png':
        return <AiOutlineFileImage />;
      case 'jpeg':
        return <AiOutlineFileImage />;
    }
  };

  const selectedFiles = useMemo(() => {
    let files = [];
    if (filesKeys?.length) {
      filesKeys.forEach(key => {
        const fileName = getFileNameFromKey(key);
        files.push(fileName);
      });
    }
    return files;
  }, [filesKeys]);

  const getItems = () => {
    let treeAtPosition = getTreeAtPosition();
    if (!treeAtPosition) return null;
    let items = Object.keys(treeAtPosition)
      .reverse()
      .map((key: string, index: number) => {
        let value = getTreeAtPosition()[key];
        if (key === 'files')
          return value.map((file, index) => {
            return (
              <ExplorerItem
                selected={selectedFiles.includes(file.name?.replaceAll('_', ' '))}
                onClick={() => customClick && customClick(file.Key)}
                type={file.fileType}
                key={'file_' + file.Key + index}>
                {getItemIcon(file.fileType)}
                <span>{file.name?.replaceAll('_', ' ').slice(0, file.name.lastIndexOf('.'))}</span>
                {!embedded && (
                  <div>
                    {admin && (
                      <RiDeleteBin7Line
                        onMouseOver={() => setHoveredAction(true)}
                        onMouseLeave={() => setHoveredAction(false)}
                        style={{ color: COLORS.Red }}
                        onClick={() => deleteObject(file.Key, currentPath.split('/')[0] === 'user-uploads')}
                      />
                    )}
                    <Link href={file.link}>
                      <HiOutlineCloudDownload onMouseEnter={() => setHoveredAction(true)} onMouseLeave={() => setHoveredAction(false)} />
                    </Link>
                  </div>
                )}
              </ExplorerItem>
            );
          });

        return (
          <ExplorerItem key={'folder' + key + index} onClick={() => handleNavigate(key)}>
            <AiOutlineFolder style={{ color: COLORS.darkBlue }} />
            <span>{getFolderLabel(key)}</span>
            {!embedded && (
              <div>
                {admin && currentPath.split('/')[0] !== 'user-uploads' && key !== 'user-uploads' && (
                  <RiDeleteBin7Line
                    onMouseOver={() => setHoveredAction(true)}
                    onMouseLeave={() => setHoveredAction(false)}
                    style={{ color: COLORS.Red }}
                    onClick={() => deleteDirectory(getFolderPath(key))}
                  />
                )}
                <Link>
                  <HiOutlineCloudDownload
                    onClick={e => downloadDirectory(getFolderPath(key), e)}
                    onMouseOver={() => setHoveredAction(true)}
                    onMouseLeave={() => setHoveredAction(false)}
                  />
                </Link>
              </div>
            )}
          </ExplorerItem>
        );
      });
    return items;
  };

  const getSurveyVersionName = versionId => {
    let surveyVersionName = '';
    (admin ? getSelectedAccountEntityById(selectedEntityId) : getDetailedEntityById(selectedEntityId))?.responses.forEach(survey => {
      survey.versions.forEach(surveyVersion => {
        if (surveyVersion._id === versionId) {
          surveyVersionName = surveyVersion.versionName;
        }
      });
    });
    return surveyVersionName;
  };

  const getFolderLabel = key => {
    if (key === 'user-uploads') {
      return admin ? t('FileExplorer.clientUploadedFiles') : t('FileExplorer.myUploadedFiles');
    }
    if (key.includes('_')) {
      return key.replaceAll('_', ' ');
    }
    if (!key) {
      return;
    }
    let versionName = getSurveyVersionName(key);
    if (versionName === '') {
      return key.replaceAll('_', ' ');
    }

    return versionName;
  };

  const getBreadcrumb = () => {
    let parts = currentPath.split('/');
    let breadcrumb = parts.map((part, index) => (
      <React.Fragment key={'breadcrumb_' + part + index}>
        <BreadcrumbItem current={index === parts.length - 1} disableBreadcrumb={disableBreadcrumb} onClick={() => !disableBreadcrumb && handleBreadCrumbClick(index)}>
          {getFolderLabel(part)}
        </BreadcrumbItem>
        <span>{index < parts.length - 1 && ' / '}</span>
      </React.Fragment>
    ));
    breadcrumb.unshift(
      <React.Fragment key={'breadCrumb_entity_0'}>
        <BreadcrumbItem current={currentPath === ''} disableBreadcrumb={disableBreadcrumb} onClick={() => !disableBreadcrumb && handleBreadCrumbClick(-1)}>
          {getEntityname()}
        </BreadcrumbItem>
        {parts.length ? <span key={'breadcrumb_splitter'}>{'/'}</span> : ''}
      </React.Fragment>
    );
    return breadcrumb;
  };

  const handleBreadCrumbClick = (index: number) => {
    if (index === -1) {
      setCurrentPath('');
      setLevel(0);
      return;
    }
    let parts = currentPath.split('/');
    let newPath = parts.slice(0, index + 1).join('/');
    setCurrentPath(newPath);
    setLevel(index + 1);
  };

  const onDrop = async files => {
    setFileLoading(true);
    await handleUploadFilesToS3(files, selectedEntityId, targetAccount._id, currentPath);
    await getDocumentsFromS3();
    setFileLoading(false);
  };

  const onDropRejected = rejectedFiles => {
    console.log('rejectedFiles', rejectedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    disabled: !admin,
    onDrop,
    onDropRejected,
    noClick: true,
  });

  const getEntityOptions = () => {
    return targetAccount?.entities.map(entity => ({
      value: entity._id,
      label: entity.name,
    }));
  };

  const getDocumentsFromS3 = async () => {
    const documents = await getDocumentsListFromS3ByAccountId(targetAccount._id, selectedEntityId);
    setStoredFiles(documents);
  };

  const handleEntityChange = (name, value) => {
    setSelectedEntityId(value);
  };

  const getFolderPath = folderName => {
    let basePath = `public/${targetAccount._id}/${selectedEntityId}/`;
    let path = currentPath === '' ? '' : `${currentPath}/`;
    return `${basePath}${path}${folderName}/`;
  };

  const handleNotifyClient = async () => {
    await notifyClientForDocumentsReady(selectedAccount);
    toast.success(t('FileExplorer.notifyClient'));
  };

  useEffect(() => {
    if (!selectedEntityId) return;
    if (!selectedFileKey) {
      setCurrentPath('');
      setLevel(0);
      setTree(undefined);
    }
    (async () => {
      if (targetAccount._id) {
        setFileLoading(true);
        await getDocumentsFromS3();
        setFileLoading(false);
      }
    })();
  }, [selectedEntityId]);

  const getEntityname = () => (admin ? getSelectedAccountEntityById(selectedEntityId) : getDetailedEntityById(selectedEntityId))?.name;

  return (
    <FileExplorerContainer>
      {admin && !embedded && (
        <ActionContainer>
          <Button
            disabled={!selectedEntityId || tree?.files?.length < 1 || fileLoading}
            type={'success'}
            onClick={handleNotifyClient}
            label={t('FileExplorer.notifyClientButton')}
          />
        </ActionContainer>
      )}
      {!embedded && showEntitySelection && (
        <InputComponent
          onChange={handleEntityChange}
          type='select'
          name={'selectedEntity'}
          options={getEntityOptions()}
          value={selectedEntityId}
          label={t('FileExplorer.entityChoiceLabel')}
          sharable={false}
        />
      )}
      <Content>
        {selectedEntityId ? (
          <FileExplorerWindow {...getRootProps()}>
            <input {...getInputProps()} />
            <BreadcrumbContainer>{getBreadcrumb()}</BreadcrumbContainer>
            {storedFiles.length > 0 ? (
              <FileSlider role={'slider'}>
                <FileContainer>
                  {tree && getItems()}
                  {isDragActive && <DragOverlay>{t('FileExplorer.dropFiles')}</DragOverlay>}
                </FileContainer>
              </FileSlider>
            ) : (
              <Empty>
                <ImFilesEmpty />
                {admin && (
                  <span>
                    {t('FileExplorer.noFilesUploadedClient')} <b>{getEntityname()}</b> {t('FileExplorer.uploadFilesDragDrop')}
                  </span>
                )}
                {!admin && (
                  <span>
                    {t('FileExplorer.noFilesUploaded')} <b>{getEntityname()}</b> {t('FileExplorer.notifyEmail')}
                  </span>
                )}
                {isDragActive && <DragOverlay>{t('FileExplorer.dropFiles')}</DragOverlay>}
              </Empty>
            )}
          </FileExplorerWindow>
        ) : (
          <Empty>
            <MdOutlineBusinessCenter />
            {admin && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('FileExplorer.selectEntityPromptNoAdmin'),
                }}></span>
            )}
            {!admin && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('FileExplorer.selectEntityPrompt'),
                }}></span>
            )}
            {isDragActive && <DragOverlay>{t('FileExplorer.dropFiles')}</DragOverlay>}
          </Empty>
        )}
        {fileLoading && (
          <LoaderContainer>
            <Loader loaderOnly={true} />
          </LoaderContainer>
        )}
      </Content>
    </FileExplorerContainer>
  );
};
