import { Modal } from '../modal/Modal';

import styled from 'styled-components';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

export interface ModalDeleteProductProps {
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
  deletedProduct: { survey: any; version: any };
}

export const ModalDeleteProduct = ({ show, onClose, onClick, deletedProduct }: ModalDeleteProductProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        alignment={'center'}
        size={{ width: '50%' }}
        show={show}
        title={`${t('modal.confirm_delete_prefix')} ${deletedProduct?.version?.versionName} ${t('modal.confirm_delete_suffix')}`}
        onClose={onClose}>
        <BasicModalContainer>
          <p>{t('modal.delete_confirmation_message')}</p>
          <ButtonContainer>
            <Button type={'danger'} onClick={onClick} label={t('common.delete')} />
            <Button type={'light'} onClick={onClose} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
