import { useMemo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { saveAs } from 'file-saver';

import { IoMdStats } from 'react-icons/io';
import { BsPersonFill } from 'react-icons/bs';
import { TbBrandDaysCounter, TbFileDownload, TbFolder, TbHomeStats, TbInfoSquareRounded, TbLockAccess, TbUsers } from 'react-icons/tb';
import { FaRegSave, FaTasks } from 'react-icons/fa';
import { PiHandWaving } from 'react-icons/pi';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { LuBellRing } from 'react-icons/lu';
import { AiOutlineAudit } from 'react-icons/ai';

import { selectLoading, setCurrentEntity, setLoading, setSidenavState } from '../../reducers/app.reducer';
import { selectAccount, setImpersonated, selectSelectedAccount, selectActiveAccount, updateUnavailableDays } from '../../reducers/account.reducer';
import { selectSurveys } from '../../reducers/contentful.reducer';

import { Container, DateRangePicker } from '@components-new/atoms';
import { DashboardGeneration } from '../dashboard-generation/DashboardGeneration';
import { DashboardDependencies } from '../dashboard-dependencies/DashboardDependencies';
import { Button } from '../Button';
import { FileExplorer } from '../file-explorer/FileExplorer';
import WeekDaysList from './components/WeekDaysList';
import Reminders from '../reminders/Reminders';
import { AdminUserList } from '../admin-user-list/AdminUserList';
import { AdminInfos } from '../admin-infos/AdminInfos';
import { AmdinSteps } from '../admin-steps/AdminSteps';
import { AdminAuthorizations } from '../admin-authorizations/AdminAuthorizations';

import { isUnavailabilityDaysComplete, isAnySubmittedEntity } from '@utils/utils';
import { COLORS, COUNTER_TYPES, ACCOUNT_TYPES, Z_INDEX } from '@utils/constants';
import { getAccountById, getStats } from '@utils/api';

import AdminActionPlan from '@pages/admin/ActionPlan/ActionPlan';
import Audits from '@pages/admin/Audit/Audit';

import { paths } from '@routes/routes.constants';

export enum UserDetailsType {
  AUTHORIZATIONS = 'surveys',
  USERS = 'users',
  STEPS = 'steps',
  STATS = 'counters',
  VALIDATIONS = 'validations',
  GENERATIONS = 'generations',
  INFOS = 'infos',
  UPLOADS = 'uploads',
  DEPENDENCIES = 'dépendances',
  ACTIONS = 'userActions',
  AGENDA = 'agenda',
  REMINDERS = 'Relances',
  AUDIT = 'audit',
}

export const UserDetails = () => {
  //REDUX
  const dispatch = useDispatch<any>();
  const selectedAccount = useSelector(selectSelectedAccount);
  const activeAccount = useSelector(selectActiveAccount);
  const contentfulSurveys = useSelector(selectSurveys);
  const loading = useSelector(selectLoading);
  const account = useSelector(selectAccount);

  const history = useHistory();

  //STATE
  const [currentActionRights, setCurrentActionRights] = useState(selectedAccount?.actionRights);
  const [currentTab, setCurrentTab] = useState(UserDetailsType.INFOS);
  const [currentResponses, setCurrentResponses] = useState(null);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  let previousAccount: any | undefined = usePrevious(selectedAccount);

  useEffect(() => {
    if (!selectedAccount) {
      dispatch(
        setSidenavState({
          open: true,
          type: 'admin',
          level: 1,
        })
      );
      return;
    }

    let parseActionRights =
      typeof selectedAccount.actionRights === 'string' ? JSON.parse(selectedAccount.actionRights) : selectedAccount.actionRights;
    setCurrentActionRights(_.cloneDeep(parseActionRights));
    if (previousAccount?._id !== selectedAccount._id) {
      if (account?.accountType === ACCOUNT_TYPES.AGENCY) {
        handleChangeTab(UserDetailsType.GENERATIONS);
      } else {
        handleChangeTab(UserDetailsType.INFOS);
      }
    }
    setCurrentResponses([...selectedAccount?.entities]);
  }, [selectedAccount]);

  const getAccountCreationDate = () => {
    if (!selectedAccount) {
      return;
    }
    return new Date(selectedAccount.createdAt).toLocaleDateString();
  };

  const handleChangeTab = tab => {
    setCurrentTab(tab);
  };

  const handleSaveStats = async () => {
    dispatch(setLoading(true));
    // dispatch(updateStats());
    dispatch(setLoading(false));
  };

  const updateCurrentEntityCounterById = (counterId, amount, targetEntity) => {
    const newUserResponses = _.cloneDeep(currentResponses);
    const entity = newUserResponses.find(entity => entity.name === targetEntity.name);
    const counter = entity.counters.find(counter => counter.id === counterId);

    if (counter) {
      counter.amount = parseInt(amount);
      let currentCounterIndex = entity.counters.findIndex(counter => counter.id === counterId);
      let newCounterIndex = COUNTER_TYPES.findIndex(counter => counter.id === counterId);
      if (currentCounterIndex !== newCounterIndex) {
        let changedCounter = entity.counters.splice(currentCounterIndex, 1)[0];
        entity.counters.splice(newCounterIndex, 0, changedCounter);
      }
    } else {
      let newCounter = COUNTER_TYPES.find(counter => counter.id === counterId);
      let newCounterIndex = COUNTER_TYPES.findIndex(counter => counter.id === counterId);
      newCounter.amount = parseInt(amount);
      entity.counters.splice(newCounterIndex, 0, newCounter);
    }
    setCurrentResponses(newUserResponses);
  };

  const handleImpersonate = async account => {
    const accountRefreshedData = await getAccountById(account._id);
    dispatch(setImpersonated(accountRefreshedData));
    dispatch(setCurrentEntity(null));
    history.push(paths.dashboardGroup);
  };

  // ---------------------------------------------

  const [unavailabilityDays, setUnavailabilityDays] = useState(
    activeAccount?.unavailability || [
      { day: 1, startTime: '', endTime: '', allDay: false },
      { day: 2, startTime: '', endTime: '', allDay: false },
      { day: 3, startTime: '', endTime: '', allDay: false },
      { day: 4, startTime: '', endTime: '', allDay: false },
      { day: 5, startTime: '', endTime: '', allDay: false },
    ]
  );

  const [holidayStartDate, setHolidayStartDate] = useState(activeAccount?.holiday?.startDate || '');
  const [holidayEndDate, setHolidayEndDate] = useState(activeAccount?.holiday?.endDate || '');

  const isAllSlotsValid =
    useMemo(() => isUnavailabilityDaysComplete(unavailabilityDays), [unavailabilityDays]) &&
    !_.isEqual(activeAccount?.unavailability, unavailabilityDays);

  const isHolidaySlotsChanged = useMemo(() => {
    if (!activeAccount?.holiday) {
      if (holidayEndDate && holidayStartDate) return true;
      return false;
    }
    return !_.isEqual(activeAccount?.holiday, { startDate: holidayStartDate, endDate: holidayEndDate });
  }, [activeAccount?.holiday, holidayStartDate, holidayEndDate]);

  const isSaveButtonDisabled = useMemo(() => !isHolidaySlotsChanged && !isAllSlotsValid, [isHolidaySlotsChanged, isAllSlotsValid]);

  const saveLegalOfficerUnavailability = async () => {
    let newSelectedAccount = _.cloneDeep(selectedAccount);
    newSelectedAccount.unavailability = unavailabilityDays;
    dispatch(
      updateUnavailableDays({
        accountId: newSelectedAccount._id,
        ...(isAllSlotsValid && { unavailabilityDays }),
        ...(isHolidaySlotsChanged && { holiday: { startDate: holidayStartDate, endDate: holidayEndDate } }),
      })
    );
  };

  const handleGetStats = async () => {
    dispatch(setLoading(true));
    let statsBuffer = await getStats();
    if (statsBuffer === false) {
      dispatch(setLoading(false));
      return;
    }
    let blob = new Blob([new Uint8Array(statsBuffer.data)], { type: 'application/octet-stream' });
    let currentDate = new Date().toLocaleDateString();
    dispatch(setLoading(false));
    saveAs(blob, `DipeeoStats-${currentDate}.xlsx`);
  };

  const getMissingSurveys = () => {
    let missingSurveys = [];
    let CTFsurveys = contentfulSurveys?.map(survey => survey.id);

    if (CTFsurveys) {
      currentActionRights?.surveys.forEach(survey => {
        if (!CTFsurveys.includes(survey.id)) {
          missingSurveys.push(survey);
        }
      });
    }
    return missingSurveys;
  };

  //TODO: make components for each admin feature to make this file clearer
  return (
    <>
      {selectedAccount ? (
        <UserDetailsContainer>
          <TabContainer>
            <TabSection expand={true}>
              {account?.accountType !== ACCOUNT_TYPES.AGENCY && (
                <>
                  <Tab current={currentTab === UserDetailsType.INFOS} onClick={() => handleChangeTab(UserDetailsType.INFOS)}>
                    <TbInfoSquareRounded />
                    <span>Infos</span>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.USERS} onClick={() => handleChangeTab(UserDetailsType.USERS)}>
                    <TbUsers />
                    <span>Utilisateurs</span>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.AUTHORIZATIONS} onClick={() => handleChangeTab(UserDetailsType.AUTHORIZATIONS)}>
                    <TbLockAccess />
                    <Infos>
                      <span>Autorisations</span>
                      {getMissingSurveys().length > 0 && <PiHandWaving />}
                    </Infos>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.ACTIONS} onClick={() => handleChangeTab(UserDetailsType.ACTIONS)}>
                    <FaTasks />
                    <span>Plan d'actions</span>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.AUDIT} onClick={() => handleChangeTab(UserDetailsType.AUDIT)}>
                    <AiOutlineAudit />
                    <span>Audits</span>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.STEPS} onClick={() => handleChangeTab(UserDetailsType.STEPS)}>
                    <TbBrandDaysCounter />
                    <Infos>
                      <span>Étapes</span>
                      {isAnySubmittedEntity(selectedAccount) && <PiHandWaving />}
                    </Infos>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.STATS} onClick={() => handleChangeTab(UserDetailsType.STATS)}>
                    <TbHomeStats />
                    <span>Stats</span>
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.UPLOADS} onClick={() => handleChangeTab(UserDetailsType.UPLOADS)}>
                    <TbFolder />
                    <span>Upload / Download</span>
                  </Tab>
                  {selectedAccount._id === activeAccount._id && (
                    <Tab current={currentTab === UserDetailsType.AGENDA} onClick={() => handleChangeTab(UserDetailsType.AGENDA)}>
                      <MdOutlineEditCalendar />
                      Agenda
                    </Tab>
                  )}
                </>
              )}
              <Tab current={currentTab === UserDetailsType.GENERATIONS} onClick={() => handleChangeTab(UserDetailsType.GENERATIONS)}>
                <TbFileDownload />
                <span>Générations</span>
              </Tab>
            </TabSection>
            <TabSection>
              <Tab current={currentTab === UserDetailsType.REMINDERS} onClick={() => handleChangeTab(UserDetailsType.REMINDERS)}>
                <LuBellRing />
                <span>{UserDetailsType.REMINDERS}</span>
              </Tab>
              {account?.accountType !== ACCOUNT_TYPES.AGENCY && (
                <>
                  <Tab onClick={handleGetStats}>
                    {' '}
                    <IoMdStats />
                    Statistiques
                  </Tab>
                  <Tab current={currentTab === UserDetailsType.DEPENDENCIES} onClick={() => handleChangeTab(UserDetailsType.DEPENDENCIES)}>
                    Dépendances
                  </Tab>
                </>
              )}
            </TabSection>
          </TabContainer>
          <ContentContainer>
            <FlexContainer>
              <span>{selectedAccount?.companyName}</span>
              <Since>Client depuis le {getAccountCreationDate()}</Since>
              <Button label={'Impersonate'} type={'admin'} icon={BsPersonFill} onClick={() => handleImpersonate(selectedAccount)} />
            </FlexContainer>
            {!loading && (
              <Content>
                {currentTab === UserDetailsType.INFOS && <AdminInfos />}
                {currentTab === UserDetailsType.USERS && <AdminUserList />}
                {account?.accountType !== ACCOUNT_TYPES.AGENCY && currentTab === UserDetailsType.AUTHORIZATIONS && <AdminAuthorizations />}
                {account?.accountType !== ACCOUNT_TYPES.AGENCY && currentTab === UserDetailsType.ACTIONS && <AdminActionPlan />}
                {currentTab === UserDetailsType.AUDIT && <Audits />}
                {currentTab === UserDetailsType.STEPS && <AmdinSteps />}
                {currentTab === UserDetailsType.STATS && (
                  <>
                    <UserDetailsTop>
                      <UserActionContainer>
                        <Button type={'success'} onClick={handleSaveStats} label={'Mettre à jour les compteurs'} />
                      </UserActionContainer>
                    </UserDetailsTop>
                    {/*<Content>*/}
                    <GridContainer>
                      {currentResponses?.map((entity, index) => {
                        return (
                          <List key={index}>
                            <ListTitle>Compteurs pour {entity.name}</ListTitle>
                            <Counters>
                              <label htmlFor='assistance_requests'>
                                <div>Demandes d'assistance</div>
                                <input
                                  defaultValue={entity.counters.find(counters => counters.id === 'assistance_requests')?.amount}
                                  onChange={e => updateCurrentEntityCounterById('assistance_requests', e.target.value, entity)}
                                  name={'assistance_requests'}
                                  type='text'
                                />
                              </label>
                              <label htmlFor='user_rights'>
                                <div>Droits des personnes</div>
                                <input
                                  defaultValue={entity.counters.find(counters => counters.id === 'user_rights')?.amount}
                                  onChange={e => updateCurrentEntityCounterById('user_rights', e.target.value, entity)}
                                  name={'user_rights'}
                                  type='text'
                                />
                              </label>
                              <label htmlFor='data_violation'>
                                <div>Violations de données</div>
                                <input
                                  defaultValue={entity.counters.find(counters => counters.id === 'data_violation')?.amount}
                                  onChange={e => updateCurrentEntityCounterById('data_violation', e.target.value, entity)}
                                  name={'data_violation'}
                                  type='text'
                                />
                              </label>
                            </Counters>
                          </List>
                        );
                      })}
                    </GridContainer>
                    {/*</Content>*/}
                  </>
                )}
                {currentTab === UserDetailsType.UPLOADS && <FileExplorer admin={true} />}
                {currentTab === UserDetailsType.AGENDA && (
                  <>
                    <UserDetailsTop>
                      <UserActionContainer>
                        <Button
                          icon={FaRegSave}
                          label={'Mettre à jour les infos'}
                          onClick={saveLegalOfficerUnavailability}
                          type={'success'}
                          disabled={isSaveButtonDisabled}
                        />
                      </UserActionContainer>
                    </UserDetailsTop>
                    <Content>
                      <InfosFlexContainer>
                        <Left>
                          <UserInfosSlider>
                            <InfoItem>
                              <span style={{ marginBottom: '1rem' }}>Indiquez vos indisponibilités</span>
                              <WeekDaysList unavailabilityDays={unavailabilityDays} setUnavailabilityDays={setUnavailabilityDays} />
                            </InfoItem>
                            <Container flex column>
                              <span
                                style={{
                                  fontSize: '1.125em',
                                  color: '#000',
                                  fontWeight: 'bold',
                                  marginBottom: '1rem',
                                }}>
                                Vous partez en vacances ? Indiquez-le ici !
                              </span>
                              <DateRangePicker
                                startDate={holidayStartDate}
                                endDate={holidayEndDate}
                                setStartDate={setHolidayStartDate}
                                setEndDate={setHolidayEndDate}
                                labels={{ startDate: 'De', endDate: 'à' }}
                              />
                            </Container>
                          </UserInfosSlider>
                        </Left>
                      </InfosFlexContainer>
                    </Content>
                  </>
                )}
                {currentTab === UserDetailsType.REMINDERS && <Reminders />}
                {currentTab === UserDetailsType.DEPENDENCIES && <DashboardDependencies></DashboardDependencies>}
                {currentTab === UserDetailsType.GENERATIONS && <DashboardGeneration></DashboardGeneration>}
              </Content>
            )}
          </ContentContainer>
        </UserDetailsContainer>
      ) : (
        <NoAccountMessage>Veuillez sélectionner le compte client que vous souhaitez consulter pour afficher les détails.</NoAccountMessage>
      )}
    </>
  );
};

const NoAccountMessage = styled.h1`
  align-self: center;
  width: 100%;
  text-align: center;
  color: black;
  margin: 0 20% 0 calc(20% + 360px);
`;

const Infos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  svg {
    color: ${COLORS.Squash};
    animation: swing 0.8s infinite ease-in-out;
    height: 20px;
    width: 20px;
  }
`;

const TabSection = styled.div<{ expand?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:first-child {
    border-top: 1px solid ${COLORS.MediumGrey};
  }

  &:first-child {
    > div:first-child {
      border-top: none;
    }
  }

  ${({ expand }) =>
    expand &&
    css`
      flex: 1;
    `};
`;

const UserActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
`;

const TabContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
  flex-direction: column;
  border-right: 1px solid #e9edf7;
  overflow-y: auto;
`;

const Tab = styled.div<{ current?: boolean }>`
  //height: 100%;
  padding: 20px 15px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid ${COLORS.MediumGrey};
  font-size: 1.125rem;
  column-gap: 5px;

  &:hover {
    background-color: #187ead;
    color: #fff;
  }

  ${props =>
    props.current &&
    `
         background-color: #187ead;
         color: #fff;
  `}
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Counters = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ListTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187ead;
  border-bottom: 1px solid #e9edf7;
  margin-bottom: 10px;
  width: max-content;
`;

const Since = styled.div`
  font-size: 1em;
  color: #a3aed0;
  flex: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  width: calc(100% - 40px);
  align-items: center;
  column-gap: 10px;
  padding: 15px 20px;
  border-bottom: 1px solid ${COLORS.MediumGrey};
  height: 30px;

  span {
    font-size: 1.5em;
  }
`;

const GridContainer = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 15px;
  //height: 100%;
  //width: 100%;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 10px), 1fr));
  grid-auto-flow: dense;
`;

const InfosFlexContainer = styled.div`
  display: flex;
  column-gap: 30px;
  height: 100%;
`;

const Left = styled.div`
  flex: 3;
  overflow-y: auto;
`;

const UserInfosSlider = styled.div`
  height: calc(100% - 40px);
  display: grid;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px 20px;
  grid-template-columns: 1fr 1fr;

  .spacer {
    height: 20px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  flex: 1;

  > label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    > input {
      margin: 0;
    }

    &.missing {
      color: red;
    }
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 10px;
  grid-row: span 1;

  div {
    display: flex;
    column-gap: 10px;

    select {
      flex: 1;
    }

    button {
      min-width: 0px;
    }
  }

  input,
  select {
    padding: 5px 10px;
    width: fit-content;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 0;
    width: auto;

    &:focus-visible {
      border: 1px solid #187ead;
      outline: none;
    }
  }

  > span {
    font-size: 1.125em;
    color: #000;
    font-weight: bold;
  }

  label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 1.125em;
  }
`;

const Content = styled.div`
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  column-gap: 15px;
  height: calc(100vh - 102px);
  flex-direction: column;
  overflow-y: auto;
`;

const UserDetailsContainer = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  z-index: ${Z_INDEX.above};
`;

const UserDetailsTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  width: calc(100% - 40px);
  color: black;
  column-gap: 20px;
  justify-content: space-between;
`;
