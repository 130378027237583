import { Button } from '@components/Button';
import { Modal } from '@components/modal/Modal';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasicModalContainer,
  ButtonContainer,
  NextButton,
  PreviousButton,
  StepContainer,
  StepContent,
  StepItemContainer,
} from './WizardModale_.style';
import { BUTTON_COLORS } from '@utils/constants';

const WizardModale = ({ show, step, setStep, onClose, steps, title, resetForm }) => {
  const { t } = useTranslation();

  const [isStepValid, setIsStepValid] = useState(true);

  const validateStep = () => {
    const currentStep = steps[step];
    setIsStepValid(currentStep.isValid);
  };

  const nextStep = () => {
    if (isStepValid) {
      const currentStep = steps[step];
      if (currentStep.action) {
        currentStep.action();
      }

      setStep(prevStep => Math.min(prevStep + 1, steps.length - 1));
    }
  };

  const prevStep = () => {
    setStep(prevStep => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    validateStep();
  }, [step]);

  useEffect(() => {
    validateStep();
  }, [steps]);

  return (
    <Modal show={show} onClose={onClose} title={title} size={{ height: '70%' }}>
      <BasicModalContainer>
        {steps.map((stepItem, index) => (
          <StepContainer key={index} isActive={index === step}>
            {stepItem.header}
            <StepContent>
              <StepItemContainer>{stepItem.content}</StepItemContainer>
            </StepContent>
          </StepContainer>
        ))}
        <ButtonContainer>
          {step > 0 && step !== steps.length - 1 && (
            <PreviousButton>
              <Button onClick={prevStep} type={'light'} label={t('common.previous')} />
            </PreviousButton>
          )}

          {step !== steps.length - 1 && (
            <NextButton>
              <Button lightColor={BUTTON_COLORS.default} onClick={nextStep} type={'light'} disabled={!isStepValid} label={t('common.next')} />
            </NextButton>
          )}
        </ButtonContainer>
      </BasicModalContainer>
    </Modal>
  );
};

export default WizardModale;
