import { MdDone } from 'react-icons/md';
import { PiHourglass } from 'react-icons/pi';
import { TbProgress } from 'react-icons/tb';

// ActionPlan

export const actionsStatus = {
  pending: { label: 'common.status_to_handle', icon: PiHourglass, color: '#DD0615' },
  ongoing: { label: 'common.status_ongoing', icon: TbProgress, color: '#F49D1D' },
  completed: { label: 'common.status_completed', icon: MdDone, color: '#7BDFC8' },
};

export const actionsUrgencyComplexity = {
  low: { label: 'common.low', color: '#7bdfc8', backgroundColor: '#7bdfc833' },
  medium: { label: 'common.medium', color: '#f49d1d', backgroundColor: '#f49d1d33' },
  high: { label: 'common.high', color: '#f97a82', backgroundColor: '#f97a8233' },
};

// ------------------
