import { IoIosCloseCircle } from 'react-icons/io';

import { ChipContainer, IconContainer, ChipText, DeleteIconContainer } from './Chip_.style';
import { ChipProps } from './Chip.type';

const Chip: React.FC<ChipProps> = ({
  label,
  icon: Icon,
  color = '#000000',
  variant = 'filled',
  onClick,
  backgroundColor,
  onDelete,
  deleteIcon: DeleteIcon,
}) => {

  const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onDelete?.();
  };

  return (
    <ChipContainer
      variant={variant}
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      {Icon && <IconContainer iconOnly={!label}><Icon size={22} /></IconContainer>}
      {label && <ChipText hasIcon={!!Icon}>{label}</ChipText>}
      {onDelete && (
        <DeleteIconContainer
          onClick={handleDelete}
        >
          {DeleteIcon ? <DeleteIcon size={22} /> : <IoIosCloseCircle size={22} />}
        </DeleteIconContainer>
      )}
    </ChipContainer>
  );
};

export default Chip;

