import { forwardRef } from 'react';

import Div from './Container_.style';
import { ContainerProps } from './Container.type';

const Container = forwardRef<HTMLElement, ContainerProps>(({ children, style, ...rest }, ref) => {
  return (
    <Div ref={ref} {...rest} style={style}>
      {children}
    </Div>
  );
});

export default Container;
