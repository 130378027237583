import React from 'react';
import { TitlePageContainer } from './TitlePage.styles';

interface TitlePageProps {
  title: string;
}
const TitlePage: React.FC<TitlePageProps> = ({ title }) => {
  return <TitlePageContainer>{title}</TitlePageContainer>;
};

export default TitlePage;
