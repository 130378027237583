import styled from 'styled-components';

interface DivProps {
  flex?: boolean;
  grid?: boolean;
  column?: boolean;
  columnTemplate?: string;
  alignItems?: string;
  m?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  p?: string;
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  mChild?: string;
  mrChild?: string;
  mlChild?: string;
  mtChild?: string;
  mbChild?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  gap?: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  border?: string;
  position?: string;
  maxWidth?: string;
  justifyContent?: string;
  justifyItem?: string;
  zIndex?: number;
}

const Div = styled.div<DivProps>`
  ${({ zIndex }) => zIndex && `z-index:${zIndex}`};
  display: ${({ flex, grid }) => (flex ? 'flex' : grid ? 'grid' : 'block')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  ${({ columnTemplate }) => columnTemplate && `grid-template-columns: ${columnTemplate};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}

  // Margins
  ${({ m }) => m && `margin: ${m};`}
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mr }) => mr && `margin-right: ${mr};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ ml }) => ml && `margin-left: ${ml};`}
  
  // Paddings
  ${({ p }) => p && `padding: ${p};`}
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pr }) => pr && `padding-right: ${pr};`}
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
  ${({ pl }) => pl && `padding-left: ${pl};`}
  
  & > *:not(:last-child) {
    ${({ mChild }) => mChild && `margin: ${mChild};`}
    ${({ mrChild }) => mrChild && `margin-right: ${mrChild};`}
    ${({ mlChild }) => mlChild && `margin-left: ${mlChild};`}
    ${({ mtChild }) => mtChild && `margin-top: ${mtChild};`}
    ${({ mbChild }) => mbChild && `margin-bottom: ${mbChild};`}
  }

  // Overflow properties
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ overflowX }) => overflowX && `overflow-x: ${overflowX};`}
  ${({ overflowY }) => overflowY && `overflow-y: ${overflowY};`}
  
  ${({ gap }) => gap && `gap: ${gap};`}
  
  ${({ height }) => height && `height: ${height};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ justifyItem }) => justifyItem && `justify-items: ${justifyItem};`}

  ${({ position }) => position && `position: ${position};`}
  
  ${({ border }) => border && `border: ${border};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
`;

export default Div;
