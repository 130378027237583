import { useState } from 'react';
import { FaFileInvoice, FaUser } from 'react-icons/fa';
import PersonalInformations from 'src/components/personalInformations/PersonalInformations';
import { ComponentContainer, ProfileContainer } from './Profile.styles';
import SideBar from 'src/components/sideBar/Sidebar';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/account.reducer';
import { ADMIN_ROLES } from 'src/utils/constants';
import Invoices from 'src/components/invoices/Invoices';
import { RiLock2Fill } from 'react-icons/ri';
import UserAdministration from 'src/components/user-administration/UserAdministration';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const isAdmin = ADMIN_ROLES.includes(user.role);
  const SideBarItems = [
    {
      label: t('profile.personal_info'),
      value: 'personalInformations',
      icon: <FaUser />,
      component: <PersonalInformations />,
    },
  ];

  if (isAdmin) {
    SideBarItems.push({
      label: t('profile.administration'),
      value: 'confidentiality',
      icon: <RiLock2Fill />,
      component: <UserAdministration />,
    });

    SideBarItems.push({
      label: t('profile.invoices'),
      value: 'invoices',
      icon: <FaFileInvoice />,
      component: <Invoices />,
    });
  }

  const [activeItem, setActiveItem] = useState(SideBarItems[0]);

  return (
    <ProfileContainer>
      <SideBar items={SideBarItems} activeItem={activeItem} setActiveItem={setActiveItem} sideBarTitle={t('profile.user_profile_management')} />
      <ComponentContainer>{activeItem.component}</ComponentContainer>
    </ProfileContainer>
  );
};

export default Profile;
