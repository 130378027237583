import { COLORS, Z_INDEX } from 'src/utils/constants';
import styled from 'styled-components';

export const UserComponentContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  align-items: flex-start;
  column-gap: 10px;
  z-index: ${Z_INDEX.above};
`;

export const UserName = styled.div`
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  white-space: nowrap;

  span {
    font-size: 1em;
    opacity: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;

    svg {
      cursor: pointer;
      color: ${COLORS.Red};
      opacity: 0.8;
      height: 18px;
      width: 18px;
      transition: all 0.2s ease-in-out;
      margin-left: 2px;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const UserNameContainer = styled.div`
  font-size: 19px;
  font-weight: 700;
  color: #326c82;
`;
