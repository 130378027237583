import styled from 'styled-components';

export const StepContent = styled.div`
  display: 'flex';
  width: 100%;
  overflow-y: auto;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

export const StepContainer = styled.div<{ isActive?: boolean }>`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  width: 100%;
  height: 80%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
`;

export const StepItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;
  height: 100%;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PreviousButton = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const NextButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;
