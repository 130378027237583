import React from 'react';
import { RadioTilesContainer, RadioButton, RadioTile, RadioTileLabel } from './RadioTiles_.style';
import { RadioTilesProps } from './RadioTiles.type';

/**
 * Renders a radio button as tile with label and icon.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} props.label - The label text to display for the radio button.
 * @param {React.ComponentType} [props.icon] - An optional icon component to display alongside the label.
 * @param {boolean} props.checked - A boolean value indicating whether the radio button is checked.
 * @param {function} [props.onChange] - A callback function to handle the radio button change event.
 * @param {boolean} [props.disabled=false] - A boolean value indicating whether the radio button is disabled.
 * @param {string} [props.tileSize] - The size of the radio button tile.
 * @param {string} [props.color] - The color of the radio button tile.
 * @param {function} [props.onHover] - A callback function to handle the mouse hover event.
 * @param {function} [props.onLeave] - A callback function to handle the mouse leave event.
 * @returns {JSX.Element} - The rendered RadioTiles component.
 *
 */

const RadioTiles = ({
  className,
  label,
  icon: Icon,
  checked = false,
  onChange,
  disabled = false,
  onHover,
  onLeave,
  ...props
}: RadioTilesProps): React.JSX.Element => {
  return (
    <RadioTilesContainer
      className={className}
      checked={checked}
      disabled={disabled}
      onMouseLeave={() => onLeave?.()}
      onMouseEnter={() => onHover?.()}
      data-testid='radioTiles-container'>
      <RadioButton type='radio' checked={checked} onChange={onChange} disabled={disabled} data-testid='radioTiles-input' {...props} />
      <RadioTile checked={checked} disabled={disabled}>
        {Icon && <Icon data-testid='radioTiles-icon' />}
        <RadioTileLabel disabled={disabled} data-testid='radioTiles-label'>
          {label}
        </RadioTileLabel>
      </RadioTile>
    </RadioTilesContainer>
  );
};

export default RadioTiles;
