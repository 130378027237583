import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import makeAnimated from 'react-select/animated';

import { InputProps } from "./Input.type"
import { FieldContainer } from './Input_.style';

// const animatedComponents = makeAnimated();

const Input: React.FC<InputProps> = (props) => {
  const { type, isCreatable, inputRef } = props

  // Render appropriate input based on type
  const renderInput = () => {
    switch (type) {
      case 'select':
        if (isCreatable) return <CreatableSelect ref={inputRef} {...props} />
        return <Select
          ref={inputRef}
          menuPosition='fixed'
          {...props}
        // onFocus={() => onHover?.()}
        // onBlur={() => onLeave?.()}
        // components={animatedComponents}
        />
      case 'text' || 'number':
      case 'password':
      default:
        return null;
    }
  };

  return (
    <FieldContainer locked={false}
      filled={false}
      subQuestion={false}
      disabled={false}
      last={false}
      span={1}
      display
    >
      <div className='input'>{renderInput()}</div>
    </FieldContainer>
  )
}

export default Input