import styled, { keyframes } from 'styled-components';
import { Z_INDEX } from '../../utils/constants';

export interface LoaderProps {
  loaderOnly?: boolean;
}

export default function Loader({ loaderOnly = false }: LoaderProps) {
  return (
    <>
      {!loaderOnly && (
        <LoaderContainer>
          <LoaderElt />
        </LoaderContainer>
      )}
      {loaderOnly && <LoaderElt />}
    </>
  );
}

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 43, 43, 0.8);
  z-index: ${Z_INDEX.loader};
`;

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
`;

const ball1 = keyframes`
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
`;

const ball2 = keyframes`
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }`;

const LoaderElt = styled.div`
  animation: ${rotate} 1s infinite;
  height: 50px;
  width: 50px;

  &:before,
  &:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }

  &:before {
    animation: ${ball1} 1s infinite;
    background-color: #187ead;
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }

  &:after {
    animation: ${ball2} 1s infinite;
    background-color: #00a096;
    box-shadow: 30px 0 0 #97bf0d;
  }
`;
