import styled, { css } from 'styled-components';
import { COLORS } from '@utils/constants';

export const Action = styled.div<{ enabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ enabled }) => (enabled ? 0.8 : 0.4)};
  transition: opacity 0.2s;
  user-select: none;

  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};

  ${({ enabled }) =>
    enabled &&
    css`
      &:hover {
        opacity: 1;
      }
    `}
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

export const ConformityErrorsSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  //padding: 20px;
  min-height: 0;
  row-gap: 20px;
`;

export const DisableLink = styled.div`
  color: ${COLORS.softRed};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`;

export const EmptyMessages = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-direction: column;
  row-gap: 10px;

  span {
    text-align: center;
  }

  svg {
    height: 30px;
    width: 30px;
    color: ${COLORS.MediumGrey};
    animation: swing 0.8s ease;
  }
`;

export const ErrorItem = styled.div`
  display: flex;
  border: 1px solid #dfe6e9;
  padding: 5px 20px;
  //margin: 0 10px;

  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  line-height: 20px;
`;

export const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  row-gap: 10px;
`;

export const ErrorTitle = styled.div`
  font-size: 1.2em;
  text-align: left;
`;

export const ErrorSubTitle = styled.div`
  margin-top: 10px;
  font-size: 1.2em;
  text-align: left;
  font-weight: 600;
`;

export const Label = styled.div``;

export const ToggleLink = styled.div`
  color: #1179a0;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  column-gap: 5px;
  margin-top: 10px;
  > div {
    text-decoration: underline;
  }
  svg {
    margin-top: 3px;
  }
`;

export const Slider = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
`;

export const SurveyName = styled.div``;
