import styled from 'styled-components';
import dipeeoLogoWhite from '../../assets/dipeeo-blanc.svg';
import dipeeoLogoBlue from '../../assets/logo-bleu.png';
import dipeeoBadgeLogo from '../../assets/logo_small.svg';

interface LogoProps {
  subtitle?: string;
  className?: string;
  whiteBg: boolean;
  large?: boolean;
  badge?: boolean;
  onClick?: () => void;
  pointer?: boolean;
}

export const Logo = ({ subtitle, className, whiteBg, large, badge, onClick, pointer }: LogoProps) => {
  const logo = whiteBg ? dipeeoLogoBlue : badge ? dipeeoBadgeLogo : dipeeoLogoWhite;

  return (
    <LogoContainer pointer={pointer} badge={badge} large={large} onClick={onClick} className={className}>
      <img src={logo} alt='Logo Dipeeo' />
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </LogoContainer>
  );
};

const LogoContainer = styled.div<{ large: boolean; badge: boolean; pointer: boolean }>`
  position: relative;

  ${({ pointer }) => pointer && 'cursor: pointer;'}
  img {
    max-width: ${({ large, badge }) => (large ? '300px' : badge ? '50px' : '150px')};
    ${({ large }) => large && 'height: 70px;'}
  }
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  color: #f49d1d;
  font-size: 0.8125em;
  font-family: 'Baloo 2';
  font-weight: 700;
`;
