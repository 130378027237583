import styled from 'styled-components';
import { InitialsProps } from './Avatar.type';

export const Initials = styled.div<InitialsProps>`
  border-radius: 50%;
  background-color: ${({ color }) => color || 'rgba(244, 157, 29, 0.6)'};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  transition: box-shadow 0.2s ease-in-out;
  cursor: ${({ cursor }) => cursor};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          width: 25px;
          height: 25px;
          font-size: 12px;
        `;
      case 'large':
        return `
          width: 70px;
          height: 70px;
          font-size: 28px;
        `;
      default: // medium
        return `
          width: 50px;
          height: 50px;
          font-size: 22px;
        `;
    }
  }}// &:hover {
  //   box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
  // }
`;
