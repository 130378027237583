import { Modal } from '../modal/Modal';

import styled from 'styled-components';
import { Button } from '../Button';
import { COLORS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

export interface ModalNextUnfulfilledSurveyProps {
  entity: any;
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
}

export const ModalNextUnfulfilledSurvey = ({ entity, show, onClose, onClick }: ModalNextUnfulfilledSurveyProps) => {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: undefined, width: '40%' }} show={show} title={t('modal.next_step_title')}>
          <BasicModalContainer>
            <StyledSpan> {t('modal.congratulations_message')}</StyledSpan>
            <ButtonContainer>
              <Button onClick={onClick} label={t('modal.next_survey_button')} />
            </ButtonContainer>
          </BasicModalContainer>
        </Modal>
      )}
    </>
  );
};

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: ${COLORS.Grey};
  line-height: 25px;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
