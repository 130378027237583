import styled, { css, keyframes } from 'styled-components';
import { Logo } from '../logo/logo';
import { COLORS, Z_INDEX } from '../../utils/constants';
import { Menu } from '../menu/Menu';
import { selectSidenavState, setSidenavState } from '../../reducers/app.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { SubMenu } from '../menu/SubMenu';
import { useHistory } from 'react-router-dom';
import { paths } from '@routes/routes.constants';
import { selectImpersonated } from '../../reducers/account.reducer';

export const SideNav = () => {
  const dispatch = useDispatch<any>();
  const sidenavState = useSelector(selectSidenavState);
  const history = useHistory();
  const impersonated = useSelector(selectImpersonated);

  const closeSidenav = () => {
    dispatch(setSidenavState(null));
  };

  return (
    <>
      <Sidenav open={sidenavState} impersonated={!!impersonated}>
        <LogoContainer>
          <Logo pointer onClick={() => history.push(paths.dashboardGroup)} large={false} subtitle={''} whiteBg={false} badge={true} />
        </LogoContainer>
        <Menu />
      </Sidenav>
      <SubMenu />
      {sidenavState?.open && <Overlay onClick={closeSidenav} />}
    </>
  );
};

const fadeInOverlay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.48;
  }
`;

const fadeOutOverlay = keyframes`
  from {
    opacity: 0.48;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ closing?: boolean }>`
  position: fixed;
  background-color: #585858;
  opacity: 0.48;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
  cursor: pointer;
  animation: ${fadeInOverlay} 0.3s;
  ${({ closing }) =>
    closing &&
    css`
      animation: ${fadeOutOverlay} 0.5s;
    `};
`;

const LogoContainer = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  svg {
    color: white;
    transition: all 0.3s;
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  }
`;

const Sidenav = styled.nav<{ impersonated: boolean; open: boolean }>`
  width: 80px;
  height: calc(100% - 60px);
  background-color: ${({ impersonated }) => (impersonated ? COLORS.darkViolet : COLORS.darkBlue)};
  z-index: ${Z_INDEX.sidenav};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0;
  position: absolute;
  transition: all 0.3s;
  row-gap: 30px;
`;
