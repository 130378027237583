import { Modal } from '../modal/Modal';
import {
  ButtonContainer,
  GlobalContainer,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionContent,
  Download,
  Previous,
  StepsContainer,
  Step,
  StepLabel,
  StyledMdArrowForwardIos,
  LoaderContainer,
  Percentage,
  PercentageContainer,
  PreviewContainer,
  PreviewTitle,
  PreviewSummary,
  ResultContainer,
  EmptyStep,
  EmptyEntity,
} from './ModalMigrateSurveys_.style';
import { Button } from '@components/Button';
import { InputComponent } from '@components/form/InputComponent';
import { useState } from 'react';
import Loader from '@components/loader/loader';
import { getSurveyMigrationReport } from '@utils/api';
import { saveAs } from 'file-saver';
import { PiBriefcaseThin } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { selectSelectedAccount } from '../../reducers/account.reducer';

export interface ModalMigrateSurveysProps {
  show?: boolean;
  onClose: () => void;
  onMigrate: (sourceSurveyId: string, targetSurveyId: string) => void;
  validateMigration: (migratedEntities) => void;
  contentfulSurveys: any;
  migrationResult: any;
  loading: boolean;
}

export const ModalMigrateSurveys = ({
  show,
  onClose,
  onMigrate,
  validateMigration,
  contentfulSurveys,
  migrationResult,
  loading,
}: ModalMigrateSurveysProps) => {
  const selectedAccount = useSelector(selectSelectedAccount);
  const [open, setOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [sourceSurvey, setSourceSurvey] = useState(null);
  const [targetSurvey, setTargetSurvey] = useState(null);

  const getSurveysOptions = () => {
    return contentfulSurveys.map(survey => ({ value: survey.id, label: survey.name })) || [];
  };

  const handlePreviousButton = () => {
    if (currentStep === 2) {
      setSourceSurvey(null);
      setTargetSurvey(null);
    }
    if (currentStep === 3) {
      setTargetSurvey(null);
    }
    setCurrentStep(prevState => prevState - 1);
  };

  const handleSourceSurvey = (e, surveyId) => {
    setCurrentStep(2);
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setSourceSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleTargetSurvey = (e, surveyId) => {
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setTargetSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleMigrateSurveys = () => {
    if (currentStep === 4) {
      validateMigration(migrationResult.migratedEntities);
      return;
    }
    if (currentStep === 2) {
      onMigrate(sourceSurvey?.id, targetSurvey?.id);
    }
    setCurrentStep(prevState => prevState + 1);
  };
  const getButtonLabel = () => {
    switch (currentStep) {
      case 1:
        return 'SUIVANT';
      case 2:
        return 'PREVISUALISER';
      case 3:
        return 'VALIDER LA MIGRATION';
      case 4:
        return "J'AI COMPRIS";
    }
  };

  const migrationPercentage = migrationResult ? Math.round((migrationResult?.questionsMigrated / migrationResult?.questionsToMigrate) * 100) || 0 : 0;

  const downloadReport = async () => {
    let statsBuffer = await getSurveyMigrationReport(migrationResult);
    if (statsBuffer === false) {
      return;
    }
    let blob = new Blob([new Uint8Array(statsBuffer.data)], { type: 'application/octet-stream' });
    let currentDate = new Date().toLocaleDateString();
    saveAs(blob, `DipeeoMigrationReport-${currentDate}.xlsx`);
  };

  const getTargetSurveyConflictMessage = () => {
    if (!sourceSurvey || !targetSurvey) return;
    let isSourceSurveyVersionable = contentfulSurveys.find(survey => survey.id === sourceSurvey?.id)?.versioning.enabled;
    let isTargetSurveyVersionable = contentfulSurveys.find(survey => survey.id === targetSurvey?.id)?.versioning.enabled;
    if (isSourceSurveyVersionable && !isTargetSurveyVersionable) {
      return 'Impossible de migrer un questionnaire versionable vers un questionnaire non versionable';
    }
    for (const entity of selectedAccount.entities) {
      let targetVersions = entity.responses.find(survey => survey.surveyId === targetSurvey?.id)?.versions;
      if (targetVersions?.length) {
        return 'Le questionnaire cible possède une ou plusieurs version(s)';
      }
    }
  };

  const isButtonDisabled = !sourceSurvey?.id || !targetSurvey?.id || Boolean(getTargetSurveyConflictMessage());

  const getOnCloseFn = () => {
    return currentStep < 4 ? onClose() : validateMigration(migrationResult.migratedEntities);
  };

  return (
    <>
      {show && (
        <Modal onClose={() => getOnCloseFn()} size={{ height: '75%', width: '40%' }} show={show} title={'Espace migration de questionnaire :'}>
          <GlobalContainer>
            {currentStep < 4 && (
              <DescriptionContainer>
                <DescriptionHeader onClick={() => setOpen(prevState => !prevState)}>
                  <b>Comment ça marche ?</b>
                  <StyledMdArrowForwardIos open={open} className={'arrow'} />
                </DescriptionHeader>
                <DescriptionContent open={open}>
                  {open && (
                    <>
                      <div>1. Veuillez sélectionner le questionnaire contenant les sources que vous souhaitez importer</div>
                      <div>2. Sélectionnez ensuite le questionnaire qui recevra ces modifications</div>
                      <div>3. Observez la prévisualisation de la migration de vos questionnaires</div>
                      <div>4. Acceptez ou refusez celle-ci</div>
                    </>
                  )}
                </DescriptionContent>
              </DescriptionContainer>
            )}
            <StepsContainer>
              {currentStep < 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 1/4 :</b> Sélection du questionnaire source :
                  </StepLabel>
                  <InputComponent
                    type={'select'}
                    options={getSurveysOptions()}
                    onChange={handleSourceSurvey}
                    sharable={false}
                    value={getSurveysOptions().filter(value => {
                      return value.value === sourceSurvey?.id;
                    })}
                  />
                </Step>
              )}
              {currentStep < 3 && sourceSurvey && (
                <Step>
                  <StepLabel>
                    <b>Etape 2/4 :</b> Sélection du questionnaire qui recevra les modifications :
                  </StepLabel>
                  {sourceSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveysOptions()}
                      onChange={handleTargetSurvey}
                      sharable={false}
                      value={getSurveysOptions().filter(value => {
                        return value.value === targetSurvey?.id;
                      })}
                      error={getTargetSurveyConflictMessage()}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire source</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep === 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 3/4 :</b> Prévisualisation :
                  </StepLabel>
                  {!loading ? (
                    <>
                      <PreviewContainer>
                        <PreviewTitle>Rapport de votre migration :</PreviewTitle>
                        <PreviewSummary>
                          {sourceSurvey.name} {'=>'} {targetSurvey.name}
                        </PreviewSummary>
                        <ResultContainer>
                          <PercentageContainer type={'success'}>
                            <Percentage type={'success'}>{migrationPercentage}%</Percentage>
                            <span>
                              {' '}
                              <b>({migrationResult?.matchedQuestions.length} réponses)</b> de réponses intégrées aux questionnaires
                            </span>
                          </PercentageContainer>
                        </ResultContainer>
                        <ResultContainer>
                          <PercentageContainer type={'error'}>
                            <Percentage type={'error'}>{100 - migrationPercentage}%</Percentage>
                            <span>
                              <b>({migrationResult?.unmatchedQuestions.length} réponses)</b> n'ont pas été intégrées aux questionnaires
                            </span>
                          </PercentageContainer>
                        </ResultContainer>
                        <Download onClick={downloadReport}>+ Télécharger le rapport</Download>
                      </PreviewContainer>
                    </>
                  ) : (
                    <LoaderContainer>
                      <Loader loaderOnly={true} />
                    </LoaderContainer>
                  )}
                </Step>
              )}
              {currentStep === 4 && (
                <Step>
                  <StepLabel>
                    <b>Etape 4/4 :</b> Migration terminée ! Réactivez votre questionnaire
                  </StepLabel>
                  <p>Migration effectuée avec succès !</p>
                  <p>
                    Votre migration vient d'être effectué. Votre questionnaire "{targetSurvey.name}" comporte désormais "
                    {migrationResult.matchedQuestions.length} réponses" du questionnaire "{sourceSurvey.name}"
                  </p>
                  <p>
                    Afin que celle-ci ne génère aucun conflit, vos deux questionnaires ont été désactivés. Pour accéder à votre migration et
                    visualiser le questionnaire dans la partie client, veillez à réactiver le questionnaire "{targetSurvey.name}" une fois la
                    migration terminée.
                  </p>
                </Step>
              )}
            </StepsContainer>
            <ButtonContainer>
              {currentStep > 1 && <Previous onClick={handlePreviousButton}>{'PRÉCÉDENT'}</Previous>}
              <Button onClick={handleMigrateSurveys} label={getButtonLabel()} disabled={isButtonDisabled} customColor={'rgba(244, 157, 29, 1)'} />
            </ButtonContainer>
          </GlobalContainer>
        </Modal>
      )}
    </>
  );
};
