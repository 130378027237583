/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';

import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { MdClose } from 'react-icons/md';

import Div from 'src/components/div/Div';
import { InputComponent } from 'src/components/form/InputComponent';
import IconStatus from 'src/components/iconStatus/IconStatus';
import Spinner from 'src/components/spinner/Spinner';

import 'react-calendar/dist/Calendar.css';
import { isEmailValid } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';

const CalendarSide = ({
  setIsCalendarOpen,
  index,
  setIndex,
  selectedSlot,
  setSelectedSlot,
  selectedDate,
  setSelectedDate,
  attendees,
  setAttendees,
  bookSlot,
  success,
  error,
  user,
  setDescription,
  setPhoneNumber,
  fullPage,
  loading,
  availableSlots,
  calendarActiveStartDate,
  setCalendarActiveStartDate,
}) => {
  const { t } = useTranslation();
  const [emailInputValue, setEmailInputValue] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<number>();

  const [emailError, setEmailError] = useState(false);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const isDateDisabled = ({ activeStartDate, date }) => {
    // Check if the date is not in the active month
    if (!isDateInActiveMonth({ activeStartDate, date })) {
      return true;
    }

    // Check if the date is in the past
    if (dayjs(date).isBefore(dayjs(), 'day')) {
      return true;
    }

    // Check if the date is a weekend day (Saturday or Sunday)
    const dayOfWeek = dayjs(date).day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return true;
    }

    // Check if the time is after 18:00 (6:00 PM)
    if (dayjs(date).hour() >= 18) {
      return true;
    }

    const availability = availableSlots?.days?.find(day => day.date === dayjs(date).format('YYYY-MM-DD'));
    if (!availability?.slots?.length || availability?.status === 'unavailable') return true;

    return false;
  };

  const isDateInActiveMonth = ({ activeStartDate, date }) => {
    const activeMonth = activeStartDate.getMonth();
    const activeYear = activeStartDate.getFullYear();

    return date.getMonth() === activeMonth && date.getFullYear() === activeYear;
  };

  const tileContent = ({ activeStartDate, date }) => {
    const currentDate = new Date();
    if (isDateInActiveMonth({ activeStartDate, date })) {
      return date.toDateString() === currentDate.toDateString() ? <Dot /> : null;
    }
  };

  const handleEmailChange = e => {
    if (emailError) setEmailError(false);
    setEmailInputValue(e.target.value);
  };

  const removeEmail = emailToDelete => {
    setAttendees(attendees.filter(emailList => emailList.email !== emailToDelete));
  };

  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  const handleEmailValidation = e => {
    if (emailInputValue === '') return;
    if (['Enter', 'Tab', ','].includes(e.key) || e.type === 'blur') {
      e.preventDefault();
      let email = emailInputValue.trim();

      if (!isEmailValid(email)) {
        setEmailError(true);
      }

      if (isEmailValid(email)) {
        setAttendees([...attendees, { email: emailInputValue, responseStatus: 'accepted' }]);
        setEmailInputValue('');
        setEmailError(false);
      }
    }
  };

  const handlePhoneNumberChange = e => {
    setPhoneNumber(e.target.value);
  };

  let interval;

  useEffect(() => {
    if (index === 2) {
      setTimeLeft(5);
      interval = setInterval(() => {
        setTimeLeft(t => {
          if (t < 1) {
            clearInterval(interval);
          }
          return t - 1;
        });
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => interval && clearInterval(interval);
  }, [index]);

  if (timeLeft < 1 && !fullPage) {
    setTimeout(() => {
      setIsCalendarOpen(false);
    }, 0);
  }

  const isDisabledMonth =
    dayjs(calendarActiveStartDate).year() === dayjs().year() ? dayjs(calendarActiveStartDate).subtract(1, 'month').month() < dayjs().month() : false;

  return (
    <CalendarBox>
      {index === 0 && (
        <>
          <Title>{t('calendar.selectDateTime')}</Title>
          <Div flex mt='2rem' gap='5%' justifyContent='center' justifyItem='center' height='88%' width='auto' maxWidth='100%' position='relative'>
            {loading && (
              <Div position='absolute' zIndex={2} height='100%' width='100%'>
                <Spinner
                  color='#0060d4'
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 2,
                  }}
                />
              </Div>
            )}
            <StyledCalendar
              onChange={handleDateChange}
              value={selectedDate}
              tileDisabled={({ activeStartDate, date }) => isDateDisabled({ activeStartDate, date })}
              tileContent={tileContent}
              prev2Label={null}
              prevLabel={isDisabledMonth ? null : <GrFormPrevious size={20} />}
              nextLabel={<GrFormNext size={20} />}
              next2Label={null}
              locale='fr-FR'
              fullPage={fullPage}
              onActiveStartDateChange={({ activeStartDate }) => {
                setCalendarActiveStartDate(activeStartDate);
                setSelectedDate(null);
              }}
              // loading={loading}
            />
            {selectedDate && (
              <Div flex column height='100%' width='100%' overflowY='auto' style={{ opacity: loading && '0.7' }}>
                <Title>{dayjs(selectedDate).format('dddd, MMMM D')}</Title>
                <Div flex column overflowY='auto' pr='12px' height='100%'>
                  {!loading ? (
                    availableSlots?.days
                      ?.find(day => day.date === dayjs(selectedDate).format('YYYY-MM-DD'))
                      ?.slots?.map((slot, index) => {
                        return (
                          <Div flex gap='5px' key={index}>
                            <StyledButton
                              disabled={selectedSlot?.start === slot.start}
                              onClick={() => {
                                setSelectedSlot(slot);
                              }}>
                              {dayjs(slot.start).format('h:mmA')}
                            </StyledButton>
                            {selectedSlot?.start === slot.start && (
                              <StyledButton
                                active
                                onClick={() => {
                                  setIndex(1);
                                }}>
                                {t('common.next')}
                              </StyledButton>
                            )}
                          </Div>
                        );
                      })
                  ) : (
                    <CircularSpinner />
                  )}
                </Div>
              </Div>
            )}
          </Div>
        </>
      )}
      {index === 1 && (
        <>
          <Div flex gap='5%' mb={'1rem'}>
            <Div flex column width='100%'>
              <Label>{t('common.name')} *</Label>
              <Input placeholder={user.name} type='text' disabled />
            </Div>
            <Div flex column width='100%'>
              <Label>{t('common.email')} *</Label>
              <Input placeholder={user.email} type='text' disabled />
            </Div>
          </Div>

          <Div mb={'1rem'}>
            <Label>{t('calendar.inviteesEmail')}</Label>
            <InputContainer>
              <InputComponent
                type={'text'}
                placeholder={t('calendar.addInvitees')}
                onChange={handleEmailChange}
                onKeyDown={handleEmailValidation}
                onBlur={handleEmailValidation}
                value={emailInputValue}
                sharable={false}
                error={emailError && t('calendar.invalidEmail')}
              />
              <ChipsContainer>
                {attendees?.map(
                  (list, index) =>
                    list.email && (
                      <Chips key={index}>
                        {list.email}
                        <ChipsButton onClick={() => removeEmail(list.email)}>
                          <MdClose />
                        </ChipsButton>
                      </Chips>
                    )
                )}
              </ChipsContainer>
            </InputContainer>
          </Div>
          <Div mb={'1rem'}>
            <Label>{t('calendar.shareDetails')}</Label>
            <TextArea title='description' placeholder='' name='description' id='' cols={30} rows={2} onChange={handleDescriptionChange} />
          </Div>
          <Div mb={'1rem'}>
            <Label>{t('common.phoneNumber')}</Label>
            <Input name='phoneNumber' onChange={handlePhoneNumberChange} />
          </Div>
          <ConfirmButton onClick={bookSlot} disabled={loading}>
            {!loading ? t('calendar.confirmEvent') : <Spinner color='white' />}
          </ConfirmButton>
        </>
      )}
      {index === 2 && (
        <Div flex column alignItems='center' mt='22%'>
          {success && (
            <>
              <IconStatus status={'success'} />
              <Text>{t('calendar.successAppointment')}</Text>
            </>
          )}
          {error && (
            <>
              <IconStatus status={'error'} />
              <Text>{t('calendar.errorAppointment')}</Text>
            </>
          )}

          {!fullPage && <CloseText>{t('calendar.autoClose', { timeLeft })}</CloseText>}
        </Div>
      )}
    </CalendarBox>
  );
};

export default CalendarSide;

// -----------------------------
const CloseText = styled.h4`
  position: absolute;
  bottom: 5%;
  font-weight: bold;
  color: #737373;
  letter-spacing: 0;
  font-family: 'Nunito';
  font-size: 1rem;
`;
const Text = styled.h1`
  color: black;
  font-weight: 600;
  font-size: 28px;
  @media (max-width: 640px) {
    font-size: 26px;
  }
  text-align: center;
`;
// ----------------------------
const Label = styled.h5`
  font-size: 1.2rem;
  margin: 0.75rem 0;
`;
// ----------------------------

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const CircularSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3b82f6;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  position: relative;
  top: 50%;
  left: 50%;
`;

// ------------------------------
const sharedInputStyles = `
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 46px;
  padding: 10px 14px;
  border: solid 1px hsl(0,0%,80%);
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  color: #1a1a1a;
  font-size: 16px;
  line-height: 1.5;
  overflow-wrap: normal;
  appearance: none;

  &:focus-visible {
    outline-offset: unset;
    outline: 1px solid #2684FF !important;
  }
`;

const Input = styled.input`
  ${sharedInputStyles}
  ${({ disabled }) => disabled && `background-color: #b2b2b252;`}
`;

const TextArea = styled.textarea`
  ${sharedInputStyles}
  resize: vertical;
  overflow-wrap: normal;
  overflow: auto;
`;
// -------------------------------
const ConfirmButton = styled.button`
  position: absolute;
  bottom: 6%;
  border-color: rgb(0, 105, 255);
  background-color: rgb(0, 105, 255);
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;

  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;

  min-height: 40px;
  min-width: 15rem;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 40px;

  margin-top: 1.5rem;
  cursor: pointer;

  transition: width 0.4s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

// ---------------------------

const StyledButton = styled.button<{ active?: boolean; disabled?: boolean }>`
  margin-top: 0.5rem;
  width: 100%;
  background-color: white;
  color: dodgerblue;
  font-weight: bold;
  padding: 0.75rem 2rem;
  border: 1px solid lightblue;
  border-radius: 10px;
  transition:
    border-color 0.2s,
    color 0.2s;

  ${({ active }) =>
    active &&
    css`
      background-color: dodgerblue;
      color: white;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: white;
      border-color: transparent;
      background-color: rgb(0 0 0/60%);
    `}

  &:not([disabled]):hover {
    border-color: dodgerblue;
  }
  &:not([disabled]) {
    cursor: pointer;
  }
`;

// ------------------------------------

const CalendarBox = styled.div`
  width: 100%;
  max-width: 75%;
  overflow: hidden;
  border-top: none;
  padding-left: 1rem;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.75rem 0;
  width: 100%;
  text-align: center;
  color: #4b5563;
  display: flex;
  flex-direction: column;
  letter-spacing: 0;
  font-family: 'Nunito';
`;
// ----------------------------

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const Chips = styled.div`
  background-color: #c8e3f6;
  display: inline-flex;
  border-radius: 30px;
  height: 30px;
  font-size: 14px;
  padding: 0 4px 0 1rem;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
`;

const ChipsButton = styled.div`
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 22px;
`;

// styled Calendar

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: currentColor;
  margin: 0 auto;
  position: relative;
  bottom: 0;
  border-radius: 50%;
`;

const StyledCalendar = styled(Calendar)<{ fullPage?: boolean }>`
  border: none;
  width: fit-content;
  ${({ fullPage }) => fullPage && `width: 450px;`}
  font-size: 1.3rem;
  font-family: 'Nunito';

  .react-calendar__navigation {
    width: 70%;
    margin: 1rem auto 2rem;
    align-items: center;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .react-calendar__navigation__arrow {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: rgb(0, 105, 255);
    background-color: rgba(0, 105, 255, 0.065);
  }

  // .react-calendar__navigation button {
  // font-weight: bold;
  // font-size: 14px;
  // color: #374151;
  // padding: 0.5rem 1rem;
  // border: none;
  // background: none;
  // cursor: pointer;
  // position: relative;
  // z-index: 1;
  // display: inline-flex;
  // justify-content: center;
  // align-items: center;
  // width: 38px;
  // height: 38px;
  // border-radius: 50%;
  // color: rgb(0, 105, 255);
  // background-color: rgba(0, 105, 255, 0.065);
  // }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:active,
  .react-calendar__navigation button:enabled:focus {
    background-color: rgba(0, 105, 255, 0.15);
  }

  // .react-calendar__navigation button[disabled] {
  //   color: #d1d5db;
  //   cursor: not-allowed;
  // }

  // .react-calendar__viewContainer {
  //   margin: 0;
  //   padding: 0;
  // }

  .react-calendar__month-view__days {
    margin: 0;
    padding: 0;
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
    align-items: center;
    justify-items: center;
  }

  .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
    justify-items: center;
  }

  // .react-calendar__month-view__weekdays__weekday {
  //   background-color: #f3f4f6;
  //   border: 1px solid #d1d5db;
  //   text-align: center;
  //   padding: 0.5rem;
  //   font-weight: bold;
  //   color: #374151;
  // }

  .react-calendar__month-view__days__day--neighboringMonth {
    transform: scale(0);
  }

  .react-calendar__tile {
    // padding: 0.5rem;
    // text-align: center;
    // border: 1px solid #d1d5db;
    // background-color: #ffffff;
    // font-weight: bold;
    // color: #374151;
    // transition: background-color 0.3s ease-in-out;
    // cursor: pointer;
    width: 2.75rem;
    height: 2.75rem;
    @media (min-width: 1600px) {
      width: 44px;
      height: 44px;
    }
  }

  .react-calendar__tile:disabled {
    background-color: transparent;
  }

  .react-calendar__tile:enabled {
    border-radius: 50%;

    padding: 0;
    // max-width: 2.75rem;

    font-size: 0.875rem;
    line-height: 1.25rem;
    // width: 44px;
    // height: 44px;
    font-weight: 600;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;

    color: rgb(0 96 212);
    background-color: rgb(235 244 255);
  }

  .react-calendar__tile:enabled:hover {
    background-color: rgba(0, 105, 255, 0.15);
  }

  .react-calendar__tile--active {
    background-color: #3b82f6 !important;
    color: #ffffff !important;

    border-radius: 50%;

    padding: 0;
    // max-width: 2.75rem;

    font-size: 0.875rem;
    line-height: 1.25rem;
    // width: 2.75rem;
    // height: 2.75rem;
    font-weight: 600;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    // color: rgb(255 255 255);
    // background-color: rgb(0 105 255);
  }

  .react-calendar__tile--hasActive:not(.react-calendar__tile--active) {
    display: none;
  }
`;
