import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import { MdMoreVert } from 'react-icons/md';

import { ChildContainer, MenuItem, MoreContainer, PopoverMenu } from './MoreOptions_style';
import { MoreOptionsProps } from './MoreOptions.type';

const MoreOptions = ({ className, options, icon, children, placement, rotate, removeBorder, svgColor, offset, hover }: MoreOptionsProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [show, setShow] = useState(false);
  const Icon = icon ?? MdMoreVert;

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target?.parentElement?.dataset?.pop) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [referenceElement]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? 'right',
    modifiers: offset
      ? [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ]
      : [],
  });

  const handleOptionClick = (e, onClick) => {
    e.stopPropagation();
    onClick && onClick();
    setShow(false);
  };

  const handleMoreOptionClick = e => {
    e.stopPropagation();
    setShow(prevState => !prevState);
  };

  const getProps = () => ({
    ...(hover && { onMouseEnter: handleMoreOptionClick }),
    ...(!children && {
      rotate: !!rotate,
      removeBorder: removeBorder,
      svgColor: svgColor,
    }),
    show: show,
    onClick: handleMoreOptionClick,
  });

  // Clone children and pass additional props
  const modifiedChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...{ show, setShow } });
    }
    return child;
  });

  return (
    <>
      {options.length > 0 && (
        <>
          {children ? (
            <ChildContainer {...getProps()} ref={setReferenceElement}>
              {modifiedChildren}
            </ChildContainer>
          ) : (
            <MoreContainer {...getProps()} ref={setReferenceElement}>
              <Icon />
            </MoreContainer>
          )}
          {show && (
            <PopoverMenu className={className} data-pop ref={setPopperElement} {...attributes.popper} style={styles.popper}>
              {options?.map((option, index) => {
                let Icon = option.icon;
                return (
                  <MenuItem color={option.color} key={index} onClick={e => handleOptionClick(e, option.onClick)}>
                    {Icon && <Icon />}
                    {option.label}
                  </MenuItem>
                );
              })}
            </PopoverMenu>
          )}
        </>
      )}
    </>
  );
};

export default MoreOptions;
