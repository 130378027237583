import { saveSubContractorResponses } from '@utils/api';

export const WIZARD_SUB_CONTRACTORS = t => [
  {
    step: 1,
    label: t('wizard.subContractor.step1.label'),
    title: t('wizard.subContractor.step1.title'),
    content: t('wizard.subContractor.step1.content'),
    input: false,
    inputType: 'text',
    options: [],
    buttonLabel: t('common.start').toUpperCase(),
    conditions: [],
    showNextButton: true,
  },
  {
    step: 2,
    label: t('wizard.subContractor.step2.label'),
    content: t('wizard.subContractor.step2.content'),
    items: [
      {
        input: true,
        inputType: 'text',
        inputLabel: t('wizard.subContractor.step2.inputLabels.companyName'),
        name: 'companyName',
        placeholder: t('wizard.subContractor.step2.inputPlaceholders.companyName'),
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: t('wizard.subContractor.step2.inputLabels.responsiblePosition'),
        name: 'responsablePosition',
        placeholder: t('wizard.subContractor.step2.inputPlaceholders.responsiblePosition'),
      },
      {
        input: true,
        inputType: 'text',
        inputLabel: t('wizard.subContractor.step2.inputLabels.responsibleEmail'),
        name: 'responsableEmail',
        placeholder: t('wizard.subContractor.step2.inputPlaceholders.responsibleEmail'),
      },
    ],
    buttonLabel: t('common.next').toUpperCase(),
    showNextButton: true,
  },
  {
    step: 3,
    label: t('wizard.subContractor.step3.label'),
    input: false,
    inputType: 'text',
    buttonLabel: t('common.next').toUpperCase(),
    buttonShouldSave: true,
    saveFunction: saveSubContractorResponses,
    hasQuestion: true,
    showNextButton: true,
  },
  {
    step: 4,
    label: t('wizard.subContractor.step4.label'),
    title: t('wizard.subContractor.step4.title'),
    content: t('wizard.subContractor.step4.content'),
    input: false,
    inputType: 'text',
    options: [],
    conditions: [],
    showNextButton: false,
  },
];
