import styled, { keyframes } from 'styled-components';
import { Button } from '@components/Button';
import { COLORS } from '@utils/constants';

export const CardContainer = styled.div`
  background-color: ${COLORS.white};
  width: fit-content;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'nunito';
  position: absolute;
`;

export const ClosePopUpContainer = styled.div`
  display: flex;
  justify-content: end;
  color: ${COLORS.Black};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div<{ isActive: boolean }>`
  background-color: ${COLORS.white};
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 70px;
  padding: 20px;
  display: block;
  width: 400px;
  height: 430px;
  transform: translateY(${({ isActive }) => (isActive ? '0px' : 'var(--translateY)')});
  transition: all 0.5s;
  animation: ${({ isActive }) => (isActive ? fadeIn : 'none')} 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
`;

export const Content = styled.div<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: all 600ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation: ${({ isActive }) => (isActive ? fadeIn : 'none')} 600ms;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: 'nunito';
  height: 100%;
`;

export const ButtonContainer = styled(Button)<{ isActive: boolean }>`
  display: block;
  cursor: pointer;
  transition: transform 0.25s;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.span`
  font-weight: bold;
  font-size: 24px;
  color: ${COLORS.Black};
`;

export const PopUpTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
