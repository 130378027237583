const Flag = ({ country }) => {
  return (
    <picture>
      {/* <source
        type='image/webp'
        srcSet={`https://flagcdn.com/28x21/${country}.webp, 
      https://flagcdn.com/56x42/${country}.webp 2x,
      https://flagcdn.com/84x63/${country}.webp 3x`}
      /> */}
      {/* <source
        type='image/png'
        srcSet={`https://flagcdn.com/28x21/${country}.png,
      https://flagcdn.com/56x42/${country}.png 2x,
      https://flagcdn.com/84x63/${country}.png 3x`}
      /> */}
      <img src={`https://flagcdn.com/28x21/${country}.webp`} width='28' height='21' alt='United Kingdom' />
    </picture>
  );
};

export default Flag;
