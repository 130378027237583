import styled, { css, keyframes } from 'styled-components';
import { MdClose } from 'react-icons/md';
import { COLORS, Z_INDEX } from '../../utils/constants';
import { useEffect, useState } from 'react';

export interface ModalProps {
  show?: boolean;
  title?: string;
  subTitle?: string;
  onClose: any;
  children?: React.ReactNode;
  actions?: Array<{ label: string; onClick?: () => void; icon?: any; selected?: boolean }>;
  size?: { height?: string; width?: string };
  alignment?: string;
  loading?: boolean;
  overAll?: boolean;
  hideClose?: boolean;
}

export const Modal = ({ show, onClose, title, children, size, alignment, loading, overAll, subTitle, hideClose }: ModalProps) => {
  const [displayModal, setDisplayModal] = useState(false);

  const handleOnClose = () => {
    onClose && onClose();
    return;
  };

  useEffect(() => {
    if (show) {
      setDisplayModal(show);
    } else {
      let timer = setTimeout(() => {
        setDisplayModal(show);
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [show]);

  return (
    <>
      {displayModal && (
        <>
          <ModalContainer closing={!show} alignment={alignment} height={size?.height} width={size?.width} overAll={overAll}>
            {loading ? (
              <LoaderContainer>
                <LoaderElt />
              </LoaderContainer>
            ) : (
              <>
                {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
                {subTitle && <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
                {!hideClose && <MdClose onClick={handleOnClose} />}
                <ChildrenContainer>{children}</ChildrenContainer>
              </>
            )}
          </ModalContainer>
          <Overlay closing={!show} onClick={handleOnClose} overAll={overAll} />
        </>
      )}
    </>
  );
};

const LoaderContainer = styled.div`
  width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const ModalContainer = styled.div<{ height: string; width: string; alignment: string; closing: boolean; overAll: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 35px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  max-height: 75%;
  margin: 0 auto;
  position: fixed;
  border-radius: 20px;
  ${({ overAll }) => `z-index: ${overAll ? Z_INDEX.overall : Z_INDEX.modal};`}
  // z-index: 999;
  row-gap: 25px;
  animation: fadeInUp 0.5s;
  top: 10%;
  left: 50%;
  ${({ width }) => `
    width: ${width ? width : '620px'};
    margin-left: ${width ? `calc(-${width} / 2);` : `calc(-620px / 2);`};
  `}
  ${({ height }) => height && `height: ${height};`}
  ${({ closing }) => closing && `animation: fadeOutUp 0.5s;`}
  ${({ alignment }) => alignment && `align-items: ${alignment};`}
  > svg {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;
    position: absolute;
    top: 25px;
    right: 25px;
    height: 24px;
    width: 24px;

    &:hover {
      opacity: 1;
    }
  }
`;

const fadeInOverlay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.48;
  }
`;

const fadeOutOverlay = keyframes`
  from {
    opacity: 0.48;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ closing: boolean; overAll?: boolean }>`
  position: fixed;
  background-color: #585858;
  opacity: 0.48;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  ${({ overAll }) => `z-index: ${overAll ? Z_INDEX.overall + Z_INDEX.below : Z_INDEX.overlay};`}
  // z-index: 99;
  cursor: pointer;
  animation: ${fadeInOverlay} 0.5s;
  ${({ closing }) =>
    closing &&
    css`
      animation: ${fadeOutOverlay} 0.5s;
    `};
`;

const Title = styled.div`
  font-family: 'Nunito';
  font-size: 2em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  align-self: flex-start;

  > b {
    color: ${COLORS.AquaMarine};
  }
`;

const SubTitle = styled.div`
  font-family: 'Nunito';
  font-size: 1.5em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'Roboto';
  font-weight: 400;
  height: calc(100% - 70px);
  min-height: 0;
  flex: 1;
  width: 100%;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
`;

const ball1 = keyframes`
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
`;

const ball2 = keyframes`
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }`;

const LoaderElt = styled.div`
  animation: ${rotate} 1s infinite;
  height: 50px;
  width: 50px;

  &:before,
  &:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }

  &:before {
    animation: ${ball1} 1s infinite;
    background-color: #187ead;
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }

  &:after {
    animation: ${ball2} 1s infinite;
    background-color: #00a096;
    box-shadow: 30px 0 0 #97bf0d;
  }
`;
