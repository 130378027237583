import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { COLORS } from 'src/utils/constants';
import { paths } from 'src/routes/routes.constants';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    button {
      box-shadow: none;
      min-width: 0;
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${COLORS.Green};
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${COLORS.ToasterError};
  }
`;

export const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const Content = styled.div<{ path: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ path }) => {
    switch (path) {
      case paths.login:
      case paths.wizard:
        return 'margin-left: 0; width: 100%;';
      case paths.wizard2:
        return 'margin-left: 0; width: 100%;';
      default:
        return 'margin-left: 80px; width: 100%;';
    }
  }};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;
