import styled from 'styled-components';

export const Aside = styled.aside`
  color: #fff;
  width: 250px;
  padding-left: 20px;
  height: 100vh;
  background-image: linear-gradient(102deg, #326c82, #99bbd81f);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const SidebarLink = styled.a<{ active: boolean }>`
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  gap: 15px;
  display: flex;
  align-items: center;
  padding: 12px;
  padding-left: 30px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: #326c82;
    background: #fff;
    outline: none;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: 0.2s;
  }

  i {
    margin-right: 5px;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  ${({ active }) =>
    active &&
    `
    color: #326c82;
    background: #fff;
    outline: none;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`}
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 40px 0;
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

export const SideBarItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
