import { useCallback, useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import useDidUpdate from 'src/hooks/useDidUpdate';
import { COLORS } from 'src/utils/constants';

const MultiTextValueInput = ({ inputKey, placeholder, defaultValue, onFocus, onBlur, name, onMultiTextChange, valuesLimit }) => {
  const [multiTextValues, setMultiTextValues] = useState(
    defaultValue?.map(elt => {
      return { label: elt.value, value: elt.value };
    }) || []
  );
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const selectRef = useRef(null);

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleKeyDown = useCallback(
    (event): { event: React.KeyboardEventHandler; name: string } => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          if (multiTextValues.length >= valuesLimit) {
            setError(true);
            return;
          }
          setMultiTextValues(prev => [...prev, createOption(inputValue)]);
          setInputValue('');
          event.preventDefault();
      }
    },
    [inputValue]
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (inputValue && selectRef.current && !selectRef.current?.select?.inputRef?.contains(event.target)) {
        if (multiTextValues.length >= valuesLimit) {
          setError(true);
          return;
        }
        setMultiTextValues(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue, multiTextValues.length, valuesLimit]);

  const handleInputValueChange = value => {
    if (error) setError(false);
    setInputValue(value);
  };

  useDidUpdate(() => {
    onMultiTextChange({ target: { name, value: multiTextValues.map(elt => elt.value) } });
  }, [multiTextValues]);

  return (
    <div key={inputKey} className='input'>
      <CreatableSelect
        ref={selectRef}
        name={name}
        placeholder={placeholder}
        value={multiTextValues}
        inputValue={inputValue}
        menuIsOpen={false}
        onChange={value => setMultiTextValues(value)}
        onInputChange={handleInputValueChange}
        onKeyDown={handleKeyDown}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onBlur={onBlur}
        isClearable
        isMulti
        components={{
          DropdownIndicator: null,
        }}
      />
      {error && <ErrorMessage>Nombre maximal de contacts atteint.</ErrorMessage>}
    </div>
  );
};
export default MultiTextValueInput;

const ErrorMessage = styled.h5`
  margin-top: 5px;
  color: ${COLORS.Red};
  font-weight: 400;
`;
