import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import {
  Arrow,
  ChildrenContainer,
  CloseIcon,
  DropDownMenuContainer,
  Header,
  HeaderContainer,
  OptionContainer,
  OptionContent,
  OptionLabel,
  Slider,
  TiteContainer,
} from './DropDownMenu.styles';
import { Separator } from '../notifications/Notifications.styles';

interface DropDownMenuProps {
  options: {
    label: string | React.ReactElement;
    onClick: () => void;
    onHover?: () => void;
  }[];
  header: React.ReactElement;
  title?: string | React.ReactElement;
  callBackOpenDropDown?: () => void;
  callBackCloseDropDown?: () => void;
  width?: string;
  emptyOptionsComponent?: React.ReactElement;
}
const DropDownMenu: React.FC<DropDownMenuProps> = ({
  header,
  title,
  callBackOpenDropDown,
  options,
  width,
  emptyOptionsComponent,
  callBackCloseDropDown,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [show, setShow] = useState(false);

  const handleShow = async () => {
    setShow(!show);
  };

  useEffect(() => {
    if (show) {
      callBackOpenDropDown && callBackOpenDropDown();
    } else {
      callBackCloseDropDown && callBackCloseDropDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!referenceElement || !referenceElement.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceElement]);

  const handleClickOption = option => {
    option.onClick();
    setShow(false);
  };
  return (
    <DropDownMenuContainer ref={setReferenceElement}>
      <HeaderContainer onClick={handleShow}>{header}</HeaderContainer>
      {show && (
        <ChildrenContainer width={width}>
          <Arrow />
          <Header>
            <TiteContainer>{title && title}</TiteContainer>
            <CloseIcon>
              <MdClose onClick={handleShow} />
            </CloseIcon>
          </Header>
          {options.length !== 0 ? (
            <Slider>
              <OptionContainer>
                {options.map(
                  (option, index) =>
                    option && (
                      <div key={`option-${index}`}>
                        <Separator />
                        <OptionContent onMouseEnter={option.onHover && option.onHover} onClick={() => handleClickOption(option)}>
                          <OptionLabel>{option.label}</OptionLabel>
                        </OptionContent>
                      </div>
                    )
                )}
              </OptionContainer>
            </Slider>
          ) : (
            <div>
              <Separator />
              {emptyOptionsComponent}
            </div>
          )}
        </ChildrenContainer>
      )}
    </DropDownMenuContainer>
  );
};

export default DropDownMenu;
