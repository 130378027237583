import styled from 'styled-components';
import { COLORS } from '../../utils/constants';

interface ProgressBarProps {
  level: number;
  max: number;
  label?: string;
}
export const ProgressBar = ({ level, max, label }: ProgressBarProps) => {
  return (
    <GlobalContainer>
      <Title>
        <span>{level}</span> {label} sur <span>{max}</span>
      </Title>
      <ProgressBarMeter level={level} max={max}></ProgressBarMeter>
    </GlobalContainer>
  );
};

const GlobalContainer = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 1.33rem;

  span {
    font-weight: 600;
  }
`;

const ProgressBarMeter = styled.div<{ level: number; max: number }>`
  height: 0.5rem;
  background-color: rgba(239, 240, 246, 1);
  border-radius: 35px;
  margin: 0.5rem 0;

  &:before {
    content: '';
    background-color: ${COLORS.DarkGreen};
    height: 100%;
    width: ${({ level, max }) => (level / max) * 100}%;
    display: block;
    border-radius: 35px;
    transition: width 0.2s;
  }
`;
