import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash } from 'react-icons/fi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { COLORS, ACCOUNT_TYPES } from '../../utils/constants';
import { createDbAccount, selectAllAccounts } from '../../reducers/account.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import * as _ from 'lodash';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { setSidenavState } from '../../reducers/app.reducer';
import { paths } from '@routes/routes.constants';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface DashboardCreateUserProps {
  open: boolean;
}

export interface UserProps {
  name: string;
  email: string;
  firstName: string;
}

export interface NewAccountInfos {
  email: string;
  companyName: string;
  accountType: string;
  cgu: string;
  withProduct: string;
  processor: string;
  surveys: any;
  documents: any;
  registries: any;
  users: Array<UserProps>;
}

export const DashboardCreateUser = ({ open }: DashboardCreateUserProps) => {
  //redux
  const dispatch = useDispatch<any>();
  const allAccounts = useSelector(selectAllAccounts);
  const contentfulData = useSelector(selectAllContentfulData);
  const history = useHistory();

  //state
  const accountInitialState = {
    companyName: '',
    accountType: 'user',
    cgu: '',
    withProduct: '',
    processor: '',
    surveys: contentfulData.surveys
      ?.filter(survey => survey.isDefaultIncluded)
      .map(survey => {
        return { value: survey.id, label: survey.name };
      }),
    documents: contentfulData.documents
      ?.filter(document => document.isDefaultIncluded)
      .map(document => {
        return { value: document.id, label: document.name };
      }),
    registries: contentfulData.registries
      ?.filter(registry => registry.isDefaultIncluded)
      .map(registry => {
        return { value: registry.id, label: registry.name };
      }),
    users: [{ email: '', name: '', firstName: '', role: 'admin' }],
  };
  const errorsInitialState = {
    companyName: true,
    name: true,
    email: true,
    firstName: true,
    cgu: true,
    processor: true,
    withProduct: true,
  };
  const [newAccountInfos, setNewAccountInfos] = useState(accountInitialState);
  const [errors, setErrors] = useState(errorsInitialState);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleCreateAccount = async () => {
    await dispatch(
      createDbAccount({
        companyName: newAccountInfos.companyName,
        accountType: newAccountInfos.accountType,
        cgu: newAccountInfos.cgu,
        withProduct: newAccountInfos.withProduct,
        processor: newAccountInfos.processor,
        surveys: newAccountInfos.surveys,
        documents: newAccountInfos.documents,
        registries: newAccountInfos.registries,
        users: newAccountInfos.users,
      })
    );
    toast.success('Le client a bien été créé');
    handleCloseCreateAccount();
  };

  const handleAccountInfoChange = (name, value) => {
    if (name.target) {
      value = name.target.value;
      name = name.target.name;
    }

    setNewAccountInfos({
      ...newAccountInfos,
      [name]: value,
    });

    handleErrors(name.target ? name.target : { name, value });
  };

  const handleAddUser = () => {
    let usersCopy = _.cloneDeep(newAccountInfos.users);
    usersCopy.push({ email: '', name: '', firstName: '', role: 'admin' });
    setNewAccountInfos({
      ...newAccountInfos,
      users: usersCopy,
    });
    setErrors({
      ...errors,
      [`email_${usersCopy.length}`]: true,
      [`name_${usersCopy.length}`]: true,
      [`firstName_${usersCopy.length}`]: true,
    });
  };

  const handleDeleteUser = index => {
    let usersCopy = _.cloneDeep(newAccountInfos.users);
    usersCopy.splice(index, 1);
    setNewAccountInfos({
      ...newAccountInfos,
      users: usersCopy,
    });
    const errorsCopy = _.cloneDeep(errors);
    const emailKey = `email_${index + 1}`;
    const nameKey = `name_${index + 1}`;
    const firstNameKey = `firstName_${index + 1}`;

    const updatedErrors = { ...errorsCopy };
    delete updatedErrors[emailKey];
    delete updatedErrors[nameKey];
    delete updatedErrors[firstNameKey];

    setErrors(updatedErrors);
  };

  const isAccountOfficerError = !newAccountInfos.users.find(user => user.email === selectedUser?.email);

  const handleErrors = (error, index?: number) => {
    let emailChange = error.name === 'email';
    let emailError = emailChange && isEmailAlreadyUsed(error.value, index);

    let errorName = index ? `${error.name}_${index + 1}` : error.name;
    if (!error.value || emailError) {
      if (!errors.hasOwnProperty(errorName)) {
        setErrors({
          ...errors,
          [errorName]: true,
        });
      }
    } else {
      if (errors.hasOwnProperty(errorName)) {
        let errorsCopy = _.cloneDeep(errors);
        delete errorsCopy[errorName];
        setErrors(errorsCopy);
      }
    }
  };

  const handleUserInfoChange = (e, index) => {
    let usersCopy = _.cloneDeep(newAccountInfos.users);
    usersCopy[index] = {
      ...usersCopy[index],
      [e.target.name]: e.target.value,
    };
    setNewAccountInfos({
      ...newAccountInfos,
      users: usersCopy,
    });
    handleErrors(e.target, index);
  };

  const handleAccountOfficerChange = (name, value) => {
    let newSelectedUser = newAccountInfos.users.find(user => user.email === value);
    setSelectedUser(newSelectedUser);
    let usersCopy = _.cloneDeep(newAccountInfos.users);

    usersCopy.forEach(user => {
      user.role = user.email === value ? 'accountOfficer' : 'admin';
    });
    setNewAccountInfos({
      ...newAccountInfos,
      users: usersCopy,
    });
  };

  const handleCloseCreateAccount = () => {
    setNewAccountInfos(accountInitialState);
    dispatch(setSidenavState(null));
    history.push(paths.admin);
  };

  const isEmailAlreadyUsed = (email, userIndex = null) => {
    if (email === '') return false;
    let emailFound = false;
    allAccounts.forEach(account => {
      if (account.users.some(user => user.email === email)) {
        emailFound = true;
      }
    });
    if (userIndex !== null) {
      if (newAccountInfos.users.filter((user, index) => userIndex !== index).some(user => user.email === email)) {
        emailFound = true;
      }
    }
    return emailFound;
  };
  const getUserOptions = () => {
    let newUsersEmail = newAccountInfos.users?.filter(user => user.email !== '');
    if (newUsersEmail.length === 0) return;
    return newUsersEmail?.map(user => ({
      value: user.email,
      label: user.email,
    }));
  };

  const accountOptions = [
    { label: 'Oui', value: 'yes' },
    { label: 'Non', value: 'no' },
    { label: 'Je ne sais pas', value: `idk` },
  ];

  const accountTypeOptions = [
    { label: 'Administrateur', value: ACCOUNT_TYPES.ADMIN },
    { label: 'Client', value: ACCOUNT_TYPES.CLIENT },
    { label: 'Agence', value: ACCOUNT_TYPES.AGENCY },
  ];

  useEffect(() => {
    setNewAccountInfos(accountInitialState);
    setErrors(errorsInitialState);
  }, [open]);
  return (
    <CreateUserContainer>
      <BrowserAutofillCatcher type='text' name={'email'} />
      <BrowserAutofillCatcher type='password' name={'password'} />
      <TopContainer>
        <Title>Nouveau client</Title>
        <Button
          icon={AiOutlineUserAdd}
          label={'Créer le client'}
          disabled={Object.keys(errors).length !== 0 || isAccountOfficerError}
          onClick={handleCreateAccount}
        />
      </TopContainer>
      <InputGroup>
        <InputComponent
          onChange={handleAccountInfoChange}
          type='text'
          name={'companyName'}
          placeholder={'Nom du client ( Organisation ) '}
          value={newAccountInfos.companyName}
          label={'Nom du client ( Organisation ) '}
          sharable={false}
          error={newAccountInfos.companyName === '' && 'Ce champ ne doit pas être vide'}
        />
        <InputComponent
          onChange={handleAccountInfoChange}
          type='select'
          name={'accountType'}
          options={accountTypeOptions}
          label={'Rôle du nouveau client'}
          sharable={false}
        />
      </InputGroup>
      <ListTop>
        <Label>Liste des utitlisateurs</Label>
        <Add onClick={handleAddUser}>
          <IoMdAddCircleOutline />
          <span>Ajouter un administrateur</span>
        </Add>
      </ListTop>
      <UserListSlider>
        <UserList>
          {newAccountInfos.users.map((user, index) => {
            return (
              <UserGroup key={index}>
                <InputComponent
                  label={`Prénom de l'utilisateur ${index + 1}`}
                  type='text'
                  name={'firstName'}
                  value={user.firstName}
                  onChange={e => handleUserInfoChange(e, index)}
                  sharable={false}
                  labelColor={COLORS.white}
                  placeholder={"Prénom de l'utilisateur"}
                />
                <InputComponent
                  label={`Nom de l'utilisateur ${index + 1}`}
                  type='text'
                  name={'name'}
                  value={user.name}
                  onChange={e => handleUserInfoChange(e, index)}
                  sharable={false}
                  labelColor={COLORS.white}
                  placeholder={"Nom de l'utilisateur"}
                />
                <InputComponent
                  label={`Email ${index + 1}`}
                  type='text'
                  name={'email'}
                  value={user.email}
                  onChange={e => handleUserInfoChange(e, index)}
                  acceptWhiteSpaces={false}
                  sharable={false}
                  labelColor={COLORS.white}
                  placeholder={"Email de l'utilisateur"}
                  error={user.email !== '' && (errors.email || errors[`email_${index + 1}`]) && 'Cet email est déjà utilisé'}
                  errorColor='red'
                />
                <Trash>{index !== 0 && <FiTrash onClick={() => handleDeleteUser(index)} />}</Trash>
              </UserGroup>
            );
          })}
        </UserList>
      </UserListSlider>
      <InputGrid>
        <InputComponent
          onChange={handleAccountOfficerChange}
          type='select'
          name={'role'}
          options={getUserOptions()}
          label={'Quel utilisateur est le référent RGPD?'}
          sharable={false}
          error={isAccountOfficerError && 'Aucun référent RGPD'}
          errorColor='red'
        />
        <InputComponent
          onChange={handleAccountInfoChange}
          type='radio'
          name={'processor'}
          value={newAccountInfos.processor}
          options={accountOptions}
          label={'Le client est-il un sous-traitant ?'}
          sharable={false}
        />
        <InputComponent
          onChange={handleAccountInfoChange}
          type='radio'
          name={'withProduct'}
          options={accountOptions}
          value={newAccountInfos.withProduct}
          label={'Le client a-t-il un produit ?'}
          sharable={false}
        />
        <InputComponent
          onChange={handleAccountInfoChange}
          type='radio'
          name={'cgu'}
          value={newAccountInfos.cgu}
          options={accountOptions}
          label={'L’utilisateur a acheté des CGU/CGV ?'}
          sharable={false}
        />
      </InputGrid>
    </CreateUserContainer>
  );
};

const Title = styled.div`
  font-size: 2.3em;
  color: ${COLORS.Black};
  font-weight: 700;
`;

const Trash = styled.div`
  width: 25px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 25px;
    width: 25px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 20px;
`;

const Add = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  color: ${COLORS.DarkGrey};
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid transparent;
  transition: all 0.2s;

  > * {
    transition: all 0.2s;
  }

  &:hover {
    border: 1px solid ${COLORS.Green};

    span {
      color: ${COLORS.Green};
    }

    svg {
      color: ${COLORS.Green};
    }
  }

  span {
    font-size: 1.125rem;
  }

  svg {
    margin-top: 1px;
    font-size: 1.5rem;
  }
`;

const ListTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  margin-left: 10px;
`;

const UserListSlider = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 150px;
  border: 1px dashed ${COLORS.Green};
  padding: 10px;
  border-radius: 10px 0 0 10px;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const UserGroup = styled.div`
  column-gap: 15px;
  display: flex;
  align-items: flex-start;
  background-color: ${COLORS.Green};
  //border: 1px dashed ${COLORS.Green};
  border-radius: 10px;
  padding: 10px;
  position: relative;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    color: ${COLORS.white};
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${COLORS.MediumGrey};
    }
  }
`;

const InputGroup = styled.div`
  column-gap: 15px;
  display: flex;
  align-items: flex-start;
  padding: 10px;
`;

const BrowserAutofillCatcher = styled.input`
  opacity: 0;
  position: absolute;
  top: -4000px;
`;

const CreateUserContainer = styled.div`
  grid-column: span 5;
  grid-row: span 8;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: calc(100% - 80px);
  row-gap: 15px;
`;
