import styled from 'styled-components';
import { IconType } from 'react-icons';

export interface CounterProps {
  amount: number;
  description: string;
  icon?: IconType;
}

export const Counter = ({ amount, description, icon }: CounterProps) => {
  let Icon = icon;
  return (
    <CounterContainer>
      <Top>
        <Icon />
        <span>{amount}</span>
      </Top>
      <Description>{description}</Description>
    </CounterContainer>
  );
};

const CounterContainer = styled.div`
  width: 135px;
  height: 135px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 26px;
    margin-top: 2px;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;
