import { Administration } from '../administration/Administration';
import { selectActiveAccount, setAccount } from 'src/reducers/account.reducer';
import { useSelector } from 'react-redux';
import TitlePage from '../titlePage/TitlePage';
import { PageContainer, Subtitle, UserAdministrationContainer } from './UserAdministration.styles';
import { useTranslation } from 'react-i18next';

const UserAdministration = () => {
  const { t } = useTranslation();
  const activeAccount = useSelector(selectActiveAccount);

  return (
    <UserAdministrationContainer>
      <PageContainer>
        <TitlePage title={t('administration.title')} />

        <Subtitle>{t('administration.user_list')}</Subtitle>
        <Administration account={activeAccount} setAccount={setAccount} admin={false} />
      </PageContainer>
    </UserAdministrationContainer>
  );
};

export default UserAdministration;
