import styled from 'styled-components';
import { COLORS } from '../../utils/constants';
import { Pie } from '../pie/Pie';
import { Chips } from '../chips/Chips';
import { Button } from '../Button';
import { IconType } from 'react-icons';
export interface DataProps {
  icon: IconType;
  label: string;
  color?: string;
}

export interface ConformityCardProps {
  title;
  data: Array<DataProps>;
  button?: {
    onClick: () => void;
    label: string;
    disabled?: boolean;
  };
  percentage: number;
  status?: string;
}

export const ConformityCard = ({ title, data, button, percentage, status }: ConformityCardProps) => {
  return (
    <ConformityCardContainer>
      <Title>{title}</Title>
      <Content>
        <PieContainer>
          <Pie percent={percentage} color={COLORS.AquaMarine} />
        </PieContainer>
        <DataContainer>
          {status && <Chips label={status} status={status} />}
          {data.map((item, index) => {
            let Icon = item.icon;
            return (
              <Data key={index}>
                <Icon color={item.color} />
                <Label>{item.label}</Label>
              </Data>
            );
          })}
          {button && <Button label={button.label} onClick={button.onClick} disabled={button.disabled} />}
        </DataContainer>
      </Content>
    </ConformityCardContainer>
  );
};

const ConformityCardContainer = styled.div`
  background-color: ${COLORS.darkBlue};
  width: fit-content;
  padding: 30px;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'nunito';
  height: calc(100% - 60px);
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'nunito', serif;
  font-weight: 700;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
  flex: 1;
`;

const PieContainer = styled.div`
  flex: 1;
  height: 150px;
  width: 150px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
`;

const Data = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 18px;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ color }) => color || 'white'};
  }
`;

const Label = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-family: 'nunito', serif;
`;
