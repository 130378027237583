import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Tippy from '@tippyjs/react';

import { MenuItem, Avatar, Checkbox, TextField } from '@mui/material';

import { AiOutlineFileAdd } from 'react-icons/ai';
import { BiDuplicate } from 'react-icons/bi';
import { VscDebugStepOver } from 'react-icons/vsc';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { PiListMagnifyingGlassDuotone } from 'react-icons/pi';
import { MdModeEdit, MdOutlineDeleteForever } from 'react-icons/md';

import { Chip, Container, FilesIcons } from '@components-new/atoms';
import { FiltersMenu, PageWrapper } from '@components-new/molecules';
import { DataTable } from '@components-new/organisms';

import { dataTableColumnsProps } from '@components-new/common/types';
import { actionsStatus, actionsUrgencyComplexity } from '@components-new/common/constants';

// TODO : to be moved to UI lib
import ActionDetails from './components/ActionDetails';

import { FileExplorer } from '@components/file-explorer/FileExplorer';
import { Modal } from '@components/modal/Modal';
import { Button } from '@components/Button';

import { selectUserActions } from 'src/reducers/contentful.reducer';
import { setLoading } from 'src/reducers/app.reducer';
import {
  updateActionPlan,
  createCustomAction,
  selectUser,
  selectSelectedAccount,
  selectActiveAccount,
  selectImpersonated,
  selectEntityActions,
  clearEntityActions,
  fetchEntityActions,
  editCustomAction,
  deleteCustomAction,
} from 'src/reducers/account.reducer';

import useDidUpdate from 'src/hooks/useDidUpdate';
import {
  compareByStatus,
  compareByUrgency,
  compareQueryToActionName,
  getActionDetail,
  getActionProductName,
  getAuthorizedProducts,
  getFileNameFromKey,
  getResponsibleName,
  getVersionIdByName,
  getVersionName,
  processAnswersForAudits,
  retrieveSurveysResponses,
  stringAvatar,
  verifyResponsesMatchConditions,
} from '@utils/utils';

import { ActionProps, CustomActionProps, EntityProps, FiltersProps } from './ActionPlan.types';
import { category, urgencyComplexity, status, customActionInitialState, filtersInitialState } from './ActionPlan.constants';

import {
  AvatarContainer,
  LinkField,
  ErasePlaceholder,
  ActionContainer,
  ModalContent,
  AdjustActions,
  ButtonsContainer,
  FloatingActionBar,
  CheckedElements,
  StyledInputComponent,
  ConfirmContainer,
} from './ActionPlan_.style';
import { checkCustomActionFields, getItemIcon, isAnyNewComment, noTableDataAvailable } from './ActionPlan.utils';


const AdminActionPlan = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<any>();
  const impersonated = useSelector(selectImpersonated);
  const currentUser = useSelector(selectUser);
  const selectedAccount = useSelector(selectSelectedAccount);
  const activeAccount = useSelector(selectActiveAccount);
  const userActions = useSelector(selectUserActions);
  const entityActions = useSelector(selectEntityActions);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [view, setView] = useState({ label: t('views.backlog'), value: 'backlog' });
  const [query, setQuery] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<ActionProps>({});
  const [editedAction, setEditedAction] = useState<ActionProps>({});
  const [selectedEntity, setSelectedEntity] = useState<EntityProps>(null);
  const [selectAllActions, setSelectAllActions] = useState<boolean>(false);
  const [customAction, setCustomAction] = useState<CustomActionProps>(customActionInitialState);
  const [filters, setFilters] = useState<FiltersProps>(filtersInitialState);
  const [filtersMenAnchorEl, setFiltersMenAnchorEl] = useState<null | HTMLElement>(null);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showCustomActionModal, setShowCustomActionModal] = useState<boolean>(false);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({ action: undefined, show: false, context: undefined });
  const [selectedDrawerItemID, setSelectedDrawerItemID] = useState<string | null>(null);
  const [checkedActions, setCheckedActions] = useState<Array<ActionProps>>([]);
  const [editAction, setEditAction] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<ActionProps | null>(null);

  const deleteDocumentRef = useRef<HTMLDivElement>(null);

  const views = useMemo(() => {
    return [
      {
        label: t('views.backlog'),
        value: 'backlog',
        onClick: () => {
          setView({ label: t('views.backlog'), value: 'backlog' });
        },
      },
      {
        label: t('views.prepared_actions'),
        value: 'actionsPrepared',
        onClick: () => {
          setView({ label: t('views.prepared_actions'), value: 'actionsPrepared' });
        },
      },
      {
        label: t('views.action_plan'),
        value: 'adminActions',
        onClick: () => {
          setView({ label: "Plan d'actions", value: 'adminActions' });
        },
      },
    ];
  }, []);

  const handleFilterChange = (selectedOptions, name) => {
    setFilters(prevState => ({ ...prevState, [name]: selectedOptions }));
  };

  const handleFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
    setFiltersMenAnchorEl(event.currentTarget);
  };

  const handleFiltersReset = () => {
    setFilters({ urgency: [], status: [], category: [], product: [], complexity: [] });
  };

  const filteredData = data => {
    return (
      data
        ?.filter((item: ActionProps) => {
          switch (view.value) {
            case 'backlog':
              return !item.draft && !item.active;
            case 'actionsPrepared':
              return item.draft && !item.active;
            case 'adminActions':
              return !['Demande', 'Audit'].includes(item.category) && item.active === true;
            case 'requests':
              return item.category === 'Demande';
            default:
              return true; // Include all items if view is not recognized
          }
        })
        // Apply search filter
        .filter((item: ActionProps) => {
          if (item.category === 'Audit' && !item.custom) {
            const title = t('actionPlan.audit_action', { name: item?.subContractor?.name });
            return compareQueryToActionName(title, query);
          }
          return compareQueryToActionName(item.title, query);
        })
        .filter((item: ActionProps) => {
          const { urgency, status, category, product, complexity } = filters;
          // Apply urgency filter
          if (urgency.length > 0 && !urgency.some(option => option.value === item.urgency)) {
            return false;
          }
          // Apply status filter
          if (status.length > 0 && !status.some(option => option.value === item.status)) {
            return false;
          }
          // Apply category filter
          if (category.length > 0 && !category.some(option => option.value === item.category)) {
            return false;
          }
          // Apply product filter
          if (
            product.length > 0 &&
            !product.some(option => {
              if (item.isEntityScoop) {
                return option.value === item.versionProductId;
              } else {
                if (item.versionProductId) {
                  return option.value === item.versionProductId;
                }
                if (item.versionProductName && !item.versionProductId) {
                  const versionId = getVersionIdByName(selectedEntity?._id, item.relatedProductId, item.versionProductName, true);
                  return option.value === versionId;
                }
              }
              return false;
            })
          ) {
            return false;
          }
          // Apply complexity filter
          if (complexity.length > 0 && !complexity.some(option => option.value === item.complexity)) {
            return false;
          }
          return true;
        })
        .sort(compareByUrgency)
        .sort(compareByStatus)
    );
  };

  const rawAdminData = useMemo(() => {
    if (!selectedEntity) return [];
    const { versSurveysAnswers, nonVersSurveysAnswers } = retrieveSurveysResponses(selectedAccount, selectedEntity);
    const entityLevelActions = [];
    const contentActions = userActions?.reduce((result, action) => {
      if (action.category === 'Audit') {
        if (versSurveysAnswers.length > 0) {
          result = result.concat(processAnswersForAudits(action, versSurveysAnswers, true));
        }
        if (nonVersSurveysAnswers) {
          result = result.concat(processAnswersForAudits(action, nonVersSurveysAnswers, false, selectedEntity._id));
        }
      } else if (versSurveysAnswers.length > 0) {
        versSurveysAnswers.forEach(elt => {
          const { isMatchConditions, isEntityScoop } = verifyResponsesMatchConditions(action.conditions, elt.responses, nonVersSurveysAnswers);
          if (isMatchConditions && isEntityScoop) {
            const exists = entityLevelActions.some(item => item.id === action.id);
            if (!exists) {
              entityLevelActions.push({
                ...action,
                isEntityScoop,
                versionProductId: selectedEntity._id,
              });
            }
          }
          if (elt.responses && isMatchConditions && !isEntityScoop) {
            const data = {
              ...elt,
            };
            delete data['responses'];
            result.push({
              ...action,
              ...data,
            });
          }
        });
      } else if (nonVersSurveysAnswers) {
        const { isMatchConditions, isEntityScoop } = verifyResponsesMatchConditions(action.conditions, null, nonVersSurveysAnswers);
        if (isMatchConditions && isEntityScoop) {
          const exists = entityLevelActions.some(item => item.id === action.id);
          if (!exists) {
            entityLevelActions.push({
              ...action,
              isEntityScoop,
              versionProductId: selectedEntity._id,
            });
          }
        }
      }

      return result;
    }, []);
    // add also the entity level actions
    contentActions.push(...entityLevelActions);
    const mergedActions = entityActions?.reduce((result, dbAction) => {
      const correspondingContentAction = contentActions.find(contentAction => {
        if (!contentAction.isEntityScoop) {
          if (contentAction.category === 'Audit') {
            return (
              contentAction.id === dbAction?.id &&
              contentAction.relatedProductId === dbAction.relatedProductId &&
              contentAction.versionProductId === dbAction.versionProductId &&
              contentAction.subContractor.name === dbAction.subContractor.name
            );
          }
          return (
            contentAction.id === dbAction?.id &&
            contentAction.relatedProductId === dbAction.relatedProductId &&
            contentAction.versionProductId === dbAction.versionProductId
          );
        } else {
          if (contentAction.category === 'Audit') {
            return (
              contentAction.id === dbAction?.id &&
              contentAction.versionProductId === dbAction.versionProductId &&
              contentAction.subContractor.name === dbAction.subContractor.name
            );
          }
          return dbAction.id === contentAction.id && dbAction.versionProductId === contentAction.versionProductId;
        }
      });

      if (correspondingContentAction) {
        // If there is a corresponding action in contentActions, merge the data
        result.push({
          ...correspondingContentAction,
          ...dbAction,
        });
      } else {
        // If there is no corresponding action in contentActions, use the data from actionsDB
        result.push({ ...dbAction, productName: getActionProductName(dbAction.relatedProductId) });
      }

      return result;
    }, []);

    // Add any remaining actions from contentActions that were not found in actionsDB
    const remainingActions = contentActions?.filter(
      contentAction =>
        !entityActions?.some(dbAction => {
          if (!dbAction.isEntityScoop) {
            if (contentAction.category === 'Audit') {
              return (
                contentAction.id === dbAction?.id &&
                contentAction.relatedProductId === dbAction.relatedProductId &&
                contentAction.versionProductId === dbAction.versionProductId &&
                contentAction.subContractor.name === dbAction.subContractor.name
              );
            }
            return (
              contentAction.id === dbAction?.id &&
              contentAction.relatedProductId === dbAction.relatedProductId &&
              contentAction.versionProductId === dbAction.versionProductId
            );
          }
          if (contentAction.category === 'Audit') {
            return (
              contentAction.id === dbAction?.id &&
              contentAction.versionProductId === dbAction.versionProductId &&
              contentAction.subContractor.name === dbAction.subContractor.name
            );
          }
          return dbAction.id === contentAction.id && dbAction.versionProductId === contentAction.versionProductId;
        })
    );
    mergedActions?.push(...remainingActions);

    return mergedActions;
  }, [selectedEntity, userActions, entityActions]);

  const adminData = useMemo(() => {
    setSelectAllActions(false);
    setCheckedActions([]);
    if (selectedEntity && rawAdminData?.length) {
      return filteredData(rawAdminData);
    }
    return [];
  }, [rawAdminData, filters, view, query]);

  useEffect(() => {
    if (selectAllActions) {
      setCheckedActions([...adminData]);
    } else {
      setCheckedActions([]);
    }
  }, [selectAllActions]);

  useDidUpdate(async () => {
    if (entityActions) dispatch(clearEntityActions());
    if (selectedEntity?._id) {
      if (!isDataLoading) setIsDataLoading(true);
      let data = { accountId: null, entityId: selectedEntity?._id };
      if (impersonated?._id) {
        data.accountId = impersonated?._id;
      } else if (selectedAccount?._id) {
        data.accountId = selectedAccount?._id;
      } else if (activeAccount?._id) {
        data.accountId = activeAccount?._id;
      }
      await dispatch(fetchEntityActions(data));
      setIsDataLoading(false);
    }
  }, [selectedEntity?._id]);

  // Handle Display Drawer in case the url contains the action info
  // @ts-ignore
  const { id } = useParams();

  const actionId = id?.split('|')[0];
  const relatedProductId = id?.split('|')[1];
  const entityId = id?.split('|')[2];
  const versionProductId = id?.split('|')[3];
  const selectedDrawerID = id?.split('|')[4];

  useEffect(() => {
    if (actionId && relatedProductId && entityId && versionProductId) {
      const actionDetail = getActionDetail(entityId, actionId, relatedProductId, versionProductId);
      const entity = activeAccount?.entities?.find(entity => entity._id === entityId);
      if (selectedDrawerID !== 'chat') {
        setSelectedDrawerItemID('detail');
      } else {
        setSelectedDrawerItemID(selectedDrawerID);
      }
      if (actionDetail.active && entity) {
        setSelectedEntity(entity);
        setSelectedAction(actionDetail);
        setEditedAction(actionDetail);
        setShowDrawer(true);
      }
    }
  }, [actionId, relatedProductId, entityId, versionProductId]);

  //
  // Action handlers
  //

  const handleCheckedActions = (event, action, type?, isEntityScoop: boolean = false) => {
    const { id, relatedProductId, versionProductId, subContractor } = action;

    const updatedAdminFiltredActions = [...adminData];
    const itemIndex = updatedAdminFiltredActions.findIndex(
      isEntityScoop
        ? item => item.id === id && item.versionProductId === selectedEntity?._id
        : action.category === 'Audit'
          ? item =>
            item.id === id &&
            item.relatedProductId === relatedProductId &&
            item.versionProductId === versionProductId &&
            item.subContractor.name === subContractor.name
          : item => item.id === id && item.relatedProductId === relatedProductId && item.versionProductId === versionProductId
    );

    if (itemIndex !== -1) {
      if (type === 'link') {
        const { link } = updatedAdminFiltredActions[itemIndex];
        if (event.target.value !== null && event.target.value !== '') {
          if (link !== '' && link !== null && link !== undefined) {
            const linkArray = link.split(';');
            updatedAdminFiltredActions[itemIndex].link = linkArray.includes(event.target.value)
              ? linkArray.filter(link => link !== event.target.value).join(';')
              : linkArray.concat(event.target.value).join(';');
          } else {
            updatedAdminFiltredActions[itemIndex].link = event.target.value;
          }
        } else {
          updatedAdminFiltredActions[itemIndex].link = '';
        }
      }
    } else {
      updatedAdminFiltredActions.push({
        ...(isEntityScoop
          ? {
            isEntityScoop: true,
            versionProductId: selectedEntity?._id,
          }
          : {
            relatedProductId,
            versionProductId,
            versionProductName: getVersionName(selectedEntity._id, relatedProductId, versionProductId),
          }),
        ...action,
        link: type === 'link' ? event.target.value : null,
      });
    }
    dispatch(setLoading(true));

    dispatch(updateActionPlan({ accountId: selectedAccount._id, entityId: selectedEntity._id, actions: updatedAdminFiltredActions }));

    dispatch(setLoading(false));
  };

  const getCheckedActions = action => {
    const checkedAction = checkedActions.find(checkedAction => {
      if (action.category === 'Audit') {
        return (
          checkedAction.id === action.id &&
          checkedAction.relatedProductId === action.relatedProductId &&
          checkedAction.versionProductId === action.versionProductId &&
          checkedAction.subContractor.name === action.subContractor.name
        );
      }
      return (
        checkedAction.id === action.id &&
        checkedAction.relatedProductId === action.relatedProductId &&
        checkedAction.versionProductId === action.versionProductId
      );
    });
    return checkedAction ? true : false;
  };

  const handleShowActionDetail = (task, ItemID) => {
    const { id, relatedProductId, versionProductId } = task;
    const targetTask = adminData.find(action => {
      return relatedProductId
        ? action.id === id && action.relatedProductId === relatedProductId && action.versionProductId === versionProductId
        : action.id === id && action.versionProductId === selectedEntity._id.toString();
    });

    if (showDrawer && selectedAction) {
      setSelectedAction(null);
      setEditedAction(null);
    } else {
      setSelectedAction(targetTask);
      setEditedAction(targetTask);
      setSelectedDrawerItemID(ItemID);
    }
    setShowDrawer(prevState => !prevState);
  };

  const handleUpdateAuthorizedActions = upgrade => {
    dispatch(setLoading(true));
    const actions = checkedActions.map(elt => {
      const contentfulActionDetails = userActions.find(action => action.id === elt.id);
      const updatedAction = {
        ...(elt.isEntityScoop
          ? {
            isEntityScoop: true,
            versionProductId: selectedEntity?._id,
            relatedProductId,
          }
          : {
            relatedProductId,
            versionProductId,
            versionProductName: getVersionName(selectedEntity._id, relatedProductId, versionProductId),
          }),
        ...elt,
      };
      const action = { ...contentfulActionDetails, ...updatedAction };
      const propertiesToDelete = ['responsible', 'sentMails', 'createdAt', 'conditions'];
      for (const property of propertiesToDelete) {
        delete action?.[property];
      }
      // Delete special data for table => attributes that ends with 'Col' => prevent unwanted action data modification
      Object.keys(action).forEach(key => {
        if (key.endsWith('Col')) {
          delete action[key];
        }
      });
      return action;
    });

    dispatch(updateActionPlan({ accountId: selectedAccount._id, entityId: selectedEntity._id, actions, upgrade: upgrade }));

    dispatch(setLoading(false));
  };

  //
  // Custom Action handlers
  //

  const handleCustomDataChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setCustomAction(prevState => {
      let updatedState;

      if (name === 'versionProductId') {
        if (value === selectedEntity._id) {
          updatedState = {
            ...prevState,
            [name]: value,
            isEntityScoop: true,
          };
          if (updatedState.relatedProductId) delete updatedState.relatedProductId;
        } else {
          const actionProductDetails = getProductsOptions()?.find(elt => elt.value === value);
          if (name === 'versionProductId' && actionProductDetails) {
            const { product, label, value } = actionProductDetails;
            updatedState = {
              ...prevState,
              relatedProductId: product,
              versionProductName: label,
              versionProductId: value,
            };
          }
        }
      } else {
        updatedState = {
          ...prevState,
          [name]: value,
        };
      }

      return {
        ...updatedState,
        isReady: checkCustomActionFields(updatedState),
      };
    });
  };

  const handleCreateCustomAction = () => {
    dispatch(setLoading(true));
    delete customAction.isReady;
    dispatch(createCustomAction({ accountId: selectedAccount._id, entityId: selectedEntity._id, action: customAction }));
    dispatch(setLoading(false));
    setShowCustomActionModal(prevState => !prevState);
    setCustomAction({
      title: '',
      relatedProductId: '',
      description: '',
      complexity: '',
      urgency: '',
      conditions: [],
      category: '',
      custom: true,
      isReady: false,
      proof: {},
    });
  };

  const handleEditCustomAction = () => {
    dispatch(setLoading(true));
    delete customAction.isReady;
    dispatch(editCustomAction({ entityId: selectedEntity._id, action: customAction }));
    dispatch(setLoading(false));
    setShowCustomActionModal(prevState => !prevState);
    setCustomAction({
      title: '',
      relatedProductId: '',
      description: '',
      complexity: '',
      urgency: '',
      conditions: [],
      category: '',
      custom: true,
      isReady: false,
    });
    setEditAction(false);
  };

  const handleDeleteCustomAction = () => {
    dispatch(
      deleteCustomAction({
        entityId: selectedEntity._id,
        actionId: deleteAction.id,
        relatedProductId: deleteAction.relatedProductId,
        versionProductId: deleteAction.versionProductId,
      })
    );
    setDeleteAction(null);
  };

  const getProductsOptions = () => {
    const products = [];
    const productsList = getAuthorizedProducts()
      .filter(product => product.versioning.enabled)
      .map(product => product.id);
    selectedAccount.entities
      .find(entity => entity._id === selectedEntity?._id)
      ?.responses?.forEach(response => {
        return (
          productsList.includes(response.surveyId) &&
          response?.versions?.forEach(version =>
            products.push({
              label: version?.versionName,
              value: version?._id,
              product: response.surveyId,
            })
          )
        );
      });
    return products;
  };

  //
  // Utils
  //

  const getActionLink = (action: ActionProps) => {
    return (
      checkedActions?.find(checkedAction => {
        if (checkedAction.isEntityScoop) {
          return checkedAction.id === action.id && checkedAction.versionProductId === action.versionProductId;
        } else {
          return (
            checkedAction.id === action.id &&
            checkedAction.versionProductId === action.versionProductId &&
            checkedAction.relatedProductId === action.relatedProductId
          );
        }
      })?.link || action?.link
    );
  };

  const handleDuplicateAction = (action: ActionProps) => {
    const { title, description, complexity, urgency, category, explanation, precision, conditions, draft, proof } = action;

    const newProof = { required: proof?.required };

    setCustomAction({
      title,
      description,
      complexity,
      urgency,
      category,
      precision,
      explanation,
      conditions,
      custom: true,
      isReady: false,
      draft,
      proof: newProof,
    });
    setShowCustomActionModal(true);
  };

  const handleEditAction = (action: ActionProps) => {
    const { id, title, description, complexity, urgency, category, explanation, precision, conditions, draft, versionProductId, relatedProductId } =
      action;
    setEditAction(true);
    setCustomAction({
      id,
      title,
      description,
      complexity,
      urgency,
      category,
      precision,
      explanation,
      conditions,
      versionProductId,
      relatedProductId,
      custom: true,
      isReady: false,
      draft,
    });
    setShowCustomActionModal(true);
  };

  const handleSelectActions = action => {
    const actionIndex = checkedActions.findIndex(checkedAction => {
      if (action.category === 'Audit') {
        return (
          checkedAction.id === action.id &&
          checkedAction.relatedProductId === action.relatedProductId &&
          checkedAction.versionProductId === action.versionProductId &&
          checkedAction.subContractor.name === action.subContractor.name
        );
      }
      return (
        checkedAction.id === action.id &&
        checkedAction.relatedProductId === action.relatedProductId &&
        checkedAction.versionProductId === action.versionProductId
      );
    });

    if (actionIndex !== -1) {
      const actions = [...checkedActions];
      actions.splice(actionIndex, 1);
      setCheckedActions(actions);
    } else {
      setCheckedActions(prevCheckedActions => [...prevCheckedActions, action]);
    }
  };

  //
  // handle focus on link input
  //
  const handleFocusLinkInput = (e, action, context = undefined) => {
    e.stopPropagation();
    setShowAvailableDocuments({ action, show: true, context });
  };

  const handleFileChoice = fileKey => {
    if (showAvailableDocuments.context === 'table') {
      handleCheckedActions(
        { target: { checked: true, value: fileKey } },
        showAvailableDocuments.action,
        'link',
        showAvailableDocuments.action.isEntityScoop
      );
    } else {
      handleCustomDataChange({ target: { name: 'link', value: fileKey } });
    }
    setShowAvailableDocuments({ action: undefined, show: false, context: undefined });
  };

  const handleCustomActionModalClose = e => {
    setCustomAction(customActionInitialState);
    setShowCustomActionModal(false);
    setEditAction(false);
  };

  const adminColumns: dataTableColumnsProps[] = [
    {
      field: 'titleCol',
      headerName: t('common.action'),
      width: 'col-1-5',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'product',
      headerName: t('common.product_entity'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'statusCol',
      headerName: t('common.status'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'dueDateCol',
      headerName: t('common.due_date'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'urgencyCol',
      headerName: t('common.criticality'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'complexityCol',
      headerName: t('common.complexity'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'categoryCol',
      headerName: t('common.category'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'responsibleCol',
      headerName: t('common.responsible'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'rounded', width: 100 },
    },
    {
      field: 'documentCol',
      headerName: t('common.document'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'rounded', width: 80 },
    },
    {
      field: 'proofCol',
      headerName: t('common.proof'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'rounded', width: 80 },
      renderCell: params => {
        return params.row.proof && params.row.proof.required && (params.row.proof.link || params.row.proof.documents) ? (
          <PiListMagnifyingGlassDuotone size={25} />
        ) : null;
      },
    },
  ];

  const filteredAdminColumns = view.value === 'adminActions' ? adminColumns : adminColumns.filter(col => col.field !== 'proofCol');

  const adminRowAction = useMemo(
    () =>
      view.value !== 'adminActions'
        ? [
          {
            icon: BiDuplicate,
            label: t('common.duplicate'),
            onClick: row => {
              handleDuplicateAction(row);
            },
            showInMenu: false,
            isDisabled: row => row.category === 'Audit',
          },
          {
            icon: MdModeEdit,
            label: t('common.edit'),
            onClick: row => {
              handleEditAction(row);
            },
            showInMenu: false,
            isDisabled: row => !row.custom,
          },
          {
            icon: MdOutlineDeleteForever,
            label: t('common.delete'),
            onClick: row => {
              setDeleteAction(row);
            },
            showInMenu: false,
            isDisabled: row => !row.custom,
          },
        ]
        : [],
    [view]
  );

  const adminRows = useMemo(() => {
    if (selectedEntity) {
      return adminData.map(action => {
        return {
          ...action,
          titleCol:
            action.category === 'Audit'
              ? action.custom
                ? action?.title
                : t('actionPlan.audit_action', { name: action?.subContractor?.name })
              : action?.title,
          product: action.isEntityScoop ? selectedEntity.name : action.versionProductName,
          checkbox: () => {
            return (
              <Checkbox
                checked={getCheckedActions(action)}
                onClick={e => {
                  e.stopPropagation();
                }}
                onChange={e => handleSelectActions(action)}
              />
            );
          },
          statusCol: () => {
            const { label, color, icon } = actionsStatus[action.status] || {}
            return <Chip variant='text' label={t(label) || t('common.not_activated')} color={color} icon={icon} />;
          },
          dueDateCol: action.dueDate ? dayjs(action.dueDate, 'DD-MM-YYYY').format('DD-MM-YYYY').toString() : '-- / -- / ----',
          urgencyCol: () => {
            const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.urgency]
            return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
          },
          complexityCol: () => {
            const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.complexity]
            return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
          },
          categoryCol: () => {
            return <Chip label={action?.category} />
          },
          responsibleCol: () => {
            return (
              <AvatarContainer max={2}>
                {action?.responsible?.map((responsible, index) => (
                  <Avatar key={index} {...stringAvatar(getResponsibleName(responsible?.email).toUpperCase(), responsible.id)} />
                ))}
              </AvatarContainer>
            );
          },
          documentCol: () => {
            const actionLink = getActionLink(action);
            return (
              <LinkField type={actionLink?.split('.')?.pop()}>
                {(action.draft || action.active) && (actionLink === '' || actionLink === undefined || actionLink === null) && (
                  <div className='col-0-5'>
                    <AiOutlineFileAdd className={'add'} onClick={e => handleFocusLinkInput(e, action, 'table')} />
                    <ErasePlaceholder />
                  </div>
                )}
                {actionLink !== '' && actionLink !== null && actionLink !== undefined && (
                  <>
                    {actionLink.includes(';') ? (
                      <FilesIcons
                        filesType={actionLink
                          ?.split(';')
                          ?.filter(link => link !== '')
                          ?.map(link => link.split('.')?.pop())}
                        max={2}
                        onClick={e => {
                          action.link && handleFocusLinkInput(e, action, 'table');
                        }}
                      />
                    ) : (
                      <div>
                        {getItemIcon(actionLink?.split('.')?.pop(), e => {
                          action.link && handleFocusLinkInput(e, action, 'table');
                        })}
                      </div>
                    )}

                    {!action.active && action.link && (
                      <div ref={deleteDocumentRef}>
                        <RiDeleteBin2Line
                          size={21}
                          className={'erase'}
                          color='red'
                          onClick={e => {
                            e.stopPropagation();
                            handleCheckedActions({ target: { checked: true, value: '' } }, action, 'link');
                          }}
                        />
                        <Tippy content='Supprimer les docs' reference={deleteDocumentRef} placement='bottom' />
                      </div>
                    )}
                  </>
                )}
              </LinkField>
            );
          },
        };
      });
    }
    return [];
  }, [selectedEntity, adminData]);

  const filtersCount = useMemo(() => {
    return Object.keys(filters).reduce((acc, key) => {
      const filterArray = filters[key];
      if (Array.isArray(filterArray) && filterArray.length > 0) {
        acc += filterArray.length;
      }
      return acc;
    }, 0);
  }, [filters]);

  const getEntitiesOptions = () => {
    return selectedAccount?.entities?.map(entity => ({
      label: entity.name,
      value: entity,
    }));
  };

  useEffect(() => {
    if (getEntitiesOptions().length === 1) {
      setLoading(true);
      setSelectedEntity(getEntitiesOptions()[0].value);
    }
  }, []);

  const getProductFilterItems = () => {
    const products = getProductsOptions();
    products.push({
      label: selectedEntity?.name,
      value: selectedEntity?._id,
    });
    return products;
  };

  const filtersMenuItems = [
    {
      targetFilter: 'product',
      placeholder: t('common.product'),
      value: filters.product,
      options: getProductFilterItems(),
    },
    {
      targetFilter: 'urgency',
      placeholder: t('common.urgency'),
      value: filters.urgency,
      options: urgencyComplexity,
    },
    {
      targetFilter: 'status',
      placeholder: t('common.status'),
      value: filters.status,
      options: status,
    },
    {
      targetFilter: 'category',
      placeholder: t('common.category'),
      value: filters.category,
      options: category,
    },
    {
      targetFilter: 'complexity',
      placeholder: t('common.complexity'),
      value: filters.complexity,
      options: urgencyComplexity,
    },
  ];

  const getSelectedEntity = () => {
    if (selectedEntity) {
      return { label: selectedEntity.name, value: selectedEntity };
    } else if (getEntitiesOptions().length === 1) {
      return getEntitiesOptions()[0];
    }
    return null;
  };

  return (
    <PageWrapper selectedView={view} views={views} showPageTitle={false}>
      <Container flex justifyContent='flex-end' position='absolute' right='2rem'>
        {selectedEntity && view.value === 'backlog' && (
          <ActionContainer>
            <Button
              onClick={e => {
                e.preventDefault();
                setShowCustomActionModal(true);
              }}
              label={t('actionPlan.add_custom_action')}
              icon={IoMdAddCircleOutline}
            />
          </ActionContainer>
        )}
      </Container>

      {/* Admin Action Data */}
      <DataTable
        loading={isDataLoading}
        rows={adminRows}
        columns={filteredAdminColumns}
        rowActions={adminRowAction}
        onRowClick={action => handleShowActionDetail(action, 'detail')}
        checkboxSelection
        allItemsSelection={selectAllActions}
        setAllItemsSelection={setSelectAllActions}
        checkRowSelection={getCheckedActions}
        handleRowSelection={handleSelectActions}
        searchPlaceholder={t('actionPlan.search_actions')}
        searchQuery={query}
        setSearchQuery={setQuery}
        onFilterClick={handleFiltersClick}
        onFilterReset={handleFiltersReset}
        filtersCount={filtersCount}
        noDataText={noTableDataAvailable(selectedEntity, view.value)}
        disableFilters={!selectedEntity}
        disableSearchBar={!selectedEntity}
        headerComponents={[
          {
            component: (
              <Container flex width='20rem' justifyContent='space-between'>
                <StyledInputComponent
                  placeholder={t('actionPlan.choose_entity')}
                  type={'select'}
                  options={getEntitiesOptions()}
                  onChange={(_, value) => setSelectedEntity(value)}
                  sharable={false}
                  defaultValue={getSelectedEntity()}
                  noOptionsMessage={() => t('actionPlan.no_entity_available')}
                />
              </Container>
            ),
            placement: 1,
          },
        ]}
      />
      {/* Action details Drawer */}
      <ActionDetails
        selectedAccount={selectedAccount}
        impersonated={impersonated}
        isAnyNewComment={isAnyNewComment}
        selectedAction={selectedAction}
        activeAccount={activeAccount}
        setSelectedAction={setSelectedAction}
        selectedEntity={selectedEntity}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedDrawerItemID={selectedDrawerItemID}
        currentUser={currentUser}
        admin={true}
        editedAction={editedAction}
        setShowSubcontractorInputModal={null}
      />

      {/* Custom Action Creation Modal */}
      {showCustomActionModal && (
        <Modal
          show={showCustomActionModal}
          title={editAction ? t('actionPlan.modal.edit_custom_action_title') : t('actionPlan.modal.create_custom_action_title')}
          onClose={handleCustomActionModalClose}>
          <ModalContent>
            <TextField
              label={t('common.title')}
              variant='outlined'
              name='title'
              type='search'
              fullWidth
              defaultValue={customAction?.title}
              onChange={handleCustomDataChange}
              required
            />
            <AdjustActions>
              <TextField
                label={t('common.product_entity')}
                variant='outlined'
                name='versionProductId'
                fullWidth
                select
                defaultValue={editAction ? customAction?.relatedProductId || customAction?.versionProductId : null}
                onChange={handleCustomDataChange}
                required>
                <MenuItem disabled>{t('common.product')}</MenuItem>
                {getProductsOptions().map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                <MenuItem disabled>{t('common.entity')}</MenuItem>
                <MenuItem key={'entity'} value={selectedEntity._id}>
                  {selectedEntity.name}
                </MenuItem>
              </TextField>
              <TextField
                label={t('common.category')}
                variant='outlined'
                name='category'
                type='search'
                select
                fullWidth
                defaultValue={customAction?.category}
                onChange={handleCustomDataChange}
                required>
                {category
                  .filter(categoryItem => categoryItem.value !== 'Audit')
                  .map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </AdjustActions>

            <AdjustActions>
              <TextField
                label={t('common.urgency')}
                variant='outlined'
                name='urgency'
                select
                fullWidth
                defaultValue={customAction?.urgency}
                onChange={handleCustomDataChange}
                required>
                {urgencyComplexity.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t('common.complexity')}
                variant='outlined'
                name='complexity'
                select
                fullWidth
                defaultValue={customAction?.complexity}
                onChange={handleCustomDataChange}
                required>
                {urgencyComplexity.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </AdjustActions>

            <TextField
              // defaultValue={''}
              value={getFileNameFromKey(customAction?.link) ?? ''}
              label={t('common.document_link')}
              variant='outlined'
              name='link'
              type='search'
              fullWidth
              onFocus={e => handleFocusLinkInput(e, customAction)}
            />

            <TextField
              label={t('common.description')}
              variant='outlined'
              name='description'
              multiline
              rows={3}
              fullWidth
              defaultValue={customAction?.description}
              onChange={handleCustomDataChange}
              required
            />
            <TextField
              label={t('actionPlan.modal.integration_explanation')}
              variant='outlined'
              name='explanation'
              multiline
              rows={2}
              fullWidth
              defaultValue={customAction?.explanation}
              onChange={handleCustomDataChange}
            />
            <TextField
              label={t('common.precision')}
              variant='outlined'
              name='precision'
              multiline
              rows={2}
              fullWidth
              defaultValue={customAction?.precision}
              onChange={handleCustomDataChange}
            />
          </ModalContent>
          <ButtonsContainer>
            <Button
              disabled={!editAction && !customAction?.isReady}
              onClick={editAction ? handleEditCustomAction : handleCreateCustomAction}
              label={t('common.validate')}
            />
            <Button onClick={handleCustomActionModalClose} label={t('common.cancel')} type='light' />
          </ButtonsContainer>
        </Modal>
      )}
      {showAvailableDocuments?.show && (
        <Modal
          size={{ height: '100%' }}
          show={showAvailableDocuments?.show}
          title={t('actionPlan.available_documents')}
          onClose={() => setShowAvailableDocuments({ action: undefined, show: false, context: undefined })}>
          <FileExplorer
            embedded
            selectedEntity={selectedEntity}
            customClick={handleFileChoice}
            selectedFileKey={showAvailableDocuments?.action?.link}
            admin
          />
        </Modal>
      )}
      {checkedActions.length > 0 && (
        <FloatingActionBar>
          <CheckedElements>{checkedActions.length} action(s) sélectionné(s)</CheckedElements>
          <Container flex mrChild='1rem'>
            {view.value === 'backlog' ? (
              <ActionContainer>
                <Button
                  onClick={() => handleUpdateAuthorizedActions('upgrade')}
                  label={t('actionPlan.transfer_to_prepared_actions')}
                  customColor='#36B894'
                  icon={VscDebugStepOver}
                />
              </ActionContainer>
            ) : view.value === 'actionsPrepared' ? (
              <>
                <ActionContainer>
                  <Button onClick={() => handleUpdateAuthorizedActions('downgrade')} label={t('common.downgrade')} customColor='#36B894' />
                </ActionContainer>
                <ActionContainer>
                  <Button onClick={() => handleUpdateAuthorizedActions('upgrade')} label={t('common.activate')} customColor='#36B894' />
                </ActionContainer>
              </>
            ) : (
              <ActionContainer>
                <Button onClick={() => handleUpdateAuthorizedActions('downgrade')} label={t('common.downgrade')} customColor='#36B894' />
              </ActionContainer>
            )}
          </Container>
        </FloatingActionBar>
      )}

      {deleteAction && (
        <Modal
          show={deleteAction ? true : false}
          title={t('actionPlan.modal.delete_custom_action_title', { name: deleteAction.title })}
          onClose={() => setDeleteAction(null)}>
          <ConfirmContainer>
            <div>
              {t('actionPlan.modal.confirmation_delete_action')} <span>{deleteAction.title}</span> ?
            </div>
            <ButtonsContainer>
              <Button onClick={handleDeleteCustomAction} label={t('common.yes')} />
              <Button onClick={() => setDeleteAction(null)} label={t('common.no')} />
            </ButtonsContainer>
          </ConfirmContainer>
        </Modal>
      )}

      <FiltersMenu
        filtersMenAnchorEl={filtersMenAnchorEl}
        setFiltersMenAnchorEl={setFiltersMenAnchorEl}
        items={filtersMenuItems}
        handleFilterChange={handleFilterChange}
      />
    </PageWrapper>
  );
};

export default AdminActionPlan;
