import styled, { css } from 'styled-components';
import { COLORS } from '../../utils/constants';

export interface PieProps {
  percent: number;
  color: string;
  inverted?: boolean;
  largePercent?: boolean;
}

export const Pie = ({ percent, color, inverted = false, largePercent }: PieProps) => {
  return (
    <PieElement color={color} percent={percent} inverted={inverted} largePercent={largePercent} completed={false}>
      <svg width='100%' height='100%' viewBox='0 0 40 40'>
        <circle className='donut-ring' cx='20' cy='20' r='16' fill='transparent' strokeWidth='4' shapeRendering='geometricPrecision'></circle>
        <circle
          className='donut-segment'
          cx='20'
          cy='20'
          r='16'
          fill='transparent'
          strokeWidth='4'
          strokeDashoffset='25'
          shapeRendering='geometricPrecision'></circle>
        <circle
          className='donut-middle'
          cx='20'
          cy='20'
          r='16'
          fill='transparent'
          strokeWidth='4'
          strokeDashoffset='25'
          shapeRendering='geometricPrecision'></circle>
        <g className='donut-text donut-text'>
          <text y={largePercent ? '55%' : '50%'} transform='translate(0, 2)'>
            <tspan x='50%' textAnchor='middle' className='donut-percent'>
              {percent}%
            </tspan>
          </text>
        </g>
      </svg>
    </PieElement>
  );
};

const PieElement = styled.div<{
  percent: number;
  border?: number;
  color: string;
  animate?: boolean;
  inverted: boolean;
  largePercent: boolean;
  completed: boolean;
}>`
  height: 100%;

  .donut-ring {
    stroke: ${({ inverted }) => (inverted ? 'rgba(162, 160, 165, 0.4)' : 'white')};
  }

  .donut-segment {
    stroke: ${({ color }) => color};
    stroke-dasharray: ${({ percent }) => percent}, ${({ percent }) => 100 - percent};
    transition: all 0.5s ease-in-out;
  }

  .donut-text {
    fill: ${({ inverted, completed }) => (completed ? 'white' : inverted ? 'black' : 'white')};
  }

  ${({ completed }) =>
    completed &&
    css`
      .donut-middle {
        fill: ${COLORS.AquaMarine};
        animation: fadeIn 0.5s ease-in-out;
      }
    `}

  .donut-percent {
    font-size: ${({ largePercent }) => (largePercent ? '0.9em' : '0.5em')};
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: 700;
  }
`;
