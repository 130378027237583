import TaskItem from '@components-new/atoms/taskItem/TaskItem';
import WizardModale from '@components-new/organisms/wizardModale/WizardModale';
import { useTranslation } from 'react-i18next';
import {
  CreateProductContainer,
  FormCompletionNotice,
  ProductAddedContainer,
  ProductAddedName,
  ProductAddedText,
  ProductImage,
  ProductItemWrapper,
  ProductsContainer,
  ScrollableContent,
  StyledInputComponent,
} from './ModalNewProduct_.style';
import ToolKit from '@components-new/atoms/icons/ToolKit';
import { useState } from 'react';
import { InputComponent } from '@components/form/InputComponent';
import CheckIcon from '@components-new/atoms/icons/CheckIcon';
import { Button } from '@components/Button';
import { setModal } from 'src/reducers/app.reducer';
import { useDispatch } from 'react-redux';
import { SearchBar } from '@components-new/molecules';

export interface ModalNewProductProps {
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
  addNewProduct: (productName: string, productType: any) => void;
  productOptions: any[];
  isNewProductNameValid: (productName: string, productType: any) => boolean;
}

export const ModalNewProduct = ({ show, onClose, onClick, productOptions, addNewProduct, isNewProductNameValid }: ModalNewProductProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [query, setQuery] = useState<string>('');

  const [wizardStep, setWizardStep] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newProductName, setNewProductName] = useState(null);

  const sortProductsByRanking = products => {
    return products.sort((a, b) => {
      const rankA = a.value.ranking !== undefined ? a.value.ranking : Infinity;
      const rankB = b.value.ranking !== undefined ? b.value.ranking : Infinity;

      return rankA - rankB;
    });
  };

  const closeProductModal = () => {
    dispatch(setModal({ show: false, type: null }));
  };

  const resetForm = () => {
    setWizardStep(0);
    setSelectedProduct(null);
    setNewProductName(null);
  };

  const filteredProducts = sortProductsByRanking(productOptions.filter(product => product?.label.toLowerCase().includes(query.toLowerCase())));

  const getProducts = filteredProducts.map((product: any, index: number) => {
    return (
      <ProductItemWrapper key={product.id} isLast={index === filteredProducts.length - 1}>
        <TaskItem
          selected={selectedProduct?.id === product?.id}
          description={product.value.description}
          title={product.label}
          onClick={() => setSelectedProduct(product)}
          disabled={false}
          icon={product.value.image ? <ProductImage alt='' src={product.value.image?.fields.file.url}></ProductImage> : <ToolKit />}
        />
      </ProductItemWrapper>
    );
  });

  const steps = [
    {
      header: (
        <div>
          <SearchBar query={query} setQuery={setQuery} />
        </div>
      ),
      content: (
        <>
          {getProducts.length !== 0 && (
            <ProductsContainer>
              <ScrollableContent>{getProducts}</ScrollableContent>
            </ProductsContainer>
          )}
        </>
      ),
      isValid: selectedProduct ? true : false,
    },
    {
      content: (
        <CreateProductContainer>
          <StyledInputComponent disabled sharable={false} type={'text'} label={t('modal.select_service_type')} value={selectedProduct?.label} />
          <InputComponent
            focus={false}
            placeholder={t('modal.new_name_placeholder')}
            sharable={false}
            label={t('modal.new_service_name')}
            type={'text'}
            onChange={e => setNewProductName(e.target.value)}
            error={
              newProductName &&
              selectedProduct &&
              newProductName?.trim().length !== 0 &&
              !isNewProductNameValid(newProductName, selectedProduct) &&
              t('modal.name_already_used_error')
            }
            value={newProductName}
          />
        </CreateProductContainer>
      ),
      isValid: newProductName ? true : false,
      action: () => addNewProduct(newProductName, selectedProduct),
    },
    {
      content: (
        <ProductAddedContainer>
          <CheckIcon width={80} height={80} />
          <ProductAddedText>{t('modal.new_product_added_success')}</ProductAddedText>
          <ProductAddedName>
            {selectedProduct?.label} : {newProductName}
          </ProductAddedName>
          <FormCompletionNotice>{t('modal.form_completion_notice')}</FormCompletionNotice>
          <Button label={t('modal.questionnaire_button')} onClick={closeProductModal}></Button>
          <Button label={t('modal.add_another_service_button')} type='light' onClick={resetForm}></Button>
        </ProductAddedContainer>
      ),
      isValid: true,
    },
  ];

  return (
    <>
      {show && (
        <WizardModale
          show={show}
          onClose={onClose}
          steps={steps}
          step={wizardStep}
          setStep={setWizardStep}
          title={t('modal.new_product_creation')}
          resetForm={resetForm}></WizardModale>
      )}
    </>
  );
};
