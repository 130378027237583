import { configureStore } from '@reduxjs/toolkit';
import ContentfulReducer from '../reducers/contentful.reducer';
import { useDispatch } from 'react-redux';
import appReducer from '../reducers/app.reducer';
import DashboardReducer from '../reducers/dashboard.reducer';
import persistedAccountReducer from './persistConfig';
import { persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
//import logger from 'redux-logger';

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    contentful: ContentfulReducer,
    dashboard: DashboardReducer,
    account: persistedAccountReducer,
    app: appReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }) /*.concat(logger)*/,
});

const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch<AppDispatch>();

export { store, persistor };
