import styled from 'styled-components';
import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';
import { COLORS } from '@utils/constants';

const IconContainer = styled.div<{ index?: number }>`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  & > * {
    margin-left: -15px;
    position: relative;
    background: linear-gradient(to right, transparent 20%, white 80%);
    border-radius: 0 45% 0 0;
    path {
      transform: translateX(10%);
    }
  }
`;

export interface FileIconsPops {
  filesType: string[];
  onClick?: (e: any) => void;
  // TODO : possible improvement as fixing the max elements to display
  max?: number;
}

const FileIcons = ({ filesType, onClick }: FileIconsPops) => {
  const fileTypesMapper = {
    xlsx: {
      icon: AiOutlineFileExcel,
      color: COLORS.xlsx,
    },
    pdf: {
      icon: AiOutlineFilePdf,
      color: COLORS.pdf,
    },
    docx: {
      icon: AiOutlineFileWord,
      color: COLORS.docx,
    },
  };

  const imageFileTypes = ['png', 'jpeg', 'jpg'];
  imageFileTypes.forEach(type => {
    fileTypesMapper[type] = {
      icon: AiOutlineFileImage,
      color: COLORS.Green,
    };
  });

  return (
    <IconContainer onClick={onClick}>
      {filesType.map((fileType, index) => {
        const { icon: IconComponent, color } = fileTypesMapper?.[fileType];
        return <IconComponent key={index} size={32} color={color} />;
      })}
    </IconContainer>
  );
};

export default FileIcons;
