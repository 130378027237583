import { IconButton } from '@mui/material';
import { COLORS } from 'src/utils/constants';
import styled from 'styled-components';

export const MessageListContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-bottom: 80px;
`;

export const ChatBubbleRow = styled.div<{ isUser?: boolean }>`
  display: flex;
  padding: 15px 20px;
  align-items: flex-end;
  flex-direction: ${({ isUser }) => (isUser ? 'row-reverse' : 'row')};
  gap: 10px;
`;

export const ChatBubble = styled.div<{ isUser?: boolean; userDisabled?: boolean }>`
  border-radius: ${({ isUser }) => (isUser ? '8px 8px 0 8px' : '8px 8px 8px 0')};
  font-size: 16px;
  padding: 15px;
  background-color: ${({ isUser }) => (isUser ? '#326C82' : '#f2f2f2')};
  color: ${({ userDisabled }) => (userDisabled ? COLORS.Grey : COLORS.DarkGrey)};
`;

export const SendContainer = styled.div`
  display: flex;
  margin-right: 65px;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TimeContainer = styled.div<{ isUser?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: ${({ isUser }) => (isUser ? 'row' : 'row-reverse')};
`;

export const DateTimeContainer = styled.h6`
  opacity: 0.7;
  margin: 5px 0 0 0;
`;

export const TextContainer = styled.div<{ isUser?: boolean; userDisabled?: boolean }>`
  color: ${({ isUser, userDisabled }) => (isUser ? '#FFF' : userDisabled ? COLORS.Grey : '#2D313F')};
  word-wrap: break-word;
`;

export const MessageFooter = styled.form`
  position: fixed;
  bottom: 0;
  width: 62%;
  background-color: #ffffff;
  padding: 10px;
  height: 85px;

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;
    color: ${COLORS.Red};
  }
`;

export const ChatBubbleContainer = styled.div`
  position: relative;
  width: 50%;
`;

export const EmptyChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 94%;
  row-gap: 20px;
  border: 1px dashed ${COLORS.MediumGrey};

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;

    b {
      color: ${COLORS.NiceBlue};
    }
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const Delete = styled.div<{ isUser?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  svg {
    opacity: ${({ isUser }) => (isUser ? '0.9' : '0.7')};
    color: ${({ isUser }) => (isUser ? '#FFF' : '#2D313F')};
    width: 20px;
    height: 20px;
  }
`;

export const SendButton = styled(IconButton)`
  && {
    color: #326c82;
  }
`;
